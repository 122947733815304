import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, IconButton } from '@mui/material';
import { District } from '../../app/feathers/districts/District';
import { Shapefile } from '../../app/feathers/districts/Shapefile';
import { CheckCircle, PauseCircleOutline, SyncAlt } from '@mui/icons-material';
import PeopleNotShadedIn from '@mui/icons-material/PeopleAltOutlined';
import PeopleShadedIn from '@mui/icons-material/PeopleAlt';

interface ShapefileDataMatcherProps {
	shapefiles: Shapefile[];
	districts: District[];

	// a dictionary of district id to shapefile row index
	pairs: Record<string, number>;
	onChangePairs: (pairs: Record<string, number>) => void;

	onChangePopulation?: (population: Record<string, number>) => void;
	style?: React.CSSProperties;
}

const ShapefileDataMatcher = ({
	shapefiles,
	districts,
	pairs,
	onChangePairs,
	onChangePopulation,
	style
}: ShapefileDataMatcherProps) => {

	// the state keeping tract of active selections
	const [ selectedDistrict, setSelectedDistrict ] = useState<District | null>(null);
	const [ populationColumn, setPopulationColumn ] = useState<string | null>(null);
	const [ statusMessage, setStatusMessage ] = useState<string | null>(null);

	const shapefileHeaders = useMemo(() => {
    if(shapefiles.length === 0) return [];
    return Object.keys(shapefiles?.[0] || {}).filter(k => k !== 'dataIndex' && k !== 'geometry')
  }, [shapefiles])

	const populationColumnPotentials = useMemo(() => {
		if(shapefiles.length === 0) return [];
		// only return columns with pop who's values are whole numbers
		return shapefileHeaders.filter(h => h && h.toLowerCase().includes('pop') && shapefiles.every(s => {
			const val = s[h];
			return !isNaN(parseInt(val)) && val === Math.floor(val);
		}))
	}, [ shapefileHeaders, shapefiles ]);

	// prep the districts and shapefile rows by ordering them
	const districtRows = useMemo<District[]>(() => {
    // sort by paired rows first 
    const ordered = Object.keys(pairs).map(k => {
      return districts.find(d => d._id === k)
    }).filter((d): d is District => d !== undefined);
    const rest = districts.filter(d => !Object.keys(pairs).includes(d._id))
    return [...ordered, ...rest]
  }, [ districts, pairs ])


	const shapefileRows = useMemo<Shapefile[]>(() => {
		// order according to the row pairs / children district orders
		let ordered = Object.values(pairs).map(k => {
			return shapefiles.find(d => d.dataIndex === k)
		}).filter((d): d is Shapefile => d !== undefined);
		// add the rest of the rows
		const rest = shapefiles.filter(d => !Object.values(pairs).includes(d.dataIndex))
		return [...ordered, ...rest];
  }, [shapefiles, districtRows, pairs])

	const pairChildDistrictToShapefileRow = (district: District, shapefileRow: Shapefile) => {
    console.log(`Pairing ${district.longName} with ${shapefileRow?.dataIndex}`)
    // remove all other pairings for this child district and shapefile row
		// because sometimes we might want to reassign a district to a different shapefile row
    const trimmedPairs = Object.entries(pairs).reduce((acc, [districtId, dataIndex]) => {
      if(districtId === district._id || dataIndex === shapefileRow.dataIndex) { 
				return acc;
      } else {
				acc[districtId] = dataIndex;
				return acc;
			}
    }, {} as Record<string, number>)

    const newPairs = {
      ...trimmedPairs,
      [district._id]: shapefileRow.dataIndex
    }
    onChangePairs(newPairs)
    setSelectedDistrict(null)
  }

	const numberPaired: number = Object.keys(pairs).length;

	// This function takes in a shapefile column and attempts to find the corresponding field in the districts
	const attemptMatchByColumn = (columnName: string) => {
		setStatusMessage(null);

		const districtToShapefileIndex: Record<string, number> = {};

		// identifier type for districts
		const identifierType = districts.some(d => d.name) ? 'name' : (districts.some(d => d.letter) ? 'letter' : 'number');

		// if the shapefiles are nonunique, do not match on those.
		const shapefilesUniqueOnColumn = shapefiles.filter((shapefile, idx, arr) => {
			const columnValue = valueForMatch(`${shapefile[columnName]}`);
			const otherMatchingRows = arr.filter((s, i) => i !== idx && valueForMatch(`${s[columnName]}`) === columnValue);
			return otherMatchingRows.length === 0;
		})
			
		const shapefileValuesToIndex = shapefilesUniqueOnColumn.reduce((acc: Record<string, number> | null, shapefile: Shapefile) => {
			if(acc === null) return null;
			// first of all, make sure that this column is both unique and fully populated
			if(!shapefile[columnName]) {
				setStatusMessage('The column you selected has missing values.');
				return null;
			}
			const columnValue = shapefile[columnName];
			// massage the column to make it lowercase, and remove common prefixes like 'district of', 'county' etc
			const columnValueForMatch = valueForMatch(`${columnValue}`);
			if(acc[columnValueForMatch]) {
				setStatusMessage('The column you selected is not unique: ' + columnValueForMatch + '. We will attempt to match how we can.');
				return null;
			}
			acc[columnValueForMatch] = shapefile.dataIndex;
			return acc;
		}, {} as Record<string, number>);
		if(shapefileValuesToIndex === null) {
			onChangePairs({});
			return;
		}

		// now, try to match the districts with the shapefile values
		districts.forEach((district, arr) => {
			const districtValue = `${district[identifierType]}`.toLowerCase();
			const shapefileIndex = shapefileValuesToIndex[districtValue];
			if(typeof(shapefileIndex) !== 'undefined') {
				districtToShapefileIndex[district._id] = shapefileIndex;
			} else {
				// try to match based on the longName and short name
				const districtValueLong = valueForMatch(`${district.longName}`);
				// make sure that this is unique across the array
				const matchingDistricts = districts.filter(d => valueForMatch(`${d.longName}`) === districtValueLong);
				let shapefileIndex: number | undefined;
				if(matchingDistricts.length === 1) {
					shapefileIndex = shapefileValuesToIndex[districtValueLong];
				}

				if(typeof(shapefileIndex) === 'undefined') {
					const districtValueShort = valueForMatch(`${district.shortName}`);
					const matchingDistricts = districts.filter(d => valueForMatch(`${d.shortName}`) === districtValueShort);
					if(matchingDistricts.length === 1) {
						shapefileIndex = shapefileValuesToIndex[districtValueShort];
					}
				}

				if(typeof(shapefileIndex) !== 'undefined') {
					districtToShapefileIndex[district._id] = shapefileIndex;
				}
			}
		});

		onChangePairs(districtToShapefileIndex);
	}

	useEffect(() => {
		if(populationColumn && onChangePopulation) {
			// try to go through and find the population values, returning it by district id to population value
			const populationValues: Record<string, number> = {};
			Object.entries(pairs).forEach(([districtId, shapefileIndex]) => {
				const shapefile = shapefiles.find(s => s.dataIndex === shapefileIndex);
				if(shapefile && shapefile[populationColumn] && !isNaN(parseInt(shapefile[populationColumn]))) {
					populationValues[districtId] = parseInt(shapefile[populationColumn]);
				}
			})

			onChangePopulation(populationValues);
		}
	}, [ populationColumn, pairs ])

	return (
		<div style={{ ...style }}>
			<div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
				<Typography variant='body1' style={{ marginBottom: '8px' }}>Match each district (left-hand side) with the correct shapefile boundary (right-hand-side)</Typography>
				{
					statusMessage && 
					<Typography variant='body1' style={{ color: 'red', marginBottom: '8px' }}>{statusMessage}</Typography>
				}
				<TwoSideSplit>
					<SplitInner>
						<TableContainer component={Paper} style={{ width: '100%', overflowX: 'scroll' }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>District name</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{districtRows.map((district) => (
										<TableRow key={district._id} style={{
											backgroundColor: selectedDistrict?._id === district._id ? '#CDDDFA' : 'white',
											cursor: 'pointer'
										}} onClick={() => {
											if(selectedDistrict?._id === district._id) {
												setSelectedDistrict(null)
											} else {
												setSelectedDistrict(district)
											}
										}}>
											<TableCell>
												<Typography variant='body1' style={{ whiteSpace: 'nowrap'}}>{district?.longName}</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</SplitInner>
					<DashedLineContainer numRows={districts.length}>
						<div/>
						{
							Object.keys(pairs).map((_, idx) => (
								<DashedLine key={idx} />
							))
						}
					</DashedLineContainer>
					<SplitInner>
						<TableContainer component={Paper} style={{ width: '100%', overflowX: 'scroll' }}>
							<Table>
								<TableHead>
									<TableRow>
										{
											shapefileHeaders.map(header => (
												<TableCell key={header}>
													<div style={{ display: 'flex', alignItems: 'center', gap: '4px'  }}>
														<Typography variant='body2' style={{ fontWeight: 'bold' }}>{header}</Typography>
														<Tooltip title='Match by this column'>
															<IconButton size='small' onClick={() => attemptMatchByColumn(header)}>
																<SyncAlt style={{ fontSize: '16px' }}/>
															</IconButton>
														</Tooltip>
														{
															populationColumnPotentials.includes(header) &&
															<Tooltip title='Set as population'>
																<IconButton size='small' onClick={() => setPopulationColumn(header)}>
																	{
																		populationColumn === header
																		? <PeopleShadedIn style={{ fontSize: '16px' }} color='primary'/>
																		: <PeopleNotShadedIn style={{ fontSize: '16px', opacity: 0.6 }}/>
																	}
																</IconButton>
															</Tooltip>
														}
													</div>
												</TableCell>
											))
										}
									</TableRow>
								</TableHead>
								<TableBody>
									{shapefileRows.map((shapefileRow) => (
										<TableRow key={shapefileRow.dataIndex} style={{
											cursor: selectedDistrict ? 'pointer' : 'default',
										}} onClick={() => {
											if(selectedDistrict) {
												pairChildDistrictToShapefileRow(selectedDistrict, shapefileRow)
											}
										}}>
											{
												shapefileHeaders.map((key, idx) => (
													<TableCell key={idx}>
														<Typography variant='body1' style={{ whiteSpace: 'nowrap'}}>{shapefileRow[key]}</Typography>
													</TableCell>
												))
											}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</SplitInner>
				</TwoSideSplit>
			</div>
			<div style={{ margin: '24px 0', display: 'flex', gap: '60px', alignItems: 'center' }}>
				<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
					{
						numberPaired === districtRows.length
						? <CheckCircle style={{ color: 'green' }} />
						: <PauseCircleOutline style={{ }} />
					}
					<Typography variant='body1'>{numberPaired} of {districtRows?.length} paired</Typography>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
					{
						populationColumn
						? <CheckCircle style={{ color: 'green' }} />
						: <PauseCircleOutline style={{ }} />
					}
					<Typography variant='body1'>{populationColumn ? 'Population column identified' : 'No population' }</Typography>
				</div>
			</div>
		</div>
	)
}

function valueForMatch(value: string) {
	return value.toLowerCase()
				.replaceAll(/district|dist|hd|sd|cng|dst|county|city of|city|parish/g, '')
				.replaceAll(/-/g, '')
				.trim()
}

const TwoSideSplit = styled.div`
	display: grid;
	width: 100%;
	max-width:100%;
	grid-template-columns: 300px 24px calc(100% - 24px - 300px);
`

interface DashedLineContainerProps {
	numRows: number;
}

const DashedLineContainer = styled.div<DashedLineContainerProps>`
	display: grid;
	grid-template-columns: 24px;
	grid-template-rows: repeat(${props => props.numRows}, 57px);
	align-items: center;
`
const DashedLine = styled.div`
	border-top: dashed 2px #AAAAAA;
	height: 1px;
	width: 100%;
`

const SplitInner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export { ShapefileDataMatcher }