import React from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';
import { CheckCircle } from '@mui/icons-material';

interface TileSelectOptionProps {
  selected: boolean;
  disabled?: boolean;
  value: string;
  title: string;
  subText: string;
  children: React.ReactNode;
  onChange: (e: any) => void;
  style?: React.CSSProperties;
}

export const TileSelectOption = (
  {
    selected,
    disabled = false,
    value,
    title,
    subText,
    children,			// icon component
    onChange,
    style
  }: TileSelectOptionProps,
) => {
  return (
    <OptionCell
      onClick={() => onChange({ target: { name: 'rating', value }})} 
      selected={selected ? 1 : 0} 
      style={{
        ...style,
        ...(disabled ? { opacity: 0.4, pointerEvents: 'none' } : {})
      }}>
      {
        selected &&
        <LittleCheckWrapper>
          <CheckCircle style={{ height: '16px', width: 'auto' }}/>
        </LittleCheckWrapper>
      }
      {children}
      <Typography variant='h3' color='textSecondary'
        style={{
          textAlign: 'center',
          marginTop: '8px',
          ...(selected ? {} : { color: '#333' })
        }}
      >{title}</Typography>
			<Typography variant='body1' color='textSecondary'
				style={{
					textAlign: 'center',
					marginTop: '8px',
					...(selected ? {} : { color: '#333' })
				}}
			>{subText}</Typography>
    </OptionCell>
  )
}

interface OptionCellProps {
  selected: number;
}

const OptionCell = styled.div<OptionCellProps>`
  border: ${props => props.selected ? `solid 2px #DDDDDD` : `solid 1px #EEEEEE`};
  cursor: pointer;
  padding: 16px;
  position: relative;
  border-radius: 8px;
  &:hover {
    border: ${props => props.selected ? `solid #DDDDDD 1px` : `solid #DDDDDD80 1px`};
    background-color: #DDDDDD50;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  img, svg.scale-svg {
    height: auto;
    max-width: 60px;
  }
  h3 {
    font-size: 16px;
  }
  @media(max-width: 380px) {
    img, svg.scale-svg {
      height: auto;
      max-width: 40px;
    }

    h3 {
      font-size: 16px;
    }
  }
`

const LittleCheckWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  border-radius: 20px;
  height: 16px;
  width: 16px;
  background-color: ${props => props.theme.palette.textSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
`;