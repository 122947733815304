/* Normally, when displaying a district, you should use district.longName or district.shortName
	 Before the district is actually created though, this function helps us to actually preview the long name / short name
	 Based on the input naming patterns, so that you can see what they'll be called
*/
import { District } from "../feathers/districts/District";
import { NamingPattern, NamingPatternConfigurationType } from "../feathers/naming-patterns/NamingPattern";

export function prettyDistrictNameFromPattern (
	district: Partial<District>, orderedNamingPatterns: (NamingPattern | null)[]	 // the most local naming patterns should come first
): { longName: string, shortName: string } {
	const { name, type, letter, number, parent } = district;
	const identifierType = Boolean(name) ? 'name' : (Boolean(letter) ? 'letter' : 'number');

	if(!type) throw new Error('District type is required to generate pretty name');

	const { longName, shortName } = orderedNamingPatterns.reduce(({ longName, shortName }, pattern) => {
		if (pattern === null) return { longName, shortName };
		if (longName.length > 0 || shortName.length > 0) return { longName, shortName };

		const configurationForType: NamingPatternConfigurationType = pattern.configuration?.[type];

		if(!configurationForType) return { longName, shortName };

		const longPattern = configurationForType.long?.en;
		const shortPattern = configurationForType.short?.en;

		if(!longPattern || !shortPattern) return { longName, shortName };

		const nameReplacements = {
			'{{name}}': name,
			'{{letter}}': letter,
			'{{number}}': number,
			'{{parent}}': parent || '',
		};

		let longNameReplaced: string = '';
		let shortNameReplaced: string = '';
		['long', 'short'].forEach((lengthType) => {
			// split the pattern into segments
			const patternSegments = (lengthType === 'long' ? longPattern : shortPattern).split('[IF ');
			// find the relevant segment based on the identifier type
			const relevantSegment = patternSegments.find(segment => segment.startsWith('{{' + identifierType.toUpperCase()));
			if(!relevantSegment) return;

			const [_, identifierTypeSegment]: string[] = relevantSegment.split('}}:');
			const nameResolved = Object.entries(nameReplacements).reduce((name, [key, value]) => {
				return name.replace(key.toUpperCase(), `${value}`);
			}, identifierTypeSegment.slice(0, -1));

			if(lengthType === 'long') longNameReplaced = nameResolved;
			else shortNameReplaced = nameResolved;
		})
		if(!longNameReplaced || !shortNameReplaced) {
			console.error('Failed to resolve longName or shortName', { longName, shortName });
			return { longName, shortName };
		}

		return { longName: longNameReplaced, shortName: shortNameReplaced };
	},{ longName: '', shortName: '' });

	return { longName, shortName };
}