// This modal is for editing content from the naming patterns service
import React, { useEffect, useMemo, useState } from 'react';
import { NamingPattern, PatchNamingPattern } from '../../app/feathers/naming-patterns/NamingPattern';
import { NamingPatternSegment } from '../../app/feathers/naming-patterns/NamingPatternSegment';
import { segmentPretty, segmentUgly } from '../../app/util/pretty-naming-pattern-segment';
import {
  Modal,
  Typography,
  Button,
  IconButton
} from '@mui/material';
import { NamingPatternSegmentSearch } from '../NamingPatternSegmentSearch';
import { ModalInner } from '../lower-order';
import { humanizeDistrictType } from '../../app/util/humanize-district-type';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useFeathers } from '../../app/util';
import { Application } from '@feathersjs/feathers';
import { ServiceTypes } from '../../app/feathers/ServiceTypes';
import { StateConfiguration } from '../../app/feathers/state-configurations/StateConfiguration';
import { District } from '../../app/feathers/districts/District';
import { Cancel, Edit, Undo } from '@mui/icons-material';

// If given a naming pattern id, this component should load that naming pattern id
// It should also be given a set of types (such as a list of types or a specific tier 2 type and therefore children as well)
// It needs to have some sense for inheritance, so it should also have passed in
//   an array of naming pattern ids, in order they'll be fallen back to
// Lastly, this will allow the editing of the naming pattern, in the form of segments. 
//   any edit will happen by searching an existing segment or allowing you to create a new segment

type NamingPatternModalProps = {
  open: boolean;
  onClose: () => void;
  onReloadData: () => void;
  restrictToTier2Types?: string[];

  //provide EITHER the stateId or stateName (for all modes except national)
  stateId?: string;
  stateName?: string;

  tier2DistrictId?: string;

  // national mode is editing the default national pattern
  // state is editing the types for the entire state at once
  // tier-2-type is the same as the state, only editing for a certain subset of district types (tier2 and children)
  // tier-2-district is editing a specific naming pattern for a tier 2 district
  mode: 'state' | 'tier-2-type' | 'tier-2-district' | 'national';
};

const NamingPatternModal = ({
  open,
  onClose,
  onReloadData,
  mode = 'tier-2-type',
  restrictToTier2Types,   // an array of types, to limit editing to this
  stateId,                // the naming pattern id to load for the state
  stateName,              // the state name to load for the
  tier2DistrictId           // in the situation in which we're editing a tier 2 district to be unique or follow a unique pattern, this should be passsed in
}: NamingPatternModalProps) => {
  const feathers = useFeathers<Application<ServiceTypes>>();
  const [ status, setStatus ] = useState<'loading' | 'loaded'>('loading');
  const [ nationalNamingPattern, setNationalNamingPattern ] = useState<NamingPattern | null>(null);

  const [ stateConfiguration, setStateConfiguration ] = useState<StateConfiguration | null>(null);
  const [ stateNamingPattern, setStateNamingPattern ] = useState<NamingPattern | null>(null);
  const [ stateDistrict, setStateDistrict ] = useState<District | null>(null);

  const [ tier2District, setTier2District ] = useState<District | null>(null);
  const [ districtNamingPattern, setDistrictNamingPattern ] = useState<NamingPattern | null>(null);

  const [ error, setError ] = useState<string | null>(null);

  // the "in state memory" of the naming pattern to update, by type
  const [ namingPatternUpdate, setNamingPatternUpdate ] = useState<Record<string, NamingPatternUpdateState | 'default' > | null>(null);
  const changesMade = namingPatternUpdate && Object.keys(namingPatternUpdate).length;
  const [ saving, setSaving ] = useState(false);

  const loadStartingData = async () => {
    try {
      const nationalNamingPattern = await feathers.getService('naming-patterns').find({ query: { key: 'default-national' }});
      setNationalNamingPattern(nationalNamingPattern.data[0]);
  
      let stateDistrict;
      if(stateId) {
        stateDistrict = await feathers.getService('districts').get(stateId);
      } else if(stateName) {
        stateDistrict = await feathers.getService('districts').find({ query: { name: stateName, type: 'state' }});
        stateDistrict = stateDistrict.data[0];
      } else {
        throw new Error('No stateId or stateName provided')
      }
      setStateDistrict(stateDistrict);

      const stateConfiguration = await feathers.getService('state-configurations').get(stateDistrict.matchName.split('-')[0]);
      setStateConfiguration(stateConfiguration);

      const stateNamingPattern = await feathers.getService('naming-patterns').get(stateDistrict.namingPatternId)
      if(stateNamingPattern._id !== (nationalNamingPattern.data[0])._id) {
        setStateNamingPattern(stateNamingPattern);
      }
  
      if (mode === 'tier-2-district' && tier2DistrictId) {
        const district = await feathers.getService('districts').get(tier2DistrictId);
        if(district.namingPatternId && ![stateNamingPattern._id, nationalNamingPattern.data[0]._id].includes(district.namingPatternId)) {
          const districtNamingPattern = await feathers.getService('naming-patterns').get(district.namingPatternId);
          setDistrictNamingPattern(districtNamingPattern);
        }
        setTier2District(district);
      }
  
      setStatus('loaded');
    } catch (e) {
      if(e instanceof Error) {
        console.error(e);
        setError('An error occurred while loading the naming pattern data: ' + (e.message || ''));
      } else {
        console.error(e);
        setError('An error occurred while loading the naming pattern data');
      }
    }
  }

  useEffect(() => {
    if (feathers && open) {
      loadStartingData()
    }
  }, [feathers, open]);

  useEffect(() => {
    if(!open) {
      setStatus('loading');
      // clear all of our data
      setNationalNamingPattern(null);
      setStateConfiguration(null);
      setStateNamingPattern(null);
      setTier2District(null);
      setDistrictNamingPattern(null);
      setNamingPatternUpdate(null);
      setSaving(false)
      setError(null)
    }
  }, [open]);

  const saveChanges = async () => {
    if(saving) return;
    if(!namingPatternUpdate) return;
    setSaving(true)
    setError(null)

    // first, determine the naming pattern we want to save against
    let namingPatternToSaveAgainstPotential: NamingPattern | null = null;
    if(mode === 'state') {
      namingPatternToSaveAgainstPotential = stateNamingPattern;
    } else if(mode === 'tier-2-type') {
      namingPatternToSaveAgainstPotential = stateNamingPattern;
    } else if(mode === 'tier-2-district') {
      namingPatternToSaveAgainstPotential = districtNamingPattern;
    }

    if(namingPatternToSaveAgainstPotential === null) {
      if((mode === 'state' || mode === 'tier-2-type') && stateId && stateConfiguration) {
        // create a new naming pattern
        const newPattern = await feathers.getService('naming-patterns').create({
          key: `${stateConfiguration.stateCode.toLowerCase()}-state-naming-pattern`,
          description: `The state-level naming pattern for ${stateConfiguration.stateName}`,
          configuration: {}
        });

        setStateNamingPattern(newPattern);

        const updatedStateDistrict = await feathers.getService('districts').patch(stateId, { namingPatternId: newPattern._id })
        setStateDistrict(updatedStateDistrict)

        namingPatternToSaveAgainstPotential = newPattern;
      } else if(mode === 'tier-2-district' && tier2District) {
        // create a new naming pattern just for this tier 2 district
        const newPattern = await feathers.getService('naming-patterns').create({
          key: `${tier2District.matchName}-state-naming-pattern`,
          description: `The state-level naming pattern for ${tier2District.longName}`,
          configuration: {}
        });

        setDistrictNamingPattern(newPattern)

        const updatedTier2District = await feathers.getService('districts').patch(tier2District._id, { namingPatternId: newPattern._id })
        setTier2District(updatedTier2District)

        namingPatternToSaveAgainstPotential = newPattern;
      } else {
        setSaving(false)
        return;
      }
    }

    const namingPatternToSaveAgainst: NamingPattern = namingPatternToSaveAgainstPotential;

    // Load the segments from the server
    const { longSegments, shortSegments } = Object.keys(namingPatternUpdate).reduce((acc, type) => {
      const update: NamingPatternUpdateState | 'default' = namingPatternUpdate[type];
      if(update === 'default') {
        return acc;
      } else {
        return {
          longSegments: [ ...acc.longSegments, ...update.longSegments.map(s => s.segment) ],
          shortSegments: [ ...acc.shortSegments, ...update.shortSegments.map(s => s.segment) ]
        }
      }
    }, { longSegments: [], shortSegments: [] } as { longSegments: string[], shortSegments: string[] });

    let segmentsLoaded: NamingPatternSegment[] = [];

    for(let i = 0; i < 2; i ++) {
      const segmentsToLoadUnique = Array.from(new Set(i === 0 ? longSegments : shortSegments));
      const chunkSize = 20;
      const segmentsChunked = segmentsToLoadUnique.reduce((acc: string[][], segment, idx) => {
        const chunkIdx = Math.floor(idx / chunkSize);
        if(!acc[chunkIdx]) {
          acc[chunkIdx] = [];
        }
        acc[chunkIdx].push(segment);
        return acc;
      }, [] as string[][]);

      const segmentsLoadedOne: NamingPatternSegment[] = (
        await Promise.all(
          segmentsChunked.map((chunk) => 
            feathers.getService('naming-pattern-segments').find({ 
              query: { 
                segment: { $in: chunk }, 
                lengthType: i === 0 ? 'long' : 'short' 
              } 
            })
          ))
        ).map((result) => result.data
      ).flat();

      segmentsLoaded = [ ...segmentsLoadedOne, ...segmentsLoaded ];
    }
    
    const segmentToId: Record<string, string> = segmentsLoaded.reduce((acc: Record<string, string>, segment) => {
      if (segment._id) {
        acc[`${segment.lengthType}-${segment.segment}`] = segment._id;
      }
      return acc;
    }, {} as Record<string, string>);

    // Find if there's any segments we need to create
    const segmentsToCreate: NamingPatternSegment[] = Object.keys(namingPatternUpdate).reduce((acc, type) => {
      const update  = namingPatternUpdate[type];
      if(update === 'default') {
        return acc;
      } else {
        const longSegments = update.longSegments.filter((segment) => !segmentToId[`long-${segment.segment}`]);
        const shortSegments = update.shortSegments.filter((segment) => !segmentToId[`short-${segment.segment}`]);
        return [ ...acc, ...longSegments, ...shortSegments ];
      }
    }, [] as NamingPatternSegment[]);

    if(segmentsToCreate.length > 0) {
      const segmentObjectsCreated = await Promise.all(segmentsToCreate.map((segment) => feathers.getService('naming-pattern-segments').create(segment)));
      segmentObjectsCreated.forEach((segment) => {
        if(segment._id) {
          segmentToId[`${segment.lengthType}-${segment.segment}`] = segment._id;
        }
      });
    }

    // turn the namingPatternUpdate into the update against the base naming pattern
    const baseNamingPattern: Record<string, string[]> = Object.keys(namingPatternToSaveAgainst.configuration || {}).reduce((acc, type) => {
      const segmentsUsed = namingPatternToSaveAgainst.configuration[type]?.segmentsUsed || [];
      acc[type] = segmentsUsed;
      return acc;
    }, {} as Record<string, string[]>);

    const updatedConfiguration = Object.keys(namingPatternUpdate).reduce((acc, type) => {
      const update: NamingPatternUpdateState | 'default' = namingPatternUpdate[type];
      if(update === 'default') {
        // when we've got a default naming pattern, we need to remove the custom naming pattern
        // this means we want to reset the type completely
        const { [type]: _, ...rest } = acc;
        return rest;
      } else {
        const longSegments = update.longSegments.map((segment) => segmentToId[`long-${segment.segment}`])
        const shortSegments = update.shortSegments.map((segment) => segmentToId[`short-${segment.segment}`])
        acc[type] = [...longSegments, ...shortSegments];
        return acc;
      }
    }, baseNamingPattern);

    console.log('updatedConfiguration', updatedConfiguration, 'against namingpattern', namingPatternToSaveAgainst._id);
    
    try {
      const updatedPattern = 
        await feathers.getService('naming-patterns').patch(
          namingPatternToSaveAgainst._id, 
          { configuration: updatedConfiguration }
        )
      setSaving(false);
      if(mode === 'state') {
        setStateNamingPattern(updatedPattern);
      } else if(mode === 'tier-2-type') {
        setStateNamingPattern(updatedPattern);
      } else if(mode === 'tier-2-district' && tier2District) {
        if(Object.keys(updatedConfiguration || {}).length === 0) {
          // if we're doing a complete reset back to the defaults, then we can just delete this naming pattern
          // if it's a tier 2 pattern

          // First, make sure we unset it from the district
          const namingPatternToSet = stateNamingPattern?._id ?? nationalNamingPattern?._id;
          const updatedTier2District = await feathers.getService('districts').patch(
            tier2District?._id,
            { namingPatternId: namingPatternToSet }
          )

          // then delete the pattern
          const deletedPattern = await feathers.getService('naming-patterns').remove(
            namingPatternToSaveAgainst._id,
          )

          // then update our local storage
          setDistrictNamingPattern(null);
          setTier2District(updatedTier2District)
        } else {
          setDistrictNamingPattern(updatedPattern)
        }
      }

      setNamingPatternUpdate(null);

      // reload the data on the main screen
      onReloadData()
      setSaving(false)
    } catch(err) {
      if(err instanceof Error) {
        console.error(err);
        setError('An error occurred while saving the naming pattern data: ' + (err.message || ''));
      } else {
        console.error(err);
        setError('An error occurred while saving the naming pattern data');
      }
      setSaving(false)
    }

    // TODO: delete any segments that aren't being used anywhere
  }

  const typesToLookAt: string[] = useMemo(() => {
    if(!stateConfiguration) return [];
    if(mode === 'tier-2-type') {
      return restrictToTier2Types
        ? restrictToTier2Types.reduce((acc, tier2Type) => {
          return [ ...acc, ...(stateConfiguration?.typeStructure || {})[tier2Type] || [] ];
        }, restrictToTier2Types ? restrictToTier2Types : [])
      : [];
    } else if(mode === 'state') {
      return Object.keys(stateConfiguration.typeStructure || {}).reduce((acc, parentType) => {
        const subtypes = stateConfiguration.typeStructure?.[parentType] || []; 
        const typesToAdd = [ parentType, ...subtypes ].filter(type => !acc.includes(type))

        return [...acc, ...typesToAdd];
      }, [] as string[]).filter(t => t !== 'country')
    } else if(mode === 'tier-2-district') {
      // only return this district and it's children types
      if(tier2District) {
        return [tier2District.type].reduce((acc, tier2Type) => {
          return [ ...acc, ...(stateConfiguration?.typeStructure || {})[tier2Type] || [] ];
        }, [tier2District.type])
      } else {
        return [];
      }
    } else {
      throw new Error(`Unsupported typesToLookAt`)
    }
  }, [ mode, stateConfiguration, restrictToTier2Types, tier2District ])

  const orderedPatterns: (NamingPattern | null)[] = useMemo(() => {
    if(mode === 'state' || mode === 'tier-2-type') return [stateNamingPattern, nationalNamingPattern]
    if(mode === 'tier-2-district') return [districtNamingPattern, stateNamingPattern, nationalNamingPattern];
    if(mode === 'national') return [nationalNamingPattern];
    else throw Error(`Invalid mode`)
  }, [  mode, stateNamingPattern, nationalNamingPattern, districtNamingPattern ]);

  let contentInner;
  if(status === 'loading') {
    contentInner = <Typography>Loading...</Typography>;
  } else if(status === 'loaded') {
    contentInner = (
      <div>
        <Typography variant="h4">Naming Pattern</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell>Long name</TableCell>
                <TableCell>Short name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                stateConfiguration && typesToLookAt.map((type) => {
                  return (
                    <NamingPatternRow
                      key={type}
                      orderedNamingPatterns={orderedPatterns}
                      districtType={type}
                      stateConfiguration={stateConfiguration}
                      value={namingPatternUpdate?.[type] || null}
                      onChange={(valueNew) => setNamingPatternUpdate(npu => ({ ...npu, [type]: valueNew }))}
                    />
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  } else {
    contentInner = (
      <div>
        <Typography variant="h4">Unsupported configuration</Typography>
        <Typography>{mode || ''} is not a supported mode for the naming pattern modal</Typography>
        <Typography>Status: {status}</Typography>
      </div>
    )
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalInner onClose={onClose} style={{ maxWidth: '800px'}}>
        <div style={{ display: 'flex', flexDirection: 'column', 'gap': '12px'}}>
          {error && <Typography color="error">{error}</Typography>}
          {contentInner}
          {changesMade && <Button style={{alignSelf: 'flex-end' }} onClick={saveChanges} variant='contained' color='primary' disabled={saving}>{saving ? 'Saving...' : 'Save'}</Button>}
        </div>
      </ModalInner>
    </Modal>
  );
};

// This is a pretty dynamic component, that for a given district type
// Will display:
// - the segment of the naming pattern
// - whether it is default or custom
// and allow you to edit it:
// - by adding a new segment
type NamingPatternUpdateState = {
  long: string;
  longSegments: NamingPatternSegment[];
  short: string;
  shortSegments: NamingPatternSegment[];
};

type NamingPatternRowProps = {
  orderedNamingPatterns: (NamingPattern | null)[];
  districtType: string;
  stateConfiguration: StateConfiguration;
  value: NamingPatternUpdateState | 'default' | null;
  onChange: (value: NamingPatternUpdateState | 'default') => void;
};

type SpecificationPart = {
  identifierType: string;
  segment: string;
  namingPatternSegment: NamingPatternSegment;
};


const NamingPatternRow = ({
  orderedNamingPatterns = [],     // localized patterns first, then state, then national. The first one is the one being edited
  value,                          // the current value of the naming pattern configuration for this specific type, for the naming pattern being edited        
  onChange,
  districtType,                   // the district type to look up
  stateConfiguration
}: NamingPatternRowProps) => {
  const typeWritten = humanizeDistrictType(districtType, stateConfiguration?.typeConfigurations);

  const namingPatternSpecsForType = useMemo(() => {
    return orderedNamingPatterns.reduce((acc, namingPattern, idx) => {
      const configurationForType = (idx === 0 ? value : null) || namingPattern?.configuration?.[districtType];

      if(configurationForType && configurationForType !== 'default') {
        if(typeof(configurationForType['long']) !== undefined || typeof(configurationForType['short']) !== undefined) {
          let specPartsLong: SpecificationPart[] = [], specPartsShort: SpecificationPart[] = [];
          ['long', 'short'].map((lengthType) => {
            const configValue = configurationForType?.[lengthType as 'long' | 'short'];
            const spec = typeof configValue === 'object' && configValue !== null ? configValue['en'] : configValue;
            if(typeof(spec) === 'string') {
              // if we have a "spec", this is a string that we can use to generate a naming pattern
              // it looks something like: [IF {{NUMBER}}:House of Delegates District {{NUMBER}}]...
              // it contains multiple segments (or none, potentially)

              const specParts = spec.split('[')
                .filter(Boolean)
                .map((value: string) => {
                  const segmentFull = `[${value}`;
                  const {segment, identifierType} = segmentPretty(segmentFull);
                  return {
                    identifierType: identifierType as 'name' | 'letter' | 'number',
                    segment,
                    namingPatternSegment: {
                      segment,
                      identifierType: identifierType as 'name' | 'letter' | 'number',
                      lengthType: lengthType as 'long' | 'short'
                    }
                  }
                });
              if(lengthType === 'long') {
                specPartsLong = specParts;
              } else {
                specPartsShort = specParts;
              }
            }
          })

          acc.push({
            specPartsLong,
            specPartsShort,
            namingPattern: namingPattern,
            isCustom: idx === 0
          });
        }
      }
      return acc;
    }, [] as { specPartsLong: SpecificationPart[], specPartsShort: SpecificationPart[], namingPattern: NamingPattern | null, isCustom: boolean }[]);
  }, [orderedNamingPatterns, districtType, value]);

  // Establish our functions for editing this data structure
  const deleteSegment = (segment: NamingPatternSegment) => {
    if(value  === 'default') {
      return;
    } else if(value) {
      const longSegments = value.longSegments.filter((existingSegment) => existingSegment.segment !== segment.segment);
      const shortSegments = value.shortSegments.filter((existingSegment) => existingSegment.segment !== segment.segment);
      const valueNew = {
        long: longSegments.map(segmentUgly).join(''),
        longSegments,
        short: shortSegments.map(segmentUgly).join(''),
        shortSegments
      }

      onChange(valueNew);
    } else {
      // If we don't have a value yet, we need to take the first naming pattern and create a new value
      const longSegments = namingPatternSpecsForType[0].specPartsLong.filter((existingSegment) => existingSegment.segment !== segment.segment).map((segment) => segment.namingPatternSegment);
      const shortSegments = namingPatternSpecsForType[0].specPartsShort.filter((existingSegment) => existingSegment.segment !== segment.segment).map((segment) => segment.namingPatternSegment);
      const valueNew = {
        long: longSegments.map(segmentUgly).join(''),
        longSegments,
        short: shortSegments.map(segmentUgly).join(''),
        shortSegments
      }

      onChange(valueNew)
    }
  }

  const addSegment = (segment: NamingPatternSegment) => {
    if(value === 'default') {
      return;
    } else if(value) {
      const longSegments = [ ...value.longSegments, segment.lengthType === 'long' ? segment : null ].filter(Boolean) as NamingPatternSegment[];
      const shortSegments = [ ...value.shortSegments, segment.lengthType === 'short' ? segment : null ].filter(Boolean) as NamingPatternSegment[];
      const valueNew = {
        long: longSegments.map(segmentUgly).join(''),
        longSegments,
        short: shortSegments.map(segmentUgly).join(''),
        shortSegments
      }

      onChange(valueNew);
    } else {
      // If we don't have a value yet, we need to take the first naming pattern and create a new value
      let longSegments = namingPatternSpecsForType ? [ ...namingPatternSpecsForType[0].specPartsLong.map((specPart) => specPart.namingPatternSegment)] : [];
      let shortSegments = namingPatternSpecsForType ? [ ...namingPatternSpecsForType[0].specPartsShort.map((specPart) => specPart.namingPatternSegment)] : [];

      if(segment.lengthType === 'long') {
        longSegments = [ ...longSegments, segment ];
      } else {
        shortSegments = [ ...shortSegments, segment ];
      }

      const valueNew = {
        long: longSegments.map(segmentUgly).join(''),
        longSegments,
        short: shortSegments.map(segmentUgly).join(''),
        shortSegments
      }

      onChange(valueNew)
    }
  }

  const revertToDefault = () => {
    onChange('default');
  }
  const establishAsCustom = () => {
    onChange({
      long: '',
      longSegments: [],
      short: '',
      shortSegments: []
    });
  }


  const isCustom = namingPatternSpecsForType?.length > 0 ? namingPatternSpecsForType[0].isCustom : false;
  let contentInner = (
    <>
      <TableCell>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px'}}>
            <Typography variant='body2' style={{ opacity: 0.7, fontSize: '10px', marginBottom: '-2px' }}>{isCustom ? 'Custom' : 'Default'}</Typography>
            {
              isCustom && 
              <IconButton onClick={revertToDefault}>
                <Undo style={{ fontSize: '16px' }}/>
              </IconButton>
            }
            {
              !isCustom && 
              <IconButton onClick={establishAsCustom}>
                <Edit style={{ fontSize: '16px' }}/>
              </IconButton>
            }
          </div>
          <Typography variant='body1'>{typeWritten}</Typography>
          
        </div>
      </TableCell>
      {
        ['specPartsLong', 'specPartsShort'].map((specPartKey) => {
          const namingPattern = namingPatternSpecsForType[0];

          const specParts = namingPattern ? namingPattern[specPartKey as 'specPartsLong' | 'specPartsShort'] : [];

          const identifiersPresent = specParts.map((specPart) => specPart.identifierType);
          const identifiersAllowed = ['name', 'letter', 'number'].filter((identifier) => !identifiersPresent.includes(identifier));

          return (
            <TableCell>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '2px', alignItems: 'center' }}>
                {
                  specParts.map(({ identifierType, segment, namingPatternSegment }, idx) => {
                    return (
                      <div key={idx} style={{ display: 'flex', gap: '4px', alignItems: 'center', padding: '4px 8px', borderRadius: '4px', backgroundColor: isCustom ? '#AFD5F080' : '#eeeeee' }}>
                        <Typography variant='body1' style={{ fontFamily: 'monospace', fontSize: '14px', letterSpacing: -0.2 }}>
                          {
                            segment.split('{{').filter((part) => part.length > 0).map((part, idx) => {
                              if(part.includes('}}')) {
                                const [ identifier, rest ] = part.split('}}');
                                return (
                                  <span key={idx}>
                                    <span style={{ fontWeight: 'bold' }}>
                                      {'{{'}{identifier}{'}}'}
                                    </span>
                                    {rest?.length > 0 ? rest : ''}
                                  </span>
                                )
                              } else {
                                return <span key={idx}>
                                  {part}
                                </span>;
                              }
                            })
                          }
                        </Typography>
                        {
                          isCustom && (
                            <IconButton size='small' onClick={() => deleteSegment(namingPatternSegment)}>
                              <Cancel style={{ fontSize: '18px' }}/>
                            </IconButton>
                          )
                        }
                      </div>
                    )
                  })
                }
                {
                  specParts.length === 0 && !isCustom && <Typography variant='body1' style={{ opacity: 0.7 }}>No naming pattern</Typography>
                }
                { identifiersAllowed.length > 0 && isCustom &&
                  <NamingPatternSegmentSearch
                    identifierTypes={identifiersAllowed}
                    lengthType={specPartKey === 'specPartsLong' ? 'long' : 'short'}
                    onChange={(e: any) => {
                      const namingPatternSegment = e.target.value as NamingPatternSegment;
                      addSegment(namingPatternSegment);
                    }}
                    value={null}
                  />
                }
              </div>
            </TableCell>
          )
      })}
    </>
  );

  return (
    <TableRow>
      {contentInner}
    </TableRow>
  );
}

export { NamingPatternModal };