// a component that allows for the editing and visualization of references added for a candidate
import Typography from '@mui/material/Typography';
import { Alert, Button, CircularProgress, Snackbar, Tooltip } from '@mui/material';
import FileUpload from '../FileUpload';
import styled from 'styled-components';
import React, { useEffect, useMemo, useState } from 'react';
import { TextInput } from '../TextInput';
import InputList from '../InputList';
import CandidateLink from '../CandidateLink';
import NewsArticle from './NewsArticle';
import StaticEditableSource from './StaticEditableSource';
import Modal from '@mui/material/Modal';
import { ModalInner } from '../lower-order';
import { isUrl, useFeathers, useFormInputs } from '../../app/util';
import { classifyLink, sanitizeUrl } from '@branchpolitics/microservice.clean.link';
import { AddButton } from '../AddButton';
import { SwitchControl } from '../lower-order';
import { Info } from '@mui/icons-material';

export const ReferenceEditor = (
  {
    references,
    onProgressPastReferenceStage,
    // the best way to update the references is to use the function mergeReferencesWithDraft, in util.js
    onChangeReferences,
    onChangeActiveDraft,
    disabled,
    candidateId,
    style,
    activeDraft,
    priorityLevel,
    hideSubmitButton = false,
    autoScrapeSocials = true,
    initialEditMode = false,
    newsEnabled = false,
    questionnaireEnabled = true,
    editingEnabled = true,
    scrapedContent
  } = {}
) => {

  const sourcesUsedInProfile = useMemo(() => {
    if(!activeDraft) return [];
    const {
      bioPersonalSources,
      bioPoliticalSources,
      bioProfessionalSources,
      issues,
    } = activeDraft;

    return [].concat(
      bioPersonalSources || [],
      bioPoliticalSources || [],
      bioProfessionalSources || [],
      (issues || []).map(iss => {
        return (iss.stances || []).map(s => s.sources).flat();
      }).flat()
    );
  }, [activeDraft]);


  const feathers = useFeathers();
  const [showAddSourceModal, setShowAddSourceModal] = useState(null);
  const existingLinks = (references?.categories || []).map(c => (c.sources || [])).flat();
  const [error, setError] = useState(null);
  const [sourcesToPrune, setSourcesToPrune] = useState([]);
  const [contentTypesToPrune, setContentTypesToPrune] = useState([]);

  const updateSourcesForType = (type, newVal, pruneSources = false) => {
    const sources = newVal
      ? (Array.isArray(newVal) ? newVal : [newVal])
      : [];
    const categoriesWithout = (references?.categories || []).filter(c => c.type !== type);
    const categoryPrev = (references?.categories || []).find(c => c.type === type);
    const categoryNew = {
      type,
      sources,
      ...(initialEditMode ? { missing: categoryPrev?.missing } : { missing: true }),
    }
    const categoriesNew = [
      ...categoriesWithout,
      categoryNew
    ];

    const valueHasChanged = sources.map(s => s.url).sort().join('') !== (categoryPrev?.sources || []).map(s => s.url).sort().join('');

    if(type === 'website' && activeDraft && valueHasChanged) {
      if(!pruneSources) {
        // If we don't explicitly say we're okay with pruning sources, then we need to check if we're about to prune any stances, etc
        // that rely on the website
        // Filter to stances that have website sources
        const stancesWithWebsite = activeDraft.issues
          .flatMap(({ stances }) => stances)
          .filter(({ sources }) => sources
            .some(({ sourceType }) => sourceType === 'website'));
        const bioPersonalWithWebsite = activeDraft.bioPersonalSources.length && activeDraft.bioPersonalSources.some(({ sourceType }) => sourceType === 'website');
        const bioPoliticalWithWebsite = activeDraft.bioPoliticalSources.length && activeDraft.bioPoliticalSources.some(({ sourceType }) => sourceType === 'website');
        const bioProfessionalWithWebsite = activeDraft.bioProfessionalSources.length && activeDraft.bioProfessionalSources.some(({ sourceType }) => sourceType === 'website');
        if(stancesWithWebsite?.length || bioPersonalWithWebsite || bioPoliticalWithWebsite || bioProfessionalWithWebsite) {
          const contentTypesToPrune = [
            stancesWithWebsite.length && `${stancesWithWebsite.length} stances`,
            bioPoliticalWithWebsite && 'Political background',
            bioProfessionalWithWebsite && 'Professional background',
            bioPersonalWithWebsite && 'Personal background',
          ].filter(Boolean);
          setSourcesToPrune(sources);
          setContentTypesToPrune(contentTypesToPrune);
          return;
        }
      } else {
        const issuesWithWebsiteSourcesRemoved = activeDraft.issues.map(({ sources, stances, ...issue }) => ({
          ...issue,
          // Purge old website from stances and prune stances that were only derived from it
          stances: stances.filter(stance =>
            !stance.sources.some(({ sourceType }) => sourceType === 'website')),
        }));
        setContentTypesToPrune([]);
        setSourcesToPrune([]);
        const bioPersonalSources = activeDraft.bioPersonalSources.filter(({ sourceType }) => sourceType !== 'website');
        const bioPoliticalSources = activeDraft.bioPoliticalSources.filter(({ sourceType }) => sourceType !== 'website');
        const bioProfessionalSources = activeDraft.bioProfessionalSources.filter(({ sourceType }) => sourceType !== 'website');
        onChangeActiveDraft?.({
          issues: issuesWithWebsiteSourcesRemoved,
          ...(bioPersonalSources.length < activeDraft.bioPersonalSources.length ? { bioPersonalSources } : {}),
          ...(bioPoliticalSources.length < activeDraft.bioPoliticalSources.length ? { bioPoliticalSources } : {}),
          ...(bioProfessionalSources.length < activeDraft.bioProfessionalSources.length ? { bioProfessionalSources } : {}),
        });
      }
    }

    onChangeReferences({
      checked: areReferencesChecked(categoriesNew.slice(), priorityLevel),
      categories: categoriesNew.slice()
    });
  };
  const updateTypeAsMissing = (type, missing) => {
    const categoriesWithout = (references?.categories || []).filter(c => c.type !== type);
    const categoriesNew = [
      ...categoriesWithout,
      {
        type,
        sources: [],
        missing
      }
    ];

    onChangeReferences({
      checked: areReferencesChecked(categoriesNew, priorityLevel),
      categories: categoriesNew.slice()
    });
  };

  /* Website */
  const websiteCategory = (references?.categories || []).find(c => c.type === 'website');
  const websiteSource = (websiteCategory?.sources || [])[0];
  const website = websiteSource?.url ?? websiteSource;
  const websiteMissing = websiteCategory?.missing;
  const [previousWebsite, setPreviousWebsite] = useState(website);
  const [triggerWebsiteScrape, setTriggerWebsiteScrape] = useState(false);
  const onWebsiteEditBlur = () => setTriggerWebsiteScrape(true);
  const [scraping, setScraping] = useState(false);
  const [fetchedLinks, setFetchedLinks] = useState([]);


  const scrapeWebsiteForSocials = async () => {
    if(scraping) return;
    setScraping(true);
    try {
      const scrapingResults = await feathers.getService('webscrape').create({
        url: website,
        type: 'website',
        candidateId,
        fieldsToScrape: ['twitter', 'instagram', 'facebook']
      });
      const { links } = scrapingResults;
      setFetchedLinks(links);
    } catch (err) {
      console.log('Error while scraping', err);
      setError(err);
    } finally {
      setScraping(false);
    }
  };

  useEffect(() => {
    if(triggerWebsiteScrape) {
      setTriggerWebsiteScrape(false);
      setPreviousWebsite(website);
      if(website && website !== previousWebsite && autoScrapeSocials) {
        scrapeWebsiteForSocials();
      }
    }
  }, [website, previousWebsite, triggerWebsiteScrape]);

  /* Socials */
  const socialsCategory = (references?.categories || []).find(c => c.type === 'social');
  const [socialsLocal, setSocialsLocal] = useState(
    socialsCategory?.sources ||
    [{ url: '' }]
  );
  const socialsMissing = socialsCategory?.missing;

  /* News */
  const newsCategory = (references?.categories || []).find(c => c.type === 'news');
  const [newsLocal, setNewsLocal] = useState(
    newsCategory?.sources ||
    [{ url: '', title: '' }]
  );

  const newsMissing = newsCategory?.missing ?? (priorityLevel === 'low' ? true : false);
  const [newsError, setNewsError] = useState({ url: false, title: false });

  /* Questionnaire */
  const questionnaireCategory = (references?.categories || []).find(c => c.type === 'questionnaire');
  const [questionnaireLocal, setQuestionnaireLocal] = useState(
    questionnaireCategory?.sources ||
    [{ url: '', title: '' }]
  );

  // const questionnaireSource = ((references?.categories || []).find(c => c.type === 'questionnaire')?.sources || [])[0];
  // const questionnaire = questionnaireSource?.url ?? questionnaireSource;
  // const questionnaireCount = !questionnaire
  //   ? 0
  //   : (sourcesUsedInProfile || []).filter(s => s?.type === 'questionnaire').length;


  const onChangeMulti = type => (res) => {
    const { name, value } = res.target;
    const newArray = value.slice();
    if(type === 'social') {
      setSocialsLocal(newArray.slice());
    } else if(type === 'news') {
      setNewsLocal(newArray.slice());
      setNewsError({
        ...newsError,
        title: isUrl(newsLocal[0]?.title),
        url: newsLocal[0]?.url && !isUrl(newsLocal[0]?.url)
      });

    }
    updateSourcesForType(type, newArray);
  };

  const onAddMulti = type => (res) => {
    const { name, value } = res.target;

    let existingArray;
    if(type === 'social') existingArray = (socialsLocal || []).slice();
    else if(type === 'news') existingArray = (newsLocal || []).slice();
    const newArray = [].concat(
      existingArray,
      (Array.isArray(value) ? value : [value])
    ).filter(l => l?.url?.length > 0);

    const uniqueArray = newArray.filter((v, i) => newArray.findIndex(v2 => v2?.url === v?.url) === i);
    onChangeMulti(type)({
      target: {
        name,
        value: uniqueArray
      }
    });
  };

  useEffect(() => { // sets social links upon scrapeWebsiteForSocials updating fetchedLinks
    if(Boolean(fetchedLinks.length)) {
      onAddMulti('social')({
        target: {
          'name': 'social',
          value: ((fetchedLinks || []).map(l => ({ url: l })))
        }
      });
    }
  }, [fetchedLinks]);

  const errorMessage = useMemo(() => {
    if(typeof error === 'string') return error;
    if(typeof error === 'object') {
      if(error?.type === 'FeathersError') {
        let messageToShow;
        if(error?.errors && Object.values(error?.errors).length > 0) {
          messageToShow = Object.values(error?.errors).join(' ');
        } else {
          messageToShow = error?.message;
        }
        switch (error?.code) {
          case 400:
            if(messageToShow) return messageToShow;
            return 'There was a problem with the data you submitted. Please check the form and try again.';
          case 401:
          case 403:
            if(messageToShow) return messageToShow;
            return 'You are not authorized to perform this action.';
          default:
            return messageToShow;
        }
      } else return error?.message;
    }

    return 'There was an error when saving. Please try again!';
  }, [error]);

  return (
    <Wrapper style={style}>
      <Modal
        open={Boolean(showAddSourceModal)}
        onClose={() => setShowAddSourceModal(null)}
      >
        <ModalInner
          style={{
            minHeight: 'auto',
            width: '400px'
          }}
        >
          <AddSourceModal
            type={showAddSourceModal}
            existingLinks={existingLinks}
            onAdd={(newSource) => {
              if(['social', 'news'].includes(showAddSourceModal)) {
                onAddMulti(showAddSourceModal)({
                  target: {
                    name: showAddSourceModal,
                    value: newSource
                  }
                });
              } else {
                updateSourcesForType(showAddSourceModal, newSource);
              }
              setShowAddSourceModal(null);
            }}
            onCancel={() => setShowAddSourceModal(null)}
          />
        </ModalInner>
      </Modal>
      <Modal
        open={Boolean(contentTypesToPrune.length > 0)}
        onClose={() => {
          setContentTypesToPrune([]);
          setSourcesToPrune([]);
        }}
      >
        <ModalInner
          style={{
            minHeight: 'auto',
            width: '400px'
          }}
        >
          <PruneSourcesModal
            sourcesToPrune={sourcesToPrune}
            contentTypesToPrune={contentTypesToPrune}
            updateSourcesForType={updateSourcesForType}
            onCancel={() => {
              setSourcesToPrune([]);
              setContentTypesToPrune([]);
            }}
          />
        </ModalInner>
      </Modal>
      <Section condensed={initialEditMode ? 0 : 1}>
        <Typography variant={'h4'} style={{ fontSize: '16px' }}>
          Campaign website
        </Typography>
        {
          initialEditMode
            ? <SwitchControl
              missing={websiteMissing}
              displayName={'Website'}
              setMissingTo={(newVal) => updateTypeAsMissing('website', newVal)}
            >
              <TextInput
                disabled={disabled}
                onChange={e => updateSourcesForType('website', e?.target?.value)}
                value={website || ''}
                placeholder="www.candidate.com"
                mode="condensed"
                style={{
                  fontSize: '16px',
                  flexGrow: 1
                }}
                onBlur={() => onWebsiteEditBlur()}
              />
              {
                website?.length > 5 &&
                <Typography onClick={scrapeWebsiteForSocials} variant={'body2'}
                            style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '16px' }}>
                  Scrape website
                </Typography>
              }
            </SwitchControl>
            :
            <>
              {StaticEditableSource(
                (
                  website
                    ? { url: website, mediaType: 'website' }
                    : undefined
                ),
                0,
                {
                  name: 'website',
                  onSetElement: editingEnabled ? (e) => updateSourcesForType('website', e?.target?.value) : null,
                  onDeleteElement: editingEnabled ? () => updateSourcesForType('website', []) : null,
                  style: { margin: '6px 0 0', ...(website ? {} : { display: 'none' }) },
                  existingLinks: sourcesUsedInProfile,
                  scrapedContent
                }
              )}
              <div style={website ? { display: 'none' } : {}}>
                <Typography variant={'body1'} style={{ marginBottom: '4px' }}>
                  No website
                </Typography>
                {
                  editingEnabled &&
                  <AddButton onClick={() => setShowAddSourceModal('website')}>
                    Add website
                  </AddButton>
                }
              </div>
            </>
        }
      </Section>
      <Section condensed={initialEditMode ? 0 : 1}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant={'h4'} style={{ fontSize: '16px' }}>
            Candidate social media
          </Typography>
          {
            scraping &&
            <>
              <CircularProgress style={{ marginLeft: '16px' }} size={15}/>
              <Typography variant={'body2'} style={{ marginLeft: '8px' }}>
                Scraping social media...
              </Typography>
            </>
          }
        </div>
        {
          initialEditMode
            ?
            <SwitchControl
              missing={socialsMissing}
              displayName={'Social Media'}
              setMissingTo={(newVal) => updateTypeAsMissing('social', newVal)}
            >
              <InputList
                style={{ maxWidth: '300px' }}
                enableReorder={false}
                disabled={disabled}
                name="socials"
                addText={'Add link'}
                onChange={(val) => onChangeMulti('social')(val)}
                value={socialsLocal}
                renderItem={CandidateLink}
                emptyItem={{ url: '' }}
              />
            </SwitchControl>
            : (
              <InputList
                style={{ maxWidth: '300px', paddingTop: '0px' }}
                enableReorder={false}
                disabled={disabled}
                name="social"
                addText={'Add link'}
                onChange={(val) => onChangeMulti('social')(val)}
                value={socialsLocal.slice()}
                renderItem={StaticEditableSource}
                onAdd={() => {
                  setShowAddSourceModal('social');
                }}
                allowRemoveItem={editingEnabled}
                allowAddItem={editingEnabled}
                extraProps={{
                  existingLinks: sourcesUsedInProfile,
                  scrapedContent,
                  ...(editingEnabled ? { } : { onSetElement: null })
                }}
                emptyList={(
                  <div>
                    <Typography variant={'body1'}>No social media</Typography>
                  </div>
                )}
              />
            )
        }
      </Section>
      {
        newsEnabled &&
        <Section condensed={initialEditMode ? 0 : 1}>
          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '4px' }}>
            <Typography variant={'h4'} style={{ fontSize: '16px' }}>
              Articles
            </Typography>
            {
              priorityLevel === 'low' &&
              <>
                <Typography variant={'body2'} style={{ opacity: 1.0, marginLeft: '12px' }}>
                  Optional
                </Typography>
                <Tooltip
                  title="This is a low priority candidate. To save time, you can avoid doing a news search on them.">
                  <Info style={{ height: '18px', width: '18px', color: '#888888', marginBottom: '2px' }}/>
                </Tooltip>
              </>
            }
          </div>
          {
            initialEditMode
              ?
              <SwitchControl
                missing={newsMissing}
                displayName={'News Articles'}
                setMissingTo={(newVal) => updateTypeAsMissing('news', newVal)}
              >
                <InputList
                  enableReorder={false}
                  disabled={disabled}
                  extraProps={{ newsError }}
                  name="news"
                  addText={'Add article'}
                  onChange={(val) => onChangeMulti('news')(val)}
                  value={newsLocal.slice()}
                  renderItem={NewsArticle}
                  emptyItem={{ url: '', title: '' }}
                />
              </SwitchControl>
              : (
                <InputList
                  style={{ maxWidth: '300px', paddingTop: '4px' }}
                  enableReorder={false}
                  disabled={disabled}
                  name="news"
                  addText={'Add link'}
                  onChange={(val) => onChangeMulti('news')(val)}
                  value={newsLocal.slice()}
                  renderItem={StaticEditableSource}
                  onAdd={() => {
                    setShowAddSourceModal('news');
                  }}
                  allowRemoveItem={editingEnabled}
                  allowAddItem={editingEnabled}
                  extraProps={{
                    existingLinks: sourcesUsedInProfile,
                    ...(editingEnabled ? { } : { onSetElement: null }),
                    scrapedContent
                  }}
                  emptyList={(
                    <div>
                      <Typography variant={'body1'}>No news articles</Typography>
                    </div>
                  )}
                />
              )
          }
        </Section>
      }
      {
        questionnaireEnabled &&
        <Section condensed={initialEditMode ? 0 : 1}>
          <Typography variant={'h4'} style={{ fontSize: '16px' }}>
            Questionnaire
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {
              questionnaireCategory?.sources?.length > 0
                ?
                questionnaireCategory.sources.map((q, idx) => {
                  return (
                    <div key={idx} style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                      <Typography variant="body1" style={{ marginRight: '8px' }}>
                        {q?.title || 'Branch questionnaire'}
                      </Typography>
                      <a href={q.url} target="_blank" rel="noreferrer">
                        <Typography noWrap variant="body2" color="secondary">
                          {`${q?.url.slice(0, 20)}...${q.url.slice(-30)}`}
                        </Typography>
                      </a>
                    </div>
                  );
                })
                :
                <>
                  <div style={{ flex: 1 }}>
                    <Typography variant="body1">No questionnaire</Typography>
                  </div>
                  <FileUpload
                    style={{ width: 'auto' }}
                    onUploadSuccess={(res) => updateSourcesForType('questionnaire', res)}
                    fileType="questionnaire"
                  />
                </>
            }
          </div>
        </Section>
      }
      {
        initialEditMode && !hideSubmitButton &&
        <Section>
          <Button
            variant={'contained'}
            size={'large'}
            color={'primary'}
            disabled={disabled || !references?.checked}
            onClick={onProgressPastReferenceStage}
          >
            Save
          </Button>
        </Section>
      }
      <Snackbar
        open={Boolean(error)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={6000}
        style={{ position: 'absolute' }}
        onClose={() => setError(undefined)}
      >
        <Alert
          severity="error"
          onClose={() => setError(undefined)}
          elevation={6}
          variant="filled"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Section = styled.div`
  ${props => props.condensed ? `margin-bottom: 36px;` : `margin-bottom: 72px;`}

  display: flex;
  flex-direction: column;
  align-items: stretch h4 {
  ${props => props.condensed ? `margin-bottom: 4px;` : `margin-bottom: 8px;`}
}
`;

const AddSourceModal = ({
                          type,
                          onAdd,
                          existingLinks,
                          onCancel
                        }) => {
  const [formInputs, onChangeFormInputs] = useFormInputs({
    url: '',
    title: '',
  });
  const [error, setError] = useState(null);
  let name, titleEnabled;
  if(type === 'website') {
    name = 'candidate website';
  } else if(type === 'social') {
    name = 'social media';
  } else if(type === 'news') {
    titleEnabled = true;
    name = 'news article';
  } else if(type === 'other') {
    name = 'third-party source';
    titleEnabled = true;
  }
  const submittable = (
    formInputs?.url?.length > 5 &&
    titleEnabled ? (formInputs?.title?.length >= 3) : true
  );

  const addSource = () => {
    if(!submittable) return;
    setError(null);

    console.log('existing links', existingLinks);
    console.log('form inputs', formInputs);
    const existingUrls = existingLinks.map(s => s?.url ? s?.url : s).filter(s => Boolean(s) && typeof s === 'string' && s.trim().length > 0).map(u => {
      console.log('sanitizing url', u);
      return sanitizeUrl(u).toLowerCase();
    });

    const thisUrl = sanitizeUrl(formInputs?.url).toLowerCase();

    if(existingUrls.includes(thisUrl)) {
      setError(`The url '${thisUrl}' has already been added as a source.`);
      return;
    }

    let formattedSource;
    if(type === 'website') {
      if(!isUrl(formInputs?.url)) {
        setError(`"${formInputs?.url}" is not a valid url.  Please check your inputs.`);
        return;
      }
      formattedSource = {
        mediaType: 'website',
        title: 'Candidate website',
        url: formInputs?.url
      };
    } else if(type === 'social') {
      const mediaType = classifyLink(formInputs?.url, 'other');
      if(mediaType === 'other') {
        setError('This social media type is not supported. Please check your inputs.');
        return;
      } else {
        formattedSource = {
          mediaType: mediaType,
          title: mediaType.slice(0, 1).toUpperCase() + mediaType.slice(1).toLowerCase(),
          url: formInputs?.url
        };
      }
    } else if(type === 'news') {
      if(!formInputs?.url || !formInputs?.title) {
        setError(`Please fill out all fields.`);
        return;
      }
      if(!isUrl(formInputs?.url)) {
        setError(`"${formInputs?.url}" is not a valid url.  Please check your inputs.`);
        return;
      }
      if(isUrl(formInputs?.title)) {
        setError(`"${formInputs?.title}" is not a valid title.  Please check your inputs.`);
        return;
      }
      formattedSource = {
        mediaType: 'other',
        title: formInputs?.title,
        url: formInputs?.url
      };
    } else if(type === 'other') {
      formattedSource = {
        mediaType: 'other',
        title: formInputs?.title,
        url: formInputs?.url
      };
    }

    onAdd(formattedSource);
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '24px' }}>
      <Typography variant={'h2'}>New {name}</Typography>
      {
        error &&
        <Alert severity="error">{error}</Alert>
      }
      {
        titleEnabled &&
        <TextInput
          onChange={onChangeFormInputs}
          value={formInputs.title}
          name={'title'}
          placeholder="Article Title"
          mode="condensed"
          style={{
            fontSize: '16px',
            width: 'calc(100% - 15px * 2)'
          }}
        />
      }
      <TextInput
        onChange={onChangeFormInputs}
        value={formInputs.url}
        name={'url'}
        placeholder="URL (https://example.com)"
        mode="condensed"
        style={{
          fontSize: '16px',
          width: 'calc(100% - 15px * 2)'
        }}
      />
      <Button
        variant={'contained'}
        color={'primary'}
        disabled={!submittable}
        onClick={addSource}
      >
        Add source
      </Button>
    </div>
  );
};

const PruneSourcesModal = ({
                             sourcesToPrune,
                             contentTypesToPrune,
                             updateSourcesForType,
                             onCancel,
                           }) => {
  return <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '24px' }}>
    <Typography variant={'h2'}>Warning!</Typography>
    <Typography variant="p">
      {sourcesToPrune.length ? 'Editing' : 'Removing'} the candidate's website will remove the following content:
    </Typography>
    <ul style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      {
        contentTypesToPrune.map((content, idx) => <li key={idx}>
          <Typography variant={'h4'}>
            {content}
          </Typography>
        </li>)
      }
    </ul>
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}>
      <Button
        variant="text"
        onClick={() => onCancel()}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={() => updateSourcesForType('website', sourcesToPrune, true)}
      >
        Confirm
      </Button>
    </div>
  </div>;
};


const areReferencesChecked = (categories, priorityLevel) => {
  const catRef = categories || [];
  // check conditions for each category
  const websiteCat = catRef.find(c => c.type === 'website');
  const websiteValid = (
    (websiteCat?.sources || []).filter(s => ((typeof (s) === 'string' ? s : s?.url) || '').trim().length > 0).length === 1 ||
    websiteCat?.missing
  );
  const socialCat = catRef.find(c => c.type === 'social');
  const socialsValid = (
    (socialCat?.sources || []).filter(s => ((typeof (s) === 'string' ? s : s?.url) || '').trim().length > 0).length > 0 ||
    socialCat?.missing
  );
  const newsCat = catRef.find(c => c.type === 'news');
  const newsValid = (
    (newsCat?.sources || []).filter(s => ((typeof (s) === 'string' ? s : s?.url) || '').trim().length > 0).length > 0 ||
    newsCat?.missing
  ) || priorityLevel === 'low';

  return (
    websiteValid &&
    socialsValid &&
    newsValid
  );
};