import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  IconButton,
  Modal,
  Select,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  Check,
  Edit,
  InfoOutlined,
  DeleteOutline,
  DataObject as CurlyBraces
} from '@mui/icons-material';
import { useFeathers, humanizeList } from '../../app/util';
import { DataDetailToolbar, ModalInner, PreviewStateConfiguration, NamingPatternModal } from '../../components';
import { StateConfigLinkTypeModal } from '../../components/StateConfigLinkTypeModal';
import { humanizeDistrictType } from '../../app/util/humanize-district-type';

const StateConfig = () => {
  const matchName = useParams().matchName;
  const feathers = useFeathers();
  const history = useHistory();
  const [stateConfig, setStateConfig] = useState(null);
  const [districtTypesByTier, setDistrictTypesByTier] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [addingSubtype, setAddingSubtype] = useState(false);
  const [linkingTypeConfig, setLinkingTypeConfig] = useState(null);
  const [editingNamingPattern, setEditingNamingPattern] = useState(null);

  const loadStateConfig = async () => {
    const stateCode = matchName.split('-')[0];
    try {
      const [
        stateConfig,
        districtConfigs,
      ] = await Promise.all([
        feathers.getService('state-configurations').get(stateCode),
        feathers.getService('district-configurations').find(),
      ]);

      setStateConfig(stateConfig);
      setDistrictTypesByTier(districtConfigs);

      const hasSubtypesConfigured = stateConfig.typeConfigurations.length > 2;
      // Default to editing if nothing is configured yet
      setEditing(!hasSubtypesConfigured);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (feathers) {
      loadStateConfig();
    }
  }, [feathers, useParams]);

  // input functions
  const onChangePrettyName = (type, prettyName) => {
    onChangeRowValue(type, 'prettyName', prettyName);
  };

  const onChangeRowValue = (type, key, value) => {
    const newTypeConfigurations = stateConfig.typeConfigurations.map(t => {
      if (t.type === type) {
        return {
          ...t,
          [key]: value,
        };
        // Do we need to remove this type other types' requiredWithout lists?
      } else if (
        key === 'requiredForFullMatch'
        && !value.startsWith('requiredWithout:')
        && t.requiredForFullMatch.includes(type)
      ) {
        const typeListString = t.requiredForFullMatch.split(':')[1];
        const filteredTypes = typeListString.split(',')
          .filter(matchedType => matchedType !== type);
        const requiredForFullMatch = filteredTypes.length
          ? `requiredWithout:${filteredTypes.join(',')}`
          : 'yes';
        return {
          ...t,
          requiredForFullMatch,
        };
      }
      return t;
    });
    setStateConfig({
      ...stateConfig,
      typeConfigurations: newTypeConfigurations,
    });
  };

  const onSave = async () => {
    await feathers.getService('state-configurations').patch(
      stateConfig.stateCode,
      {
        typeConfigurations: stateConfig.typeConfigurations,
        typeStructure: stateConfig.typeStructure,
      },
    );
    setEditing(false);
  };

  const addSubtype = (parentType, subtype) => {
    // called once we have  type
    // add a new subtype to the parent type
    const newTypeHierarchy = {
      ...stateConfig.typeStructure,
      [parentType]: [
        ...(stateConfig.typeStructure?.[parentType] || []),
        subtype,
      ],
    };

    const defaultConfigForType = {
      type: subtype,
      tierNumber: parentType === 'state' ? 2 : 3,
      optionalForStructure: false,
      requiredForFullMatch: 'yes',
      supportsMeasures: ['city', 'county'].includes(subtype),
    };

    const newTypeConfigurations = [
      ...stateConfig.typeConfigurations,
      defaultConfigForType,
    ];

    setStateConfig({
      ...stateConfig,
      typeStructure: newTypeHierarchy,
      typeConfigurations: newTypeConfigurations,
    });
    setAddingSubtype(false);
  };

  const rows = loading
    ? Array.from({ length: 6 })
    : (stateConfig?.typeConfigurations || [])
      .filter(t => {
        if (!districtTypesByTier) return false;
        const type = t.type;
        const isTier2 = districtTypesByTier['state'].includes(type);
        return isTier2;
      })
      .sort((a, b) => a.type.localeCompare(b.type));

  const subtypeOptions = addingSubtype
    ? districtTypesByTier[addingSubtype]
      .filter(t => !(stateConfig.typeStructure?.[addingSubtype] || []).includes(t))
      .sort()
    : null;

  let logicalRowIndex = 0;
  const backgroundColorsAlternating = ['#F9F9F9', '#FFFFFF'];

  const cancelEdit = () => {
    loadStateConfig();
  };

  const deleteType = (typeConfig) => {
    const typesToRemove = [
      typeConfig.type,
      ...(stateConfig.typeStructure?.[typeConfig.type] || []),
    ];
    const typeConfigurations = stateConfig.typeConfigurations
      .filter(({ type }) => !typesToRemove.includes(type));
    const typeStructure = Object.fromEntries(
      Object.entries(stateConfig.typeStructure)
        .filter(([type]) => !typesToRemove.includes(type))
        .map(([type, subTypes]) => ([
          type,
          subTypes.filter(subType => !typesToRemove.includes(subType)),
        ])),
    );
    setStateConfig({
      ...stateConfig,
      typeConfigurations,
      typeStructure,
    });
  };

  const handleLinkingTypeModalClose = (type, group) => {
    const fullGroup = [type, ...group];
    const requiredForFullMatchUpdates = fullGroup.reduce(
      (updates, currentType) => {
        const otherTypes = fullGroup.filter(type => type !== currentType);
        updates[currentType] = otherTypes
          ? `requiredWithout:${otherTypes.join(',')}`
          : 'yes';
        return updates;
      },
      { [type]: `requiredWithout:${group.join(',')}` },
    );

    const previouslyLinkedTypes = [...fullGroup.reduce(
      (linkedTypes, nextType) => {
        const newTypes = stateConfig.typeConfigurations.find(t => t.type === nextType)
          .requiredForFullMatch
          ?.split(':')?.[1]?.split(',');
        newTypes?.forEach(linkedType => linkedTypes.add(linkedType));
        return linkedTypes;
      },
      new Set(),
    )];

    const typesToRemove = (
      previouslyLinkedTypes?.filter(type => !group.includes(type)) || []
    );

    typesToRemove.forEach((type) => {
      requiredForFullMatchUpdates[type] = 'yes';
    });

    setStateConfig({
      ...stateConfig,
      typeConfigurations: stateConfig.typeConfigurations.map(
        ({ requiredForFullMatch, type, ...typeConfig }) => ({
          ...typeConfig,
          type,
          requiredForFullMatch: (
            requiredForFullMatchUpdates[type] || requiredForFullMatch
          ),
        }),
      ),
    });
    // Close the modal
    setLinkingTypeConfig(null);
  };

  return (
    <Wrapper>
      <DataDetailToolbar
        navTree={[
          { text: 'Districts', to: '/states' },
          { text: stateConfig?.stateName || '--', to: `/states/${matchName}` },
          { text: 'Edit configuration' },
        ]}
        onBack={() => history.push(`/states/${matchName}`)}
        actionButtonsComponent={
          editing
            ?
            <>
              <Button
                variant="text"
                sx={{ marginRight: '12px' }}
                onClick={() => cancelEdit()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onSave}>
                Save
              </Button>
            </>
            : 
            <>
              <Tooltip title="Edit naming patterns">
                <IconButton onClick={() => setEditingNamingPattern(true)}>
                  <CurlyBraces/>
                </IconButton>
              </Tooltip>
              <IconButton
                onClick={() => setEditing(true)}
              >
                <Edit />
              </IconButton>
            </>
        }
      />
      <div style={{ height: '36px' }} />
      {
        !editing &&
        <Typography variant="body1" style={{ marginBottom: '12px' }}>
          {`Last edited on ${stateConfig && moment(stateConfig.updatedAt).format('LLL')}`}
        </Typography>
      }
      {
        !editing && stateConfig &&
        <PreviewStateConfiguration stateConfig={stateConfig} style={{ marginBottom: '24px' }}/>
      }
      <TableContainer
        component={Paper}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                '*': {
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                },
              }}>
              <TableCell>
                <div>
                  Type
                </div>
              </TableCell>
              {
                editing &&
                <TableCell>
                  <div>
                    Pretty name
                  </div>
                </TableCell>
              }
              <TableCell>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    justifyContent: 'center',
                  }}
                >
                  Standard
                  <Tooltip
                    title={`Determines whether this should be researched for
                     every parent district. If this is a rare or infrequent
                      type, leave this unchecked.`}>
                    <InfoOutlined style={{ fontSize: '16px' }} />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell
                style={{
                  width: '20%',
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    justifyContent: 'center',
                  }}
                >
                  Required for Match
                  <Tooltip
                    title={`Is every person living in the state expected to 
                    have at least one of this district type?`}>
                    <InfoOutlined style={{ fontSize: '16px' }} />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    justifyContent: 'center',
                  }}
                >
                  Referendums
                  <Tooltip
                    title={`Determined whether referendums can be added for 
                    this district type.`}>
                    <InfoOutlined style={{ fontSize: '16px' }} />
                  </Tooltip>
                </div>
              </TableCell>
              {
                editing && <TableCell style={{ fontWeight: 'bold' }}>
                  Actions
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              if (!row) {
                return (
                  <TableRow
                    key={`${index}`}
                    style={{
                      backgroundColor: backgroundColorsAlternating[+(index % 4 > 1)],
                    }}
                  >
                    {
                      Array.from({ length: editing ? 5 : 4 }).map((_, column) => (
                        <TableCell
                          key={column}
                          style={{
                            border: index % 2 ? null : 'none',
                            paddingTop: index % 2 ? 0 : null,
                            paddingBottom: index % 2 ? null : 0,
                            paddingLeft: !column && index % 2 ? '48px' : '16px',
                          }}
                        >
                          <Skeleton
                            variant="text"
                            height={64}
                            width={column < 2 ? 96 : 36}
                            style={{
                              marginLeft: column > 1 ? 'auto' : null,
                              marginRight: column > 1 ? 'auto' : null,
                            }}
                          />
                        </TableCell>
                      ))
                    }
                  </TableRow>
                );
              }

              const childrenTypes = districtTypesByTier[row.type] || [];
              const childrenConfigs = stateConfig?.typeConfigurations
                ?.filter(t => childrenTypes.includes(t.type)) || [];
              const childrenTypesNotAdded = childrenTypes
                .filter(t => !childrenConfigs.map(c => c.type).includes(t));
              const displayNoChildrenMessage = (
                !editing
                && childrenTypes.length > 0
                && childrenConfigs.length === 0
              );
              const displayAddChildButton = (
                editing
                && childrenTypesNotAdded?.length > 0
              );
              const hasFooterSection = (
                displayNoChildrenMessage
                || displayAddChildButton
              );

              return (
                <React.Fragment key={row.type}>
                  {
                    [row, ...childrenConfigs].map((row, i, arr) => {
                      const isChildRow = childrenTypes.includes(row.type);
                      if (!isChildRow) {
                        logicalRowIndex++;
                      }
                      return (
                        <TableRow
                          key={row.type}
                          sx={{
                            '& > *': {
                              backgroundColor: backgroundColorsAlternating[logicalRowIndex % 2],
                              paddingBottom: '18px',
                              paddingTop: i === 0 ? '18px' : null,
                              // control the border display, only display at the end of each group
                              ...(
                                !(i === (arr.length - 1) && !hasFooterSection) ? {
                                  borderBottom: 'unset',
                                  paddingBottom: '18px',
                                } : {}
                              ),
                            },
                          }}
                        >
                          <TableCell
                            scope="row"
                            style={{ paddingLeft: isChildRow ? '48px' : '16px' }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {humanizeDistrictType(row.type, editing ? null : stateConfig.typeConfigurations)}
                            </Typography>
                          </TableCell>
                          {
                            editing &&
                            <TableCell
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              <TextField
                                variant="standard"
                                fullWidth={true}
                                value={row.prettyName || ''}
                                size="small"
                                onChange={(e) => {
                                  onChangePrettyName(row.type, e.target.value);
                                }}
                              />
                            </TableCell>
                          }
                          <TableCell style={{ textAlign: 'center' }}>
                            {
                              childrenTypes.includes(row.type) ? (
                                editing
                                  ?
                                  <Checkbox
                                    size="small"
                                    checked={!row.optionalForStructure}
                                    style={{ padding: 0 }}
                                    onChange={(e) => {
                                      onChangeRowValue(
                                        row.type,
                                        'optionalForStructure',
                                        !row.optionalForStructure,
                                      );
                                    }}
                                  />
                                  : (
                                    !row.optionalForStructure
                                      ? <Check />
                                      : null
                                  )
                              ) : null
                            }
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {
                              editing
                                ?
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 16,
                                  }}
                                >
                                  <Select
                                    size="small"
                                    value={
                                      row.requiredForFullMatch?.startsWith('requiredWithout')
                                        ?
                                        // 'requiredWithout:'.length
                                        row.requiredForFullMatch.length <= 16
                                          // Empty requiredWithout is just a "yes"
                                          ? 'yes'
                                          : 'linked'
                                        : row.requiredForFullMatch
                                    }
                                    onChange={(e) => {
                                      if (e.target.value === 'linked') {
                                        return;
                                      }
                                      onChangeRowValue(
                                        row.type,
                                        'requiredForFullMatch',
                                        e.target.value,
                                      );
                                    }}
                                  >
                                    <MenuItem value="yes">Yes</MenuItem>
                                    <MenuItem value="no">No </MenuItem>
                                    <MenuItem
                                      value="linked"
                                      onClick={() => setLinkingTypeConfig(row)}
                                    >
                                      {
                                        row.requiredForFullMatch.length > 16
                                          ? 'Linked to'
                                          : 'Link to'
                                      }
                                    </MenuItem>
                                  </Select>
                                  {(row.requiredForFullMatch.length || 0) > 16 && (
                                    <Tooltip
                                      title={humanizeList(
                                        row.requiredForFullMatch.split(':')[1].split(',')
                                          .map(linkedType => (
                                            humanizeDistrictType(linkedType, stateConfig.typeConfigurations)
                                          )),
                                        'and',
                                      )}
                                    >
                                      <InfoOutlined />
                                    </Tooltip>
                                  )}
                                </div>
                                : (
                                  <>
                                    {
                                      row.requiredForFullMatch === 'no'
                                        ? null
                                        : <Check />
                                    }
                                    {
                                      row.requiredForFullMatch
                                        ?.startsWith('requiredWithout')
                                        ?
                                        <Typography variant="body2">
                                          At least {humanizeList(
                                          [
                                            row.type,
                                            ...row.requiredForFullMatch.split(':')[1].split(','),
                                          ].map(linkedType => (
                                            humanizeDistrictType(linkedType, stateConfig.typeConfigurations)
                                          )),
                                          'or',
                                        )} is required.
                                        </Typography>
                                        : null
                                    }
                                  </>
                                )
                            }
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {
                              editing
                                ?
                                <Checkbox
                                  size="small"
                                  checked={row.supportsMeasures}
                                  style={{ padding: 0 }}
                                  onChange={(e) => {
                                    onChangeRowValue(
                                      row.type,
                                      'supportsMeasures',
                                      !row.supportsMeasures,
                                    );
                                  }}
                                />
                                : (
                                  row.supportsMeasures
                                    ? <Check />
                                    : null
                                )
                            }
                          </TableCell>
                          {
                            editing &&
                            <TableCell>
                              <IconButton
                                color="error"
                                onClick={() => deleteType(row)}
                              >
                                <DeleteOutline style={{ marginBottom: '2px' }} />
                              </IconButton>
                            </TableCell>
                          }
                        </TableRow>
                      );
                    })
                  }
                  {
                    hasFooterSection &&
                    <TableRow key={`${index}-subtype`}>
                      <TableCell
                        style={{
                          paddingTop: '16px',
                          paddingBottom: '16px',
                          paddingLeft: '48px',
                        }}
                        colSpan={6}
                      >
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '12px',
                        }}>
                          {
                            displayNoChildrenMessage &&
                            <Typography variant="body2">
                              No subtypes added.
                            </Typography>
                          }
                          {
                            childrenTypesNotAdded?.length > 0 && editing &&
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => setAddingSubtype(row.type)}
                              startIcon={
                                <Add style={{ marginBottom: '2px' }} />
                              }
                            >
                              Add subtype
                            </Button>
                          }
                        </div>
                      </TableCell>
                    </TableRow>
                  }
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {
        editing &&
        <div style={{ marginTop: '24px' }}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setAddingSubtype('state')}
            startIcon={<Add style={{ marginBottom: '2px' }} />}
          >
            Add type
          </Button>
        </div>
      }
      <Modal
        open={Boolean(addingSubtype)}
        onClose={() => setAddingSubtype(false)}
      >
        <ModalInner onClose={() => setAddingSubtype(false)}>
          <Typography variant="h3">Add subtype</Typography>
          <div style={{ height: '24px' }} />
          <Select
            variant="outlined"
            label="Select subtype"
            value={''}
            onChange={(e) => addSubtype(addingSubtype, e.target.value)}
          >
            {
              (subtypeOptions || []).map(t => (
                <MenuItem
                  key={t}
                  value={t}
                >
                  {humanizeDistrictType(t, stateConfig?.typeConfigurations)}
                </MenuItem>
              ))
            }
          </Select>
          <div style={{ height: '24px' }} />
        </ModalInner>
      </Modal>
      {linkingTypeConfig &&
        <StateConfigLinkTypeModal
          linkingTypeConfig={linkingTypeConfig}
          onConfirm={(type, group) => handleLinkingTypeModalClose(type, group)}
          onCancel={() => setLinkingTypeConfig(null)}
          typeConfigs={stateConfig?.typeConfigurations}
        />
      }
      <NamingPatternModal
        open={editingNamingPattern && stateConfig !== null}
        onClose={() => setEditingNamingPattern(false)}
        onReloadData={() => console.log(`finished updating naming pattern`)}
        stateName={stateConfig?.stateName || ''}
        mode='state'
      />
    </Wrapper>
  );
};


const Wrapper = styled.div`
    width: calc(100% - 2 * 24px);
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: stretch;
`;

export default StateConfig;
