import React from 'react';
import styled from 'styled-components';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import moment from 'moment';

const DateRangePicker = ({
	startDate,
	endDate,
	onChangeStartDate,
	onChangeEndDate,
	style,
	className
}) => {
	return (
		<Wrapper style={style} className={className}>
			<StyledDatePicker
				variant="inline"
				size='small'
				inputFormat="MM/DD/YYYY"
				label={'Start'}
				style={{
					width: '150px',
					highlight: { color: '#FFFFFF' }
				}}
				renderInput={(params) => <TextField {...params} />}
				value={Boolean(startDate) ? moment(startDate) : null}
				onChange={value => onChangeStartDate(value.toDate())}
			/>
			<Typography style={{ marginBottom: '-12px'}}>—</Typography>
			<StyledDatePicker
				variant="inline"
				size='small'
				inputFormat="MM/DD/YYYY"
				label={'End'}
				style={{
					width: '150px',
					highlight: { color: '#FFFFFF' }
				}}
				renderInput={(params) => <TextField {...params} />}
				value={Boolean(endDate) ? moment(endDate) : null}
				onChange={(value) => onChangeEndDate(value.toDate())}
			/>
		</Wrapper>
	)
}


const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-input {
    padding: 2px 8px;
  }

	.MuiInputLabel-outlined {
		margin-top: -10px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;
`

export { DateRangePicker };