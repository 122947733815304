
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { useFeathers } from '../../app/util';
import { ArrowRightAlt } from '@mui/icons-material';
import { TileSelectOption } from '../lower-order'
import { ShapefileUpload } from '../ShapefileUpload';
import { ArrowLeft } from '@mui/icons-material';
import { Shapefile } from '../../app/feathers/districts/Shapefile';
import { District } from '../../app/feathers/districts/District';
import { ShapefileDataMatcher } from '../ShapefileDataMatcher';
import { InteractiveMap } from '../InteractiveMap';
import { formatGeoJSON } from '../../app/util/geo-projection-helpers';
import { ServiceTypes } from '../../app/feathers/ServiceTypes';
import { Application } from '@feathersjs/feathers';
import { PopulationType } from '../../app/feathers/districts/PopulationType';
import CompositeIcon from './combine.png'
import UploadShpIcon from './upload-shp.png'
import PdfConversionIcon from './convert-pdf.png'

interface GeodistrictCreationModalProps {
  districts: District[];
  onSuccess: () => void;
}

export const GeodistrictCreationModal = ({
  districts,
  onSuccess
}: GeodistrictCreationModalProps) => {
  const feathers = useFeathers<Application<ServiceTypes>>();
  const [creationMode, setCreationMode] = useState<null | 'upload-shp' | 'composite' | 'pdf-conversion'>(null);
  /*
    Step 0: Select creation mode

    For 'upload-shp'
    step 1: upload shapefile and match columns
    step 2: visualize and save
  */
  const [ currentStep, setCurrentStep ] = useState<0 | 1 | 2>(0);
  const [ error, setError ] = useState<string | null>(null);

  // 'upload-shp'
  // for uploading shapefile...
  const [shapefiles, setShapefiles] = useState<Shapefile[]>();
  const [districtToShapefileIndex, setDistrictToShapefileIndex] = useState<Record<string, number>>({});
  const [districtToPopulation, setDistrictToPopulation] = useState<Record<string, number>>({});
  const [savingShapefiles, setSavingShapefiles] = useState(false);

  useEffect(() => {
    // every time current step resets to 0, reset the data
    // reset data
    if(currentStep === 0) {
      setCreationMode(null);
      setShapefiles(undefined)
      setDistrictToShapefileIndex({});
      setDistrictToPopulation({});
      setSavingShapefiles(false);
    }
  }, [ currentStep ]);

  const saveShapefiles = async () => {
    setSavingShapefiles(true);
    setError(null)
    // save shapefiles
    try {
      // do one at a time
      for(const [districtId, dataIndex] of Object.entries(districtToShapefileIndex)) {
        const district = districts.find(d => d._id === districtId);
        const shapefileRow = shapefiles?.find(d => d.dataIndex === dataIndex);
        if(!shapefileRow) throw Error(`Shapefile row not found ${dataIndex}`);
        const boundary = shapefileRow.geometry;
        const res = await feathers.getService('geo-districts').create({
          district: districtId,
          boundary
        });
      }

      if(Object.entries(districtToPopulation).length > 0) {
        const populationUpdates = Object.entries(districtToPopulation).map(([districtId, population]) => {
          return feathers.getService('districts').patch(districtId, { population, populationType: 'definitive' as PopulationType });
        })
        await Promise.all(populationUpdates);
      }

      console.log('Successfully saved shapefiles');

      onSuccess();
    } catch (e) {
      console.error(e);
      if(e instanceof Error) {
        setError(e.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setSavingShapefiles(false);
    }
  }

  return (
    <div>
      {
        error && <Typography variant='body1' color='error'>{error}</Typography>
      }
      {
        currentStep === 0 && <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          <Typography variant='body1'>
            How do you want to create the boundaries?
          </Typography>
          <div style={{
            display: 'grid',
            gridGap: 16,
            alignSelf: 'center',
            gridTemplateColumns: 'repeat(3, 220px)'
          }}>
            <TileSelectOption
              title="Upload shapefile"
              subText="Upload a .shp file with the information"
              onChange={() => setCreationMode(cm => cm === 'upload-shp' ? null : 'upload-shp')}
              selected={creationMode === 'upload-shp'}
              value='upload-shp'
              style={{ maxWidth: 200 }}
            >
              <TileSelectInner>
                <img src={UploadShpIcon}/>
              </TileSelectInner>
            </TileSelectOption>
            <TileSelectOption
              title="Build from other districts"
              subText="Set up these district boundaries, using other district boundaries as building blocks"
              onChange={() => setCreationMode(cm => cm === 'composite' ? null : 'composite')}
              selected={creationMode === 'composite'}
              value='composite'
              style={{ maxWidth: 200 }}
              disabled={true}
            >
              <TileSelectInner>
                <img src={CompositeIcon}/>
              </TileSelectInner>
            </TileSelectOption>
            <TileSelectOption
              title="Convert a PDF"
              subText="Convert a PDF or image file with a static map"
              onChange={() => setCreationMode(cm => cm === 'pdf-conversion' ? null : 'pdf-conversion')}
              selected={creationMode === 'pdf-conversion'}
              value='pdf-conversion'
              style={{ maxWidth: 200 }}
              disabled={true}
            >
              <TileSelectInner>
                <img src={PdfConversionIcon} style={{ width: '150px', minWidth: '120px' }} />
              </TileSelectInner>
            </TileSelectOption>
          </div>
          <Button
            variant="contained"
            color="primary"
            disabled={!creationMode}
            onClick={() => setCurrentStep(1)}
            endIcon={<ArrowRightAlt/>}
            style={{ alignSelf: 'end' }}
          >
            Next
          </Button>
        </div>
      }
      {
        creationMode === 'upload-shp' && currentStep === 1 && 
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%' }}>
          <ShapefileUpload
            style={{ width: 'auto', alignSelf: shapefiles ? 'flex-start' : 'center', margin: shapefiles ? '-12px 0 32px' : '32px 0' }}
            onChangeShapefiles={(data: Shapefile[]) => setShapefiles(data)}
          />
          {
            shapefiles && shapefiles?.length > 0 &&
            <ShapefileDataMatcher
              districts={districts}
              shapefiles={shapefiles}
              pairs={districtToShapefileIndex}
              onChangePairs={(newPairs) => setDistrictToShapefileIndex(newPairs)}
              onChangePopulation={(newPop) => setDistrictToPopulation(newPop)}
            />
          }
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <BackButton onClick={() => setCurrentStep(0)}/>
            <NextButton onClick={() => setCurrentStep(2)} disabled={Object.entries(districtToShapefileIndex).length === 0}/>
          </div>
        </div>
      }
      {
        creationMode === 'upload-shp' && currentStep === 2 &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%', height: '600px', }}>
          <InteractiveMap
            style={{}}
            districts={undefined}
            features={Object.entries(districtToShapefileIndex).map(([districtId, dataIdx]) => {
              const district = districts.find(d => d._id === districtId);
              const shapefileRow = shapefiles?.find(d => d.dataIndex === dataIdx);
              const populationForDistrict = districtToPopulation[districtId];
              if(!shapefileRow) return null;

              const tooltip = populationForDistrict ? `${district?.longName} (Population: ${populationForDistrict.toLocaleString()})` : district?.longName;
              return formatGeoJSON(shapefileRow.geometry, { fillColor: '#4F50CC', strokeColor: '#4F50CC', tooltip })
            }).filter(Boolean)}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <BackButton onClick={() => setCurrentStep(1)}/>
            <SaveButton onClick={() => saveShapefiles()} saving={savingShapefiles}/>
          </div>
        </div>
      }
    </div>
  );
};

const BackButton = ({
  onClick,
}: {
  onClick: () => void;
}) => (
  <Button
    onClick={onClick}
    startIcon={<ArrowLeft/>}
  >
    Back
  </Button>
)

const NextButton = ({
  onClick,
  disabled
}: {
  onClick: () => void;
  disabled?: boolean;
}) => (
  <Button
    style={{
      alignSelf: 'end',
    }}
    variant="contained"
    color="primary"
    disabled={disabled}
    onClick={onClick}
    endIcon={<ArrowRightAlt/>}
  >
    Next
  </Button>
)

const SaveButton = ({
  onClick,
  disabled,
  saving
}: {
  onClick: () => void;
  disabled?: boolean;
  saving: boolean;
}) => (
  <Button
    style={{
      alignSelf: 'end',
    }}
    variant="contained"
    color="primary"
    disabled={disabled || saving}
    onClick={onClick}
  >
    {saving ? 'Saving...' : 'Save'}
  </Button>
)

const TileSelectInner = styled.div`
  width: 100%;
  height: 60px;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    object-fit: contain;
  }
`;