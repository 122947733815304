import { Cancel, CheckCircle, HourglassBottomOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { Alert, Modal } from '@mui/material';
import { ModalInner } from '../lower-order';
import moment from 'moment';


// this component offers a quick text preview of a scraped content job status (pending, error, success)
// and when clicked, allows a researcher to pull up that content in a seperate window

const ScrapedContentPreview = ({
  // if null, will be marked as 'Not scraped'
  // if undefined, will be marked as loading
  scrapedContent
}) => {
  const [showContent, setShowContent] = React.useState(false);

  let icon = null, message, clickable;
  if(scrapedContent === null) {
    message = `Not scraped`
  } else if(scrapedContent?.content?.bodyText) {
    message = `Scraped`;
    icon = <CheckCircle color='success' fontSize={'14px'} />
    clickable = true;
  } else if(scrapedContent?.error) {
    message = `Error`
    icon = <Cancel color='error' fontSize={'14px'} />;
    clickable = true;
  } else if(scrapedContent) {
    message = `Pending`;
    icon = <HourglassBottomOutlined fontSize={'14px'} />
  } else {
    message = `--`;
  }

  const urlMain = scrapedContent?.url || scrapedContent?.content?.resolvedUrl;

  return (
    <div 
      style={{ display: 'flex', gap: '4px', alignItems: 'center', cursor: clickable ? 'pointer' : 'default'}}
      onClick={clickable && !showContent ? () => setShowContent(true) : null}
    >
      <Modal
        open={showContent}
        onClose={() => setShowContent(false)}
      >
        <ModalInner
          onClose={() => setShowContent(false)}
          style={{ width: '80vw', height: '80vh', overflow: 'auto' }}
        >
          <Typography variant={'body2'} style={{ fontWeight: 'bold', opacity: 0.6, marginBottom: '4px' }}>Last updated {moment(scrapedContent).fromNow()}</Typography>
          {
            scrapedContent?.error &&
            <Alert severity='error'>{scrapedContent?.error}{` `}<a href={urlMain} target='_blank'>{urlMain.slice(0,100)}</a></Alert>
          }
          {
            scrapedContent?.content?.bodyText &&
            scrapedContent?.content?.bodyText.map(({ sourceUrl, content }) => (
              <div style={{ borderLeft: 'solid 2px #ccc', paddingLeft: '16px', display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '36px'}}>
                <Typography variant={'body2'}><a href={sourceUrl} target='_blank'>{sourceUrl.slice(0,100)}</a></Typography>
                {
                  (!content || content === '') &&
                  <Typography variant={'body1'}>{'No content.'}</Typography>  
                }
                {
                  content && content !== '' &&
                  content.split('\n').filter(m => m.trim() !== '').map((paragraph, i) => (
                    <Typography variant={'body1'} key={i}>{paragraph}</Typography>
                  ))
                }
              </div>
            ))
          }
        </ModalInner>
      </Modal>
      {icon}
      <Typography variant={'subtitle1'} style={{ opacity: 0.6, fontSize: '14px',  }}>{message}</Typography>
    </div>
  )
}

export { ScrapedContentPreview };