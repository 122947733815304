import React, { MouseEvent, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { loadPaginatedFrontend, useFeathers } from '../../app/util';
import { DataDetailToolbar, ModalInner } from '../../components';
import { sortDistricts } from '../../app/util/sort-districts';
import { DistrictIssues } from '../../components/DistrictIssues';
import { Add, DeleteOutline, Map, PeopleAlt, DataObject as CurlyBraces, MapOutlined, ArrowUpward } from '@mui/icons-material';
import { Button, Checkbox, IconButton, Modal, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Application } from '@feathersjs/feathers';
import { ServiceTypes } from '../../app/feathers/ServiceTypes';
import { District } from '../../app/feathers/districts/District';
import { Tier2DistrictTypeAddModal } from '../Tier2DistrictTypeAddModal';
import { NamingPatternModal, GeodistrictCreationModal } from '../../components';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { humanizeDistrictType } from "../../app/util/humanize-district-type";

const tier2IssueTypes = [
  {
    Icon: Map,
    key: 'coverage-incomplete',
    tooltips: [
      'This district has a geoboundary.',
      'This district is missing a geoboundary.',
    ],
    columnTitle: 'Shapefile'
  },
  {
    Icon: PeopleAlt,
    key: 'population-incomplete',
    tooltips: [
      'This district has population data.',
      'This district is missing population data!',
    ],
    columnTitle: 'Population'
  },
];

const Tier2DistrictType = () => {
  const history = useHistory();
  const { matchName, tier2Type } = useParams<{ matchName: string, tier2Type: string }>();
  const stateCode = matchName.split('-')[0];
  const feathers = useFeathers<Application<ServiceTypes>>();
  const [districts, setDistricts] = useState<(District & { hasShapefile?: boolean })[]>([]);
  const [parentDistrict, setParentDistrict] = useState<District | null>(null);
  const [districtIssues, setDistrictIssues] = useState<Record<string, string[]>>({});
  const [isAddingDistricts, setIsAddingDistricts] = useState(false);
  const [addingGeodistricts, setAddingGeodistricts] = useState(false);
  const [editingNamingPattern, setEditingNamingPattern] = useState(false);
  const [title, setTitle] = useState('');
  const [bulkActionSelected, setBulkActionSelected] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState<string | null>(null);

  const [sortKey, setSortKey] = useState<string>('identifier:asc');

  useEffect(() => {
    // For some reason, this isn't working properly. The returned data actually works, but then the table doesn't rerender on the appropriate cycle.
    if(districts?.length > 0) {
      const [field, sortDirection] = sortKey.split(':');

      setDistricts(districts => sortDistricts(districts, sortDirection !== 'desc', field || 'identifier'));
    }
  }, [ sortKey ]);
  
  async function loadDistricts() {
    if (!feathers) {
      return;
    }
    // clear all starting data 
    setDistricts([]);
    setDistrictIssues({});
    setParentDistrict(null);
    

    const districtService = feathers.getService('districts');
    const stateConfigService = feathers.getService('state-configurations');
    const [parentDistrict, stateConfig] = await Promise.all([
      districtService.find({ query: { matchName } })
        .then(({ data }) => data?.[0]),
      stateConfigService.get(stateCode),
    ]);
    if (!parentDistrict) {
      return;
    }
    setParentDistrict(parentDistrict);
    setTitle(`${humanizeDistrictType(tier2Type, stateConfig.typeConfigurations)} Districts`);

    let [
      districts,
      notCoveredDistricts,
    ] = await Promise.all([
      loadPaginatedFrontend<District & { hasShapefile?: boolean }>(
        'districts',
        { parentId: parentDistrict._id, type: tier2Type },
        feathers,
        500,
      ),
      loadPaginatedFrontend<District>(
        'district-coverage',
        {
          matched: false,
          method: 'geo',
          parentId: parentDistrict.dmsId,
          type: tier2Type,
        },
        feathers,
        100,
      ),
    ]);
    
    setDistricts(sortDistricts(districts));
    const notCoveredDistrictIds = new Set(
      notCoveredDistricts.map(c => c._id),
    );
    const districtIssues = districts.reduce(
      (districts, next) => {
        districts[next._id] = [];
        if (!next.population) {
          districts[next._id].push('population-incomplete');
        }
        const coverageIncomplete = notCoveredDistrictIds.has(next.dmsId);
        next.hasShapefile = !coverageIncomplete;
        if (coverageIncomplete) {
          districts[next._id].push('coverage-incomplete');
        }
        return districts;
      },
      {} as Record<string, string[]>,
    );
    setDistrictIssues(districtIssues);
  }

  const bulkDeleteDistricts = async () => {
    setSaving(true);
    setSavingError(null);
    try {
      await Promise.all(
        bulkActionSelected.map(id => feathers.getService('districts').remove(id)),
      );
      setBulkActionSelected([]);
      setDistricts([])
      loadDistricts();
    } catch(err) {
      console.error(err);
      setSavingError('Failed to delete districts');
      return;
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (!feathers) {
      return;
    }
    loadDistricts();
  }, [feathers]);

  const onUpdateDistricts = async () => {
    await loadDistricts();
  };

  const iconColumnStyling = {
    width: 100,
    maxWidth: 100,
  }

  return (
    <Wrapper>
      <DataDetailToolbar
        navTree={[
          { text: 'Districts', to: '/states' },
          { text: parentDistrict?.name || '--', to: `/states/${matchName}` },
          { text: title },
        ]}
        onBack={() => history.push(`/states/${matchName}`)}
        actionButtonsComponent={
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px'
          }}>
            <Tooltip title="Edit naming patterns">
              <IconButton onClick={() => setEditingNamingPattern(true)}>
                <CurlyBraces/>
              </IconButton>
            </Tooltip>
            <Tooltip title='Add geoboundaries'>
              <IconButton onClick={() => setAddingGeodistricts(true)}>
                <MapOutlined/>
              </IconButton>
            </Tooltip>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setIsAddingDistricts(true)}
              endIcon={<Add/>}
            >
              Add
            </Button>
          </div>
        }
      />
      <div
        style={{
          margin: '28px 0',
          display: 'flex',
          gap: 8,
        }}
      >
        <Typography variant="h2">
          {parentDistrict?.name} {title}
        </Typography>
        <Typography variant="subtitle1">
          ({districts.length})
        </Typography>
      </div>
      {
        savingError && (
          <Typography variant="body1" color="error">
            {savingError}
          </Typography>
        )
      }
      <TableContainer component={Paper}>
        {
          bulkActionSelected.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px 16px', backgroundColor: '#EEE', gap: '12px', alignItems: 'center' }}>
              <Typography variant="body1">
                {bulkActionSelected.length} selected
              </Typography>
              <div>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete these districts?')) {
                      bulkDeleteDistricts();
                    }
                  }}
                  disabled={saving}
                  endIcon={<DeleteOutline color='error'/>}
                >
                  Delete
                </Button>
              </div>
            </div>
          )
        }
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={bulkActionSelected.length === districts.length}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    if (checked) {
                      setBulkActionSelected(districts.map(d => d._id));
                    } else {
                      setBulkActionSelected([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell style={{ width: '100%' }}>
                <SortedBy 
                  label='identifier'
                  value={sortKey}
                  setSortKey={setSortKey}
                >
                  <Typography variant='body1'>
                    Name
                  </Typography>
                </SortedBy>
              </TableCell>
              {
                tier2IssueTypes.map(({ key, columnTitle }) => (
                  <TableCell sx={iconColumnStyling} key={key}>
                    {
                      columnTitle === 'Population'
                      ? <SortedBy
                          label='population'
                          value={sortKey}
                          setSortKey={setSortKey}
                        >
                          <Typography variant='body1'>
                            {columnTitle}
                          </Typography>
                        </SortedBy>
                        : <Typography variant='body1'>
                            {columnTitle}
                          </Typography>
                    }
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {districts.map((district, index) => (
              <TableRow
                key={district._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                component={Link}
                to={`/states/${matchName}/${tier2Type}/${district?.matchName}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <TableCell style={{ textAlign: 'center' }}>
                  <Checkbox
                    checked={bulkActionSelected.includes(district._id)}
                    onClick={(event) => {
                      event.stopPropagation();
                      const checked = !bulkActionSelected.includes(district._id);

                      if (checked) {
                        setBulkActionSelected([...bulkActionSelected, district._id]);
                      } else {
                        setBulkActionSelected(bulkActionSelected.filter(id => id !== district._id));
                      }
                    }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant='body1'>
                    {district.longName}
                  </Typography>
                  <Typography variant='body2' style={{ opacity: 0.7 }}>
                    {district.population && `Population: ${district.population.toLocaleString()}`}
                  </Typography>
                </TableCell>
                <DistrictIssues
                  as='td'
                  style={iconColumnStyling}
                  issues={districtIssues[district._id]}
                  issueTypes={tier2IssueTypes}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isAddingDistricts && parentDistrict && <Tier2DistrictTypeAddModal
        existingDistricts={districts}
        parent={parentDistrict}
        type={tier2Type}
        onClose={() => setIsAddingDistricts(false)}
        onUpdateDistricts={() => onUpdateDistricts()}
      />}
      <NamingPatternModal
        open={editingNamingPattern && parentDistrict !== null}
        onClose={() => setEditingNamingPattern(false)}
        restrictToTier2Types={[tier2Type]}
        onReloadData={() => loadDistricts()}
        stateId={parentDistrict?._id || ''}
        mode='tier-2-type'
      />
      <Modal
        open={addingGeodistricts}
        onClose={() => setAddingGeodistricts(false)}
      >
        <ModalInner
          style={{ maxWidth: '800px'}}
          title="Add Geoboundaries"
          onClose={() => setAddingGeodistricts(false)}
        >
          <GeodistrictCreationModal
            districts={districts || []}
            onSuccess={() => {
              loadDistricts();
              setAddingGeodistricts(false);
            }}
          />
        </ModalInner>
      </Modal>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const SortedBy = ({ 
  label, 
  value, 
  setSortKey,
  children,
} : { 
  label: string; 
  value: string; 
  setSortKey: (value: string) => void;
  children: React.ReactNode;
}) => {
  const sortedBy = value && value.startsWith(label);
  const ascending = value && value.endsWith('asc');
  const Icon = ascending ? <ArrowUpward style={{ fontSize: '16px' }} /> : <ArrowUpward style={{ transform: 'rotate(180deg)', fontSize: '16px' }}/>;

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer' }} onClick={() => {
      if(sortedBy && ascending) {
        setSortKey(`${label}:desc`);
      } else if(sortedBy && !ascending) {
        setSortKey('');
      } else {
        setSortKey(`${label}:asc`);
      }
    }}>
      {children}
      {
        sortedBy && Icon
      }
    </div>
  );
}


export default Tier2DistrictType;
