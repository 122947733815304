import React from 'react';
import { ColorTag } from '../ColorTag';
import PropTypes from 'prop-types';

const PartyTag = ({ party, style = {}, className = '', onClick, short }) => {

  const partyDict = {
    'R': {
      text: 'republican',
      textShort: 'rep',
      color: 'red'
    },
    'D': {
      text: 'democrat',
      textShort: 'dem',
      color: 'blue'
    },
    'I': {
      text: 'indepdendent',
      textShort: 'ind',
      color: 'black'
    },
    'N': {
      text: 'nonpartisan',
      textShort: 'np',
      color: 'black'
    },
    'G': {
      text: 'green party',
      textShort: 'green',
      color: 'green'
    },
    'L': {
      text: 'libertarian',
      textShort: 'lib',
      color: 'yellow'
    }
  }
  const dataForParty = partyDict[party];
  if(!dataForParty) return null;

  return (
    <ColorTag style={style} className={className} themeColor={dataForParty.color} onClick={onClick}>
      {short ? dataForParty.textShort.toUpperCase() : dataForParty.text.toUpperCase()}
    </ColorTag>
  );
}

PartyTag.propTypes = {
  party: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  short: PropTypes.bool
};

export {PartyTag};
