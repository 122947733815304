import React from 'react';
import LinkList from '../LinkList/LinkList';
import {
  CandidateIssue,
  BioTile
} from '../lower-order';
import { FullIssuePrimer } from '../FullIssuePrimer';

import styled from 'styled-components';
import Text from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

const CandidateSummary = ({
  candidate,
  office,
  style,
  className,
  showIssueLikes,
  showArchivedIssues,
  detailMode = false
}) => {
  const {
    name,
    issues,
    archivedIssues,
    finance,
    links,
    id,
    bioPersonal,
    bioProfessional,
    bioPolitical,
    bioPoliticalSources,
    bioProfessionalSources,
    bioPersonalSources
  } = candidate;
  const issuesProvided = issues && issues.length > 0;
  const linksProvided = (links && links.length > 0);
  const bioMod = bio => bio?.length > 0 ? bio : 'No information for this section yet.';

  const biosCombined = [
    { bio: bioMod(bioPolitical), sources: bioPoliticalSources, title: 'Political background' },
    { bio: bioMod(bioProfessional), sources: bioProfessionalSources, title: 'Professional background' },
    { bio: bioMod(bioPersonal), sources: bioPersonalSources, title: 'Personal background' },
  ]

  return (
    <Wrapper style={style} className={className}>
      {
        !detailMode &&
        <Text variant='h3' color='textPrimary'>{name}</Text>
      }
      {
        biosCombined?.length > 0
        ?
          <div style={{ marginTop: '16px' }}>
            {biosCombined.map((b,i) => (
              <React.Fragment
                key={i}
              >
                {i !== 0 && <Divider/>}
                <BioTile {...b} />
              </React.Fragment>
            ))}
          </div>
        :
          <div></div>
      }
      {
        linksProvided && !detailMode &&
        <div>
          <Text
            vairant='body1'
            color='textPrimary'
            style={{ fontWeight: 'bold', marginTop: '24px', marginBottom: '-6px' }}
          >
            Links
          </Text>
          <LinkList links={links} />
          </div>
      }
      {
        !detailMode &&
        <Divider style={{ marginTop: '16px', marginBottom: '8px' }}/>
      }
      {
        issuesProvided &&
        <Section>
          <Text variant='overline' style={{ color: '#000000', opacity: 0.5 }}>ON THE ISSUES</Text>
          {issues.map((issue, index) => (
            <CandidateIssueWrap key={`cd-${candidate._id}-issue-${index}`}>
              <CandidateIssue
                issue={issue}
                infoButton={
                  <FullIssuePrimer
                    issueName={issue.title}
                    candidateId={id}
                    school={(office?.title || '').toLowerCase().includes('education')}
                    style={{ marginLeft: '8px' }}
                    triggerComponent={
                      <IconButton size='small'>
                        <InfoIcon style={{ width: '20px', height: '20px' }}/>
                      </IconButton>
                    }
                  />
                }
                showIssueLikes={false}
                candidateId={id}
                soundbiteDescriptor={`${name}, ${issue.title}`}
              />
            </CandidateIssueWrap>
          ))}
        </Section>
      }
      {
        showArchivedIssues && archivedIssues && archivedIssues?.length > 0 &&
        <Section>
          <Text variant='overline' style={{ color: '#000000', opacity: 0.5 }}>ARCHIVED ISSUES (NOT PUBLIC)</Text>
          {archivedIssues.map((issue, index) => (
            <CandidateIssueWrap key={`cd-${candidate._id}-issue-${index}`}>
              <CandidateIssue
                issue={issue}
                infoButton={
                  <FullIssuePrimer
                    issueName={issue.title}
                    candidateId={id}
                    school={(office?.title || '').toLowerCase().includes('education')}
                    style={{ marginLeft: '8px' }}
                    triggerComponent={
                      <IconButton size='small'>
                        <InfoIcon style={{ width: '20px', height: '20px' }}/>
                      </IconButton>
                    }
                  />
                }
                showIssueLikes={false}
                candidateId={id}
                soundbiteDescriptor={`${name}, ${issue.title}`}
              />
            </CandidateIssueWrap>
          ))}
        </Section>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 24px 0 0;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #EEEEEE;
`

const Section = styled.div`
  margin: 16px 0 16px;
`

const CandidateIssueWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`
CandidateSummary.propTypes = {
  candidate: PropTypes.object.isRequired,
  office: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  showIssueLikes: PropTypes.bool,
  showArchivedIssues: PropTypes.bool,
  detailMode: PropTypes.bool
};

export {
  CandidateSummary
};
