import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Skeleton,
	Tooltip,
	Typography,
} from '@mui/material';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFeathers } from '../../app/util';
import { useEffect, useState } from 'react';
import { Mail } from '@mui/icons-material';

const ElectionReminderSchedule = ({
	election,
	reminders
}) => {
	const feathers = useFeathers();
	const [ numTargetedUsers, setNumTargetedUsers ] = useState();

	const loadNumTargetedUsers = async () => {
		try {
			const query = {
				reminderEmails: true,
				...election.reminderTargetingParams,
				'organizations.0': { $exists: false }
			}
			const numTargetUsers = await feathers.getService('users').find({
				query: {
					...query,
					$limit: 0
				},
			});
			setNumTargetedUsers(numTargetUsers.total);
		} catch(err) {
			setNumTargetedUsers(null)
			console.log('Error loading numTargetedUsers:', err);
		}
	}

	useEffect(() => {
		if(feathers && election?.reminderTargetingParams) loadNumTargetedUsers();
	}, [ feathers, election ]);


	return (
		<div style={{}}>
			<h3>{election.name}</h3>
			{reminders.map((reminder, index) => {
				let dateColor;
				const daysUntilDate = moment(reminder.date).diff(moment(), 'days');
				if (daysUntilDate < 0) {
					dateColor = '#dddddd';
				} else if (daysUntilDate < 7) {
					// a nice pale yellow
					dateColor = '#ffffcc';
				} else {
					dateColor = '#eeeeee';
				}
				return (
					<div key={index}>
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
							>
								<div style={{ display: 'flex', alignItems: 'center', gap: '12px', width: '100%' }}>
									<div style={{ padding: '4px 8px', borderRadius: '4px', backgroundColor: dateColor, width: '130px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<Typography>{moment(reminder.date).format('MMM Do, YYYY')}</Typography>
									</div>
									<Typography style={{ flex: '1'}}>{reminder.subject}</Typography>
									{
										numTargetedUsers === undefined &&
										<Skeleton variant="text" width={100} />
									}
									{
										typeof(numTargetedUsers) === 'number' &&
										<Tooltip	title="Number of users targeted by this reminder">
											<div style={{ padding: '4px 8px', borderRadius: '4px', display: 'flex', gap: '4px', alignItems: 'center', backgroundColor: '#f8f8f8' }}>
												<Mail fontSize='small' />
												<Typography>{numTargetedUsers} users</Typography>
											</div>
										</Tooltip>
									}
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<div style={{ border: 'solid 1px #dddddd', borderRadius: '4px', padding: '24px' }}>
									<div dangerouslySetInnerHTML={{ __html: reminder.body }} />
								</div>
							</AccordionDetails>
						</Accordion>
					</div>
				);
			})}
		</div>
	)
}

export { ElectionReminderSchedule };