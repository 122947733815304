import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Redirect, Link, useParams } from 'react-router-dom';
import { useFormInputs, useFeathers } from '../../app/util';
import {
  CTAButton,
  SearchableSelectInput,
  CloseButton,
  HintTile,
  TextInput,
  Checkbox
} from '../../components';
import moment from 'moment';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

const CreateElection = ({
  onClose,
  onSuccess
}) => {
  const feathers = useFeathers();
  const [ formInputs, setFormInputs ] = useState({
    name: '',
    type: null,
    district: null,
    date: null,
    qualifyingDate: null,
    voterRegistrationEnd: null,
    absenteeEnd: null,
    earlyVotingStart: null,
    earlyVotingEnd: null,
    runoffPrecedent: null
  })
  const [ numRunoffRaces, setNumRunoffRaces ] = useState(undefined);
  const [ runoffConfirmed, setRunoffConfirmed ] = useState(false);
  const submittable = formInputs.name && formInputs.type && formInputs.district && formInputs.date;
  const onChange = e => {
    const { name, value } = e.target;
    const update = {};
    update[name] = value;
    let newInputs = {
      ...formInputs,
      ...update
    }
    setFormInputs(newInputs);
  }

  useEffect(() => {
    if(['primary-runoff', 'general-runoff'].includes(formInputs.type)) {
      searchForField('runoffPrecedent', null)
    }
  }, [ formInputs ])

  /* manage searching options for selection, based on search text */
  const [ optionsForField, setOptionsForField ] = useState({});
  const [ loadingForField, setLoadingForField ] = useState({});
  const searchForField = async (field, searchText = '') => {
    if(!feathers) return;

    const loadingUpdate = {};
    loadingUpdate[field] = true;
    setLoadingForField({ ...loadingForField, ...loadingUpdate });

    let options = [];
    if(field === 'district') {
      const districts = await feathers.getService('districts').find({ query: {
        searchTerm: { $search: searchText }
      }})
      options = districts.data.map(dist => ({
        id: dist._id,
        text: dist.longName,
        subText: dist.parent || 'Statewide'
      }));
    } else if(field === 'runoffPrecedent') {
      const elections = await feathers.getService('elections').find({ query: {
        ...(searchText ? { name: { $search: searchText } } : {}),
        status: ['results-certified', 'results-tentative'],
        $sort: { date: -1 }
      }})
      options = elections.data.map(({ name, key, date }) => ({
        text: name,
        subText: moment.utc(date).format('LL'),
        key
      }));
    }
    const updateForOptions = {};
    updateForOptions[field] = options;
    setOptionsForField({ ...optionsForField, ...updateForOptions })

    const loadingUpdate2 = {};
    loadingUpdate2[field] = false;
    setLoadingForField({ ...loadingForField, ...loadingUpdate2 });
  }

  const [ submitting, setSubmitting ] = useState(false);
  const [ successfulCreation, setSuccessfulCreation ] = useState(false);
  const [ error, setError ] = useState(null);
  const submit = async () => {
    if(submitting || !submittable) return;
    // Create the candidate
    setSubmitting(true);

    const districtId = formInputs.district?.id;
    const fullDistrict = await feathers.getService('districts').get(districtId, { query: { parentDepth: 5 } });
    let stateDistrict = fullDistrict.type === 'state' ? fullDistrict : fullDistrict.parentDistrict;
    if(stateDistrict.type !== 'state') stateDistrict = fullDistrict.parentDistrict;
    if(stateDistrict.type !== 'state') stateDistrict = fullDistrict.parentDistrict;
    if(stateDistrict.type !== 'state') stateDistrict = fullDistrict.parentDistrict;
  

    // Create a the new election
    try {
      const creation = await feathers.getService('elections').create({
        status: 'prep',
        date: formInputs.date,
        ...(formInputs?.qualifyingDate ? { qualifyingDate: formInputs.qualifyingDate } : {}),
        ...(formInputs?.absenteeEnd ? { absenteeEnd: formInputs.absenteeEnd } : {}),
        ...(formInputs?.voterRegistrationEnd ? { voterRegistrationEnd: formInputs.voterRegistrationEnd } : {}),
        ...(formInputs?.earlyVotingEnd ? { earlyVotingEnd: formInputs.earlyVotingEnd } : {}),
        ...(formInputs?.earlyVotingStart ? { earlyVotingStart: formInputs.earlyVotingStart } : {}),
        name: formInputs.name,
        districts: [ formInputs.district?.id ],
        electionType: formInputs.type,
        state: stateDistrict._id,
        ...(formInputs?.runoffPrecedent ?  { runoffPrecedent: formInputs.runoffPrecedent.key } : {}),
        ...(runoffConfirmed ? { confirmRaces: numRunoffRaces } : {})
      })
      console.log(creation)
      if(onSuccess) {
        onSuccess()
      } else {
        onClose()
      }
    } catch (error) {
      const errorAsJson = error.toJSON()
      if(errorAsJson.code === 400 && typeof(errorAsJson?.data?.runoffRaces) !== 'undefined') {
        setNumRunoffRaces(errorAsJson?.data?.runoffRaces)
      } else {
        console.log(error)
        setError(errorAsJson)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const dateRow = ({ label, formInputName }) => {
    return (
      <Row key={formInputName}>
        <div className='label'>{label}</div>
        <StyledDatePicker
          size='small'
          inputFormat="MM/DD/YYYY"
          label={label}
          style={{
            width: '300px',
            highlight: { color: '#FFFFFF' }
          }}
          renderInput={(params) => <TextField {...params} />}
          value={formInputs[formInputName] ? moment(formInputs[formInputName]) : null}
          onChange={(value) => onChange({ target: { name: formInputName, value: value.toDate() } })}
        />
      </Row>
    )
  }

  return (
    <Wrapper>
      <CloseButton
        onClick={onClose}
        style={{
          position: 'absolute',
          height: '36px',
          right: '-20px',
          top: '-20px'
        }}
      />
      <Top>
        <h1>New Election</h1>
        <Row>
          <div className='label'>Name</div>
          <TextInput
            name='name'
            value={formInputs.name}
            onChange={onChange}
            mode='normal'
            style={{ flexGrow: 1 }}
          />
        </Row>
        <Row>
          <div className='label'>District</div>
          <SearchableSelectInput
            name='district'
            onChangeSearchText={(searchText) => searchForField('district', searchText)}
            value={formInputs.district}
            onChange={onChange}
            optionsLoading={loadingForField.district}
            options={optionsForField?.district || []}
            style={{ flexGrow: 1 }}
          />
        </Row>
        {
          [
            { label: 'Election date', formInputName: 'date' },
            { label: 'Qualifying deadline', formInputName: 'qualifyingDate' },
            { label: 'Voter registration deadline', formInputName: 'voterRegistrationEnd' },
            { label: 'Absentee request deadline', formInputName: 'absenteeEnd' },
            { label: 'Early voting starts', formInputName: 'earlyVotingStart' },
            { label: 'Early voting ends', formInputName: 'earlyVotingEnd' },
          ].map(dateRow)
        }
        <Row>
          <div className='label'>Election Type</div>
          <select
            name='type'
            value={formInputs.type}
            onChange={onChange}
          >
            <option value='primary'>Primary Election</option>
            <option value='primary-runoff'>Primary Election Runoff</option>
            <option value='general'>General Election</option>
            <option value='general-runoff'>General Election Runoff</option>
            <option value='special'>Special Election</option>
          </select>
        </Row>
        {
          ['primary-runoff','general-runoff'].includes(formInputs.type) &&
          <Row>
            <div className='label'>Original election</div>
            <SearchableSelectInput
              name='runoffPrecedent'
              onChangeSearchText={(searchText) => searchForField('runoffPrecedent', searchText)}
              value={formInputs.runoffPrecedent}
              onChange={onChange}
              optionsLoading={loadingForField.runoffPrecedent}
              options={optionsForField?.runoffPrecedent || []}
              style={{ flexGrow: 1 }}
            />
          </Row>
        }
        {
          error &&
          <HintTile style={{ marginBottom: '20px' }}>
            <ErrorText><b>Uh oh! </b>Error creating this election: {error.message}</ErrorText>
          </HintTile>
        }
        {
          typeof(numRunoffRaces) !== 'undefined' &&
          <HintTile style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>
                <b>Heads up! </b>{`If you do this, ${numRunoffRaces} races will be automatically created.`}
              </span>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '12px' }}>
                <Checkbox
                  checked={runoffConfirmed}
                  onClick={() => setRunoffConfirmed(!runoffConfirmed)}
                />
                <span style={{ marginLeft: '8px' }}><b>Yes, proceed anyways</b></span>
              </div>
            </div>
          </HintTile>
        }
        <CTAButton
          value='Create'
          onClick={submit}
          disabled={!submittable || submitting}
        />
      </Top>
    </Wrapper>
  );

};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  min-height: 200px;
`
const Top = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 28px;
    margin: 0 0 26px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 28px;

  div.label {
    font-size: 18px;
    margin: 0 14px 0 0;
    opacity: 0.7;
    width: 100px;
    ${props => props.theme.font.normal}
    color: ${props => props.theme.colors.darkPurple};
  }
`;

const ErrorText = styled.div`
  ${props => props.theme.font.normal}
  color: ${props => props.theme.colors.darkPurple};
  b {
    ${props => props.theme.font.bold}
  }
`;

const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-input {
    padding: 2px 8px;
  }

	.MuiInputLabel-outlined {
		margin-top: -10px;
	}
`;

export default CreateElection;
