// This modal is for adding district 3 districts for a specific type of district, and specific tier 2 parent.
// It is used in the Tier2DistrictDetails screen.

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { District, DistrictSource } from '../../app/feathers/districts/District';
import { StateConfiguration } from '../../app/feathers/state-configurations/StateConfiguration';
import {
  Modal,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Button,
  TextField,
  Select,
  IconButton,
  Chip,
  Skeleton
} from '@mui/material';
import { ModalInner } from '../lower-order';
import { humanizeDistrictType } from '../../app/util/humanize-district-type';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Cancel, KeyboardReturn } from '@mui/icons-material';
import { useFeathers } from '../../app/util';
import { Application } from '@feathersjs/feathers';
import { ServiceTypes } from '../../app/feathers/ServiceTypes';
import { DistrictVotingType } from '../../app/feathers/districts/DistrictVotingType';
import { NamingPattern } from '../../app/feathers/naming-patterns/NamingPattern';
import { prettyDistrictNameFromPattern } from '../../app/util/pretty-district-name-from-pattern';

const identifierTypeForDistrict = (district: District) => {
  if (district.name) {
    return 'name';
  } else if (district.number) {
    return 'number';
  } else if (district.letter) {
    return 'letter';
  } else {
    return 'name';
  }
};

const inferredIdentifierTypeFromInput = (input: string | number) => {
  // suss out if the identifier is a number, name, or letter
  const inputString = `${input}`;
  const isNumber = inputString.match(/^\d+$/);
  if (isNumber) return 'number';
  const isLetter = inputString.length === 1 && inputString.match(/[a-z]/i);
  if (isLetter) return 'letter';
  return 'name';
};

interface CreateOperation {
  operationType: 'create';
  id?: never;
  value: Partial<District>;
}

interface PatchOperation {
  operationType: 'patch';
  id: string;
  value: Partial<District>;
}

interface DeleteOperation {
  operationType: 'delete';
  id: string;
  value?: never;
}

interface Child {
  identifier: District['name' | 'number' | 'letter'];
  votingType: DistrictVotingType;
}

interface Tier3AddDistrictModalProps {
  parentDistrict: District | null;
  tier3Type: string;
  open: boolean;
  existingSubdistricts: District[];
  stateConfiguration: StateConfiguration | undefined;
  onClose: () => void;
  onFinish: (parentDistrict: District, subdistricts: District[]) => void;
  note?: string;
  sources?: DistrictSource[];
}

const Tier3AddDistrictModal = (
  {
    parentDistrict,
    existingSubdistricts,
    stateConfiguration,
    tier3Type,
    onClose,
    onFinish,
    open,
  }: Tier3AddDistrictModalProps,
) => {
  const feathers = useFeathers<Application<ServiceTypes>>();

  const [districtGroupOption, setDistrictGroupOption] = useState<'has-districts' | 'no-districts' | null>(null);
  const [children, setChildren] = useState<Child[]>([]);
  const [sources, setSources] = useState<string[]>([]);
  const [note, setNote] = useState<string>('');
  const [sourcesGiven, setSourcesGiven] = useState<string[]>([]);
  const [noteGiven, setNoteGiven] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [orderedNamingPatterns, setOrderedNamingPatterns] = useState<(NamingPattern | null)[]>([]);

  const loadNamingPatterns = async () => {
    if(!parentDistrict) return;
    const stateDistrict = await feathers.getService('districts').get(parentDistrict._id);
    const namingPatternService = feathers.getService('naming-patterns');

    const stateNamingPatternId = stateDistrict?.namingPatternId;

    const namingPatterns = (await Promise.all([
      namingPatternService.find({ query: { key: 'default-national'} }),
      namingPatternService.find({ query: { _id: stateNamingPatternId }}),
      namingPatternService.find({ query: { _id: parentDistrict.namingPatternId }}),
    ])).map((result) => result.data?.[0]);
    
    // filter out duplicates, keep empty values instead of filtering out
    const patternsDeduped = namingPatterns.map((pattern, index, arr) => {
      const arrBefore = arr.slice(0, index);
      return arrBefore.find(p => p?.key === pattern?.key) ? null : pattern;
    })

    setOrderedNamingPatterns(patternsDeduped.reverse());
  }

  const humanizedTier3Type = useMemo(() => humanizeDistrictType(tier3Type), [tier3Type]);

  const identifierType = useMemo(() => {
    if (children.length === 0) {
      return null;
    }
    const firstIdentifier = children[0].identifier;
    if (!firstIdentifier) {
      return null;
    }
    return inferredIdentifierTypeFromInput(firstIdentifier);
  }, [children]);

  const operationsToPerform = useMemo(() => {
    // this will return an object with two keys: childrenStructureValue and districts
    // childrenStructureValue: the value to patch the parent district with (the final count of districts)
    // districts an array of operations that will be performed
    // each with the format of :
    // { operationType ('create', 'delete', 'patch'), id (only for delete and patch), value (the body )}

    if (districtGroupOption === 'has-districts') {
      // if we have districts, we need to compare the existing districts to the new districts
      if (existingSubdistricts.length === 0) {
        // this is our simplest case, just creating new districts
        const districts = children
          .filter(({ identifier }) => identifier)
          .map<CreateOperation>(d => {
            const value: Partial<District> = {
              type: tier3Type,
              parentId: parentDistrict?._id,
              votingType: d.votingType,
            };
            if (identifierType === 'number') {
              value.number = +d.identifier;
            } else if (identifierType === 'letter') {
              value[identifierType] = d.identifier as District['letter'];
            } else if (identifierType === 'name') {
              value[identifierType] = `${d.identifier}`;
            }
            return { operationType: 'create', value };
          });
        return {
          childrenStructureValue: districts.length,
          districts,
        };
      } else {
        // this means that there are already districts, and we are performing some sort of edit on those districts
        // we need to compare the existing districts to the new districts
        const newDistricts = children.filter(({ identifier }) => identifier);
        const districtsBeingCreated = newDistricts.filter(d => !existingSubdistricts.find(ed => `${ed[identifierTypeForDistrict(ed)]}` === `${d.identifier}`));
        const districtsBeingDeleted = existingSubdistricts.filter(ed => !newDistricts.find(d => `${d.identifier}` === `${ed[identifierTypeForDistrict(ed)]}`));
        const districtsBeingEdited = newDistricts.filter(d => existingSubdistricts.find(ed => `${ed[identifierTypeForDistrict(ed)]}` === `${d.identifier}` && ed.votingType !== d.votingType));

        let districts = [
          ...districtsBeingCreated.map<CreateOperation>(d => {
            const value: Partial<District> = {
              type: tier3Type,
              parentId: parentDistrict?._id,
              votingType: d.votingType,
            };
            if (identifierType === 'number') {
              value[identifierType] = +d.identifier;
            } else if (identifierType === 'letter') {
              value[identifierType] = d.identifier as District['letter'];
            } else if (identifierType === 'name') {
              value[identifierType] = `${d.identifier}`;
            }
            return { operationType: 'create', value };
          }),
          ...districtsBeingDeleted.map<DeleteOperation>(d => ({ operationType: 'delete', id: d._id })),
          ...districtsBeingEdited.map(d => {
            const value: Partial<District> = {
              votingType: d.votingType,
            };
            if (identifierType === 'number') {
              value[identifierType] = +d.identifier;
            } else if (identifierType === 'letter') {
              value[identifierType] = d.identifier as District['letter'];
            } else if (identifierType === 'name') {
              value[identifierType] = `${d.identifier}`;
            }
            const id = existingSubdistricts.find(ed => `${ed[identifierTypeForDistrict(ed)]}` === `${d.identifier}`)?._id;
            return { operationType: 'patch', id, value };
          }).filter((o): o is PatchOperation => !!o.id),
        ];

        return {
          childrenStructureValue: newDistricts.length,
          districts,
        };
      }
    } else if (districtGroupOption === 'no-districts') {
      // if we are marking this district as having no districts
      const districts = existingSubdistricts.map<DeleteOperation>(d => ({ operationType: 'delete', id: d._id }));
      return {
        childrenStructureValue: 0,
        districts,
      };
    } else {
      return {
        childrenStructureValue: -1,
        districts: [],
      };
    }
  }, [districtGroupOption, children, existingSubdistricts, parentDistrict, tier3Type]);


  const [operationReady, operationSummary] = useMemo(() => {
    const sourcesFiltered = sources.filter(Boolean);
    if(!sources || sourcesFiltered.length === 0) {
      return [false, ''];
    }

    // This will summarize the operation that takes place, if someone clicks save
    if (districtGroupOption === 'has-districts') {
      if (operationsToPerform.districts.length > 0) {
        const districtsBeingCreated = operationsToPerform.districts.filter(d => d.operationType === 'create').length;
        const districtsBeingDeleted = operationsToPerform.districts.filter(d => d.operationType === 'delete').length;
        const districtsBeingEdited = operationsToPerform.districts.filter(d => d.operationType === 'patch').length;

        const summary = [
          districtsBeingCreated > 0 ? `Creating ${districtsBeingCreated} districts (with identifier ${identifierType || '--'}).` : false,
          districtsBeingDeleted > 0 ? `Deleting ${districtsBeingDeleted} districts.` : false,
          districtsBeingEdited > 0 ? `Updating ${districtsBeingEdited} districts.` : false,
        ].filter(Boolean).join(' ');

        return [
          true,
          summary,
        ];
      }
    } else if (districtGroupOption === 'no-districts') {
      if (operationsToPerform.districts.length > 0) {
        const districtsBeingDeleted = operationsToPerform.districts.filter(d => d.operationType === 'delete').length;
        return [
          true,
          `This will delete ${districtsBeingDeleted} subdistricts and mark the parent district as having no ${humanizedTier3Type} subdistricts.`,
        ];
      } else if (parentDistrict?.childrenStructure && parentDistrict.childrenStructure[tier3Type] !== operationsToPerform.childrenStructureValue) {
        return [true, `This will set this parent district as having no ${humanizedTier3Type} subdistricts.`];
      }
    } 

    const sourcesGivenMapped = sourcesGiven.filter(Boolean).map(source => source.trim()).join(', ');
    const sourcesFilteredMapped = sourcesFiltered.join(', ');
    
    const noteUpdated = note !== noteGiven;
    const sourcesUpdated = sourcesFilteredMapped !== sourcesGivenMapped;
    if (noteUpdated || sourcesUpdated) {
      return [true, `${[noteUpdated ? 'Notes' : undefined, sourcesUpdated ? 'Sources' : undefined].filter(Boolean).join(' and ')} will be updated. This will not change the districts.`];
    }

    return [false, ''];
  }, [operationsToPerform, identifierType, districtGroupOption, sources, note]);

  const textFieldRefs = useRef<HTMLInputElement[]>([]);
  const [focusRowIndex, setFocusRowIndex] = useState<number | null>(null);

  useEffect(() => {
    if (open && feathers) {
      loadNamingPatterns();
    }
  }, [open, feathers]);

  useEffect(() => {
    if (!open) {
      setDistrictGroupOption(null);
      setChildren([]);
      setSaving(false);
      setError(null);
      setSources([]);
      setNote('');
      setSourcesGiven([]);
      setNoteGiven('');
    } else {
      // when we first open, set the state values based on if we already have some info
      const childrenStructureForParent = parentDistrict?.childrenStructure;
      if (childrenStructureForParent && typeof (childrenStructureForParent[tier3Type]) !== 'undefined') {
        const numChildren = childrenStructureForParent[tier3Type];
        if (numChildren === 0) {
          setDistrictGroupOption('no-districts');
        } else if (numChildren > 0 || existingSubdistricts.length > 0) {
          setDistrictGroupOption('has-districts');
          const newChildren = existingSubdistricts.map(d => ({
            identifier: d.name || d.number || d.letter,
            votingType: d.votingType,
          }));
          setChildren(newChildren);

          // If there is a mismatch between the children structure and actual children, display an error message
          if (numChildren !== existingSubdistricts.length) {
            setError('The number of districts in the children structure does not match the actual number of districts.');
          }
        }
      } else if (existingSubdistricts.length > 0) {
        setDistrictGroupOption('has-districts');
        const newChildren = existingSubdistricts.map(d => ({
          identifier: d.name || d.number || d.letter,
          votingType: d.votingType,
        }));
        setChildren(newChildren);
        setError('The children structure for this district is missing. Please update the children structure to match the actual number of districts.');
      }

      if (parentDistrict?.sources) {
        const sourcesFiltered = parentDistrict.sources.filter(source => source.type === tier3Type).map(source => source.url);
        setSources([
          ...sourcesFiltered,
          ''
        ]);
        setSourcesGiven(sourcesFiltered);
      }

      if (parentDistrict?.notes) {
        const note = parentDistrict.notes.find(note => note.startsWith(tier3Type + ':'));
        if (note) {
          setNoteGiven(note.split(':')[1]);
          setNote(note.split(':')[1]);
        }
      }
    }
  }, [open]);


  const handleAddChild = () => {
    setChildren((prev) => {
      const newArr: Child[] = [
        ...prev,
        {
          identifier: '',
          votingType: 'voting-district',
        },
      ];
      // The newly added row’s index = newArr.length - 1
      setFocusRowIndex(newArr.length - 1);
      return newArr;
    });
  };

  useEffect(() => {
    // If user selects "Has districts," ensure at least one row exists
    // and focus that row.
    if (districtGroupOption === 'has-districts' && children.length === 0) {
      setChildren([{ identifier: '', votingType: 'voting-district' }]);
      setFocusRowIndex(0);
    }
  }, [districtGroupOption]);

  // Whenever children changes or focusRowIndex changes, focus the appropriate row.
  useEffect(() => {
    if (
      focusRowIndex !== null &&
      focusRowIndex >= 0 &&
      focusRowIndex < children.length
    ) {
      textFieldRefs.current[focusRowIndex]?.focus();
    }
  }, [children, focusRowIndex]);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => {
    // If user presses Enter or Tab in this field...
    if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
      e.preventDefault();  // Prevent the default tab/enter action
      // Add a new row and focus it
      handleAddChild();
    }
  };

  const saveInformation = async () => {
    if (!parentDistrict || !stateConfiguration || !tier3Type) return;
    if (saving) return;
    setSaving(true);

    // Perform the operations based on operationsToPerform
    const {
      districts,
      childrenStructureValue,
    } = operationsToPerform;

    const sourcesAll: DistrictSource[] = [
      ...sources.filter(Boolean).map(source => ({ url: source.trim(), type: tier3Type })),
      ...parentDistrict.sources || [],
    ];
    // dedup the sources, taking preference for the most recent ones
    const sourcesToSave: DistrictSource[] = sourcesAll.filter((source, index, arr) => {
      return arr.findIndex(s => s.url === source.url && s.type === source.type) === index;
    })
    const noteModified = note.trim()?.length > 0 ? note.trim() : undefined;
    const notesToSave: string[] = (parentDistrict.notes || []).map(existingNote => {
      if(existingNote.startsWith(tier3Type + ':')) {
        return noteModified ? `${tier3Type}:${noteModified}` : undefined;
      } else {
        return existingNote;
      }
    }).filter((note): note is string => note !== undefined)

    if(noteModified && !notesToSave.find(note => note.startsWith(tier3Type + ':'))) {
      notesToSave.push(`${tier3Type}:${noteModified}`);
    }

    if (childrenStructureValue !== -1) {
      // first, perform the delete operations
      const deleteOperations = districts.filter((d): d is DeleteOperation => d.operationType === 'delete');
      const deletePromises = deleteOperations.map(({ id }) => feathers.getService('districts').remove(id));

      try {
        await Promise.all(deletePromises);
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError('An unknown error occurred');
        }
        setSaving(false);
        return;
      }


      // next, patch the parent district childrenStructure
      const newChildrenStructure = {
        ...parentDistrict.childrenStructure,
        [tier3Type]: childrenStructureValue,
        'validatedAt': new Date().toISOString(),
      };

			let updatedParentDistrict;
      try {
        updatedParentDistrict = await feathers.getService('districts').patch(parentDistrict._id, {
          childrenStructure: newChildrenStructure,
          notes: notesToSave,
          sources: sourcesToSave
        });
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError('An unknown error occurred');
        }
        setSaving(false);
        return;
      }

      // then, perform the operations on the districts (either creating, patching, or deleting)
      try {
        const resolvedDistricts = await Promise.all(districts.map((d) => {
          if (d.operationType === 'create') {
            return feathers.getService('districts').create(d.value);
          } else if (d.operationType === 'patch') {
            return feathers.getService('districts').patch(d.id, d.value);
          }
          return null;
        }));

        // now, create the final list of districts
        const createdAndPatchedDistricts = resolvedDistricts.filter((d): d is District => !!d);
        const existingDistrictsNotDeleted = existingSubdistricts.filter(d => !districts.find(dd => dd.id === d._id));
        const allDistricts = createdAndPatchedDistricts.concat(existingDistrictsNotDeleted).sort((a, b) => {
          if (a.number && b.number) {
            return a.number - b.number;
          } else if (a.letter && b.letter) {
            return a.letter.localeCompare(b.letter);
          } else {
            return a.name.localeCompare(b.name);
          }
        });

        if (onFinish)
          onFinish(
            updatedParentDistrict,
            allDistricts,
          );
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError('An unknown error occurred');
        }

        setSaving(false);
      }
      return;
    }
  };

  let contentInner = null;
  if (parentDistrict && stateConfiguration && tier3Type) {
    contentInner = (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h2">Add {humanizedTier3Type}</Typography>
        {
          error &&
          <Typography variant="body2" style={{ color: 'red' }}>{error}</Typography>
        }
        <div style={{ marginTop: '12px', paddingLeft: '16px'  }}>
          <Typography variant='body1' style={{ fontWeight: 'bold', marginLeft: '-16px' }}>Sources</Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '8px' }}>
            {(sources.slice(0, -1)).map((source, index) => (
              <Chip
                variant="outlined"
                key={index}
                label={
                  <a href={source} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                  {source}
                  </a>
                }
                onDelete={() => {
                  setSources(sources.filter((_, i) => i !== index));
                }}
              />
            ))}
             <TextField
                InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      const trimmedValue = sources.length ? sources[sources.length - 1].trim() : '';
                      if (trimmedValue.length > 0) {
                        setSources(s => [...s, '']);
                      }
                    }}
                  >
                    <KeyboardReturn />
                  </IconButton>
                ),
                }}
              label="Add source"
              variant="outlined"
              size="small"
              value={sources[sources.length - 1] || ''}
              onChange={(event) => setSources([...sources.slice(0, sources.length - 1), event.target.value])}
              onKeyDown={(event) => {
                const trimmedValue = sources.length ? sources[sources.length - 1].trim() : '';
                if (event.key === 'Enter' && trimmedValue.length > 0) {
                  setSources(s => [...s, '']);
                }
              }}
            />
          </div> 
          <div style={{ marginTop: '12px' }}>
            <TextField
              label="Note"
              variant="outlined"
              size="small"
              fullWidth
              value={note}
              onChange={(event) => setNote(event.target.value)}
            />
          </div>
        </div>
        <div style={{ marginTop: '24px', paddingLeft: '16px' }}>
          <Typography variant='body1' style={{ fontWeight: 'bold', marginLeft: '-16px' }}>Subdistricts</Typography>
          <RadioGroup
            value={districtGroupOption}
            onChange={(event) => setDistrictGroupOption(event.target.value as 'has-districts' | 'no-districts')}
          >
            <FormControlLabel value="no-districts" control={<Radio />} label="Does not have districts" />
            <FormControlLabel value="has-districts" control={<Radio />} label="Has districts" />
          </RadioGroup>
        </div>
        {
          districtGroupOption === 'has-districts' &&
          <>
            <TableContainer component={Paper} style={{ marginLeft: '16px', marginTop: '12px', border: 'solid 1px #dddddd', maxWidth: 450 }}>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Identifier</TableCell>
                    <TableCell>Preview</TableCell>
                    <TableCell>Voted on at-large?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    children.map((child, idx) => (
                      <TableRow key={idx} style={{ backgroundColor: idx % 2 === 0 ? '#f8f8f8' : '#FFFFFF' }}>
                        <TableCell>
                          <TextField
                            size="small"
                            variant="standard"
                            style={{ backgroundColor: 'transparent' }}
                            value={child.identifier}
                            onChange={(event) => {
                              const newChildren = [...children];
                              newChildren[idx].identifier = event.target.value;
                              setChildren(newChildren);
                            }}
                            inputRef={(el) => {
                              // Store the input reference in an array
                              textFieldRefs.current[idx] = el as HTMLInputElement;
                            }}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, idx)}
                          />
                        </TableCell>
                        <TableCell>
                          {
                            orderedNamingPatterns.length === 0 &&
                            <Skeleton variant="text" width={100} />
                          }
                          {
                            Boolean(child.identifier) &&
                            <Typography variant='body1'>
                              {prettyDistrictNameFromPattern({
                                [inferredIdentifierTypeFromInput(child.identifier)]: child.identifier,
                                type: tier3Type,
                                parent: parentDistrict?.name,
                              }, orderedNamingPatterns)?.longName || ''}
                            </Typography>
                          }
                        </TableCell>
                        <TableCell>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Select
                              value={child.votingType}
                              variant="standard"
                              style={{ backgroundColor: 'transparent' }}
                              size="small"
                              onChange={(event) => {
                                const newChildren = [...children];
                                newChildren[idx].votingType = event.target.value as DistrictVotingType;
                                setChildren(newChildren);
                              }}
                            >
                              <MenuItem value="voting-district">No</MenuItem>
                              <MenuItem value="representative-district">Yes</MenuItem>
                            </Select>
                            <IconButton size="small" onClick={() => {
                              const newChildren = [...children];
                              newChildren.splice(idx, 1);
                              setChildren(newChildren);
                            }}>
                              <Cancel style={{ fontSize: '18px' }} />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                  <TableRow>
                    <TableCell>
                      <Button size="small" onClick={handleAddChild}>Add</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
        {
          operationSummary &&
          <Typography variant="body2" style={{ marginTop: '12px' }}>{operationSummary}</Typography>
        }
        <Button
          variant="contained"
          disabled={!operationReady || saving}
          style={{ alignSelf: 'flex-end', marginTop: '12px' }}
          onClick={saveInformation}
        >
          {saving ? 'Saving...' : 'Confirm'}
        </Button>
      </div>
    );
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalInner onClose={onClose}>
        {contentInner}
      </ModalInner>
    </Modal>
  );
};

export { Tier3AddDistrictModal };