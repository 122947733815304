import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Redirect, useParams } from 'react-router-dom';
import { useFeathers } from '../../app/util';
import { useSelector } from 'react-redux';
import { TextField, MenuItem, Tooltip, Typography, Select } from '@mui/material';
import {
  CTAButton,
  SearchableSelectInput,
  CloseButton,
  HintTile,
  Checkbox,
  PartySelect,
  SearchItemStyle,
  SelectableList,
  CirclePhoto
} from '..';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const coveragePlanOptions = [
  { value: 'auto-coverage', label: 'Auto coverage', description: 'Full issues information, where first draft is created by our algorithm.' },
  { value: 'issues-coverage', label: 'Full platforms', description: 'Full issues information, where first draft is created manually by a resercher.' },
  { value: 'basic-coverage', label: 'Basic info', description: 'Only basic information, such as website, photo, and social media.' },
  { value: 'no-coverage', label: 'No info', description: 'Only candidate name' }
]


const CreateRaceModal = ({
  onClose,
  onSaveCreateRace = true,
  redirectOnFinish,
  onFinish,           // if provided as a function, will pass back the race data to create.
  election,           // the full eleciton object
  restrictToDistrict, // a district id to restrict the search to descendants of (defaults to election.districts)
  restrictToOfficesWithDistrictTypes, // an array of district types to restrict the search to

  // default props
  coveragePlan
}) => {
  const feathers = useFeathers();

  const headerTitle = useMemo(() => {
    if (!restrictToDistrict?._id) {
      return 'New Race'
    } else {
      return `New Race for ${restrictToDistrict?.longName}`
    }
  }, [restrictToDistrict])

  const isPartisan = election?.partisan;
  const [ formInputs, setFormInputs ] = useState({
    ballotText: null,
    office: null,
    district: null,
    hasPrecedents: null,
    precedents: [],
    winners: [],  // if we have precedents
    coverage: coveragePlan,
    retention: false,
    party: null,
    duplicate: null,
    additionalDistricts: null,
    hasAdditionalDistricts: null,
  })

  const [ progress, setProgress ] = useState(0);
  const submittable = progress >= 4;

  const onChange = e => {
    const { name, value } = e.target;
    const update = {};
    update[name] = value;
    let newInputs = {
      ...formInputs,
      ...update
    };

    if (name === 'hasAdditionalDistricts' && value === false) {
      newInputs.additionalDistricts = []
    }

    // Maintain the workflow for the inputs
    if(!newInputs.office) {
      setFormInputs({ ...newInputs, ...{ district: null, hasAdditionalDistricts: null,  additionalDistricts: null, hasPrecedents: null, coverage: null, duplicate: null, party: null }})
      setPrecedentOptions([]);
      setProgress(0);
    } else if(!newInputs.district) {
      setFormInputs({ ...newInputs, ...{ hasAdditionalDistricts: null,  additionalDistricts: null, hasPrecedents: null, coverage: null, duplicate: null, party: null }})
      setProgress(1);
      setPrecedentOptions([]);
    } else {
      // now we do some more nuanced work
      if(newInputs.hasPrecedents === null) {
        // we make this determination for ourselves first
        const hasPrecedents = Boolean(election.runoffPrecedent || election.primaryPrecedent);
        setFormInputs({ ...newInputs, hasPrecedents, duplicate: null, party: null })
        setProgress(hasPrecedents ? 2 : 3);
      } else if(newInputs.hasPrecedents === true) {
        // in this section, we want to check if the user has selected a precedent
        if(!newInputs.precedents.length) {
          // if we're setting the hasPrecedents directly
          setFormInputs({ ...newInputs, duplicate: null, party: null, precedents: [], winners: [] })
          setProgress(2);
        } else {
          const winnersToFoldIn = name === 'precedents' ? newInputs.precedents.map(race => {
            const winners = Object.entries(race?.result?.byCandidate || []).filter(([ _id, c ]) => ['winner', 'runoff'].includes(c.status))
            return winners.map(w => w[0])
          }).flat() : [];

          const winners = [].concat(
            newInputs.winners || [],
            winnersToFoldIn
          );
 
          // now dedup by candidate id
          const winnersFullIdsUnique = [...new Set(winners)];

          newInputs.winners = winnersFullIdsUnique;

          // if we're setting the winners / precedents directly
          setFormInputs(newInputs)
          setProgress(newInputs?.winners?.length > 0 ? 4 : 3);
        }
      } else if (newInputs.hasPrecedents === false) {
        if(!newInputs.coverage) {
          setFormInputs({ ...newInputs, coverage: coveragePlan, ...{ duplicate: null, party: null }})
          setProgress(3)
        } else {
          if(isPartisan && !newInputs.party) {
            setFormInputs({ ...newInputs, ...{ duplicate: null }})
            setProgress(3);
          } else {
            setFormInputs(newInputs)
            setProgress(4);
          }
        }
      }
    } 
  }

  const descendantOf = useMemo(
    () => (restrictToDistrict
      ? (restrictToDistrict?._id || restrictToDistrict)
      : (election?.districts?.map((d) => d._id))),
    [election, restrictToDistrict]
  )

  const [ precedentOptions, setPrecedentOptions ] = useState([]);
  const [ precedentLoading, setPrecedentLoading ] = useState(false);

  const loadPrecedentOptions = async () => {
    if(!feathers) return;
    setPrecedentLoading(true);

    const precedentElections = [...new Set([election.runoffPrecedent, election.primaryPrecedent])].filter(Boolean);

    const raceOptions = await feathers.getService('races').find({
      query: {
        election: precedentElections,
        office: formInputs.office?.key,
        district: formInputs.district?._id,
        $limit: 100
      }
    })

    if(!raceOptions.data.length) {
      setFormInputs({ ...formInputs, hasPrecedents: false })
    } else {
      const options = raceOptions.data.map(({ candidates, ...d }) => ({ ...d, text: d.longName }))
      setPrecedentOptions(options);
      setPrecedentLoading(false);
    }
  }

  const winnerOptionsAll = useMemo(() => {
    if(!formInputs.precedents.length) return [];
    return formInputs.precedents.map(race => {
      const candidateIds = race.candidateSummary.ids || [];
      const candidatesFull = candidateIds.map((_id, idx) => {
        return {
          _id,
          name: race.candidateSummary.names[idx],
          photo: race.candidateSummary.photos[idx]
        }
      })
      return candidatesFull
    }).flat();
  }, [ formInputs.precedents ])

  useEffect(() => {
    if(formInputs.hasPrecedents && !precedentLoading && !precedentOptions.length) {
      loadPrecedentOptions();
    }
  }, [ formInputs, precedentOptions, precedentLoading ])

  /* manage searching options for selection, based on search text */
  const [ optionsForField, setOptionsForField ] = useState({});
  const [ loadingForField, setLoadingForField ] = useState({});

  const searchForField = async (field, searchText, addtlParams) => {
    if(!feathers) return;

    const loadingUpdate = {};
    loadingUpdate[field] = true;
    setLoadingForField({ ...loadingForField, ...loadingUpdate });

    let options = [];
    const officeToReference = addtlParams?.office || formInputs?.office;
    if(field === 'election') {
      const elections = await feathers.getService('elections').find({ query: {
        ...(searchText ? { name: { $search: searchText } } : {}),
        $sort: { date: -1 }
      }})
      options = elections.data.map(({ name, key }) => ({ text: name, key }));
    } else if(field === 'office') {
      const offices = await feathers.getService('offices').find({ query: {
        ...(restrictToOfficesWithDistrictTypes ? { districtTypes: { $in: restrictToOfficesWithDistrictTypes } } : {}),
        ...(searchText ? { name: { $search: searchText } } : {}),
        ...(descendantOf ? { district: { $within: descendantOf }} : {})
      }})
      options = offices.data.map(({ name, key, districtTypes }) => ({ text: name, key, districtTypes }));
    }
    else if(['district', 'additionalDistricts'].includes(field) && officeToReference) {
      const districtTypes = officeToReference
        ? officeToReference.districtTypes.includes("country")
          ? ["state"]
          : (officeToReference.districtTypes || [])
        : null;

      const districts = await feathers.getService('districts').find({
        query: {
          ...(searchText ? { searchTerm: { $search: searchText } } : {}),
          ...(districtTypes ? { type: districtTypes } : {}),
          ...(descendantOf ? { $descendantOf: descendantOf, $nestChildren: false, $includeAncestor: true } : {}),
          $sort: { number: 1 }
        },
      })

      options = districts.data.map(({ longName, _id, votingType }) => ({ text: `${longName}${votingType === 'representative-district' ? ' (at-large)' : ''}`, _id }))
      if (field === 'additionalDistricts') {
        options = options.filter(d => d._id !== formInputs.district?._id)
      }
    }

    const updateForOptions = {};
    updateForOptions[field] = options;
    setOptionsForField({ ...optionsForField, ...updateForOptions })

    const loadingUpdate2 = {};
    loadingUpdate2[field] = false;
    setLoadingForField({ ...loadingForField, ...loadingUpdate2 });

    return options;
  }

  const [ submitting, setSubmitting ] = useState(false);
  const [ raceCreated, setRaceCreated ] = useState(false);
  const [ duplicateRace, setDuplicateRace ] = useState(false);
  const [ error, setError ] = useState(false);
  const submit = async () => {
    if(!submittable || submitting) return;

    let mappedInputs = {};
    if(formInputs.hasPrecedents) {
      if(!formInputs.precedents || !formInputs.winners) {
        throw new Error('Cannot create')
      }
      // check if the inputs are primary or runoff

      const inputsArePrimary = election.primaryPrecedent && formInputs.precedents.some(race => race.party);
      const inputsAreRunoff = election.runoffPrecedent && formInputs.precedents?.length === 1;
      if(!inputsArePrimary && !inputsAreRunoff) {
        throw new Error('Cannot determine if inputs are primary or runoff')
      }
      if(inputsArePrimary) {
        mappedInputs = {
          election: election.key,
          primaryPrecedent: formInputs.precedents.map(raceFull => {
            const winnersForRace = formInputs.winners.filter(winnerId => raceFull.candidateSummary.ids.includes(winnerId))
            return {
              ...raceFull,
              winners: winnersForRace
            }
          }),
          entityMatchInputs: [ formInputs.ballotText ],
          $suppress409: formInputs?.duplicate,
        }
      } else if(inputsAreRunoff) {
        mappedInputs = {
          election: election.key,
          runoffPrecedent: {
            ...formInputs.precedents[0],
            winners: formInputs.winners
          },
          entityMatchInputs: [ formInputs.ballotText ]
        }
      }
    } else {
      mappedInputs = {
        election: election?.key,
        office: formInputs?.office?.key,
        district: formInputs?.district?._id,
        additionalDistricts: formInputs?.additionalDistricts?.map(d => d._id),
        coveragePlan: formInputs?.coverage,
        ...(isPartisan ? { party: formInputs?.party } : {}),
        $suppress409: formInputs?.duplicate,
        timestamp: new Date().toISOString(), // since this is not immediately being saved to the db -- a way to uniquely identify the race for the time being
        candidates: [],
        entityMatchInputs: [ formInputs.ballotText ]
      }
    }

    setSubmitting(true);
    setError(false);

    console.log(mappedInputs)
    try {
      const race = await feathers.getService('races').create(mappedInputs);
      setRaceCreated({
        id: race._id,
        election: race.election
      })
      if(onFinish) onFinish(race);
    } catch(err) {
      // Is error code a duplicate error?

      const errorAsJson = err.toJSON()
      if(errorAsJson.code === 409) {
        setDuplicateRace(true)
      } else {
        setError(errorAsJson)
      }
      setSubmitting(false);
    }
  }

  const [ stageUpdate, setStageUpdate ] = useState(null);
  useEffect(() => {
    if(stageUpdate) {
      setStageUpdate(null);
      onChange(stageUpdate)
    }
  }, [ stageUpdate ])

  const loadInitialOptions = async () => {
    // on initial load, load the options for different fields, selecting defaults if there's only 1 option
    if(!feathers) return;

    const fieldOpts = await searchForField('office', '');
    if(fieldOpts.length !== 1) return;
    const office = fieldOpts[0];
    setStageUpdate({ target: { name: 'office', value: office }})
    // setFormInputs({ ...formInputs, office })
    // setProgress(1)

    // now for district
    const districtOpts = await searchForField('district', '', { office });
    if(districtOpts.length !== 1) return;
    const district = districtOpts[0];
    setStageUpdate({ target: { name: 'district', value: district }})

    if(coveragePlan) {
      setStageUpdate({ target: { name: 'coveragePlan', value: coveragePlan }})    
    }
    // setFormInputs({ ...formInputs, office, district, ...(coveragePlan ? { coverage: coveragePlan } : {}) })
    // setProgress(coveragePlan ? 4 : 3)
  }

  useEffect(() => {
    if(feathers) {
      loadInitialOptions();
    }
  }, [ feathers ])

  if(raceCreated && redirectOnFinish) {
    return <Redirect to={`/elections/${raceCreated.election?.key ?? raceCreated.election}/races/${raceCreated.id}`} />
  }

  const districtRow = () => (
    <Row>
      <div className='label'><Typography variant='body1' style={{ whiteSpace: 'nowrap' }}>District</Typography></div>
      <SearchableSelectInput
        name='district'
        onChangeSearchText={(searchText) => searchForField('district', searchText)}
        value={formInputs.district}
        onChange={onChange}
        optionsLoading={loadingForField.district}
        options={optionsForField?.district || []}
        style={{ flexGrow: 1 }}
      />
    </Row>
  );

  const precedentsPossible = (election?.runoffPrecedent || election?.primaryPrecedent)

  return (
    <Wrapper>
      <CloseButton
        onClick={onClose}
        style={{
          position: 'absolute',
          height: '36px',
          right: '-20px',
          top: '-20px'
        }}
      />
      <Top>
        <h1>{headerTitle}</h1>
        <Row>
          <div className='label' style={{ display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <Typography variant='body1' style={{ whiteSpace: 'nowrap' }}>Ballot text</Typography>
            <Tooltip title='How this item exactly appears on the ballot, including the district'><InfoOutlined style={{ fontSize: '20px', marginTop: '2px' }}/></Tooltip>
          </div>
          <TextField
            value={formInputs.ballotText}
            onChange={(e) => onChange({ target: { name: 'ballotText', value: e.target.value }})}
            variant='outlined'
            style={{
              border: 'solid 1px #EEEEEE',
              borderRadius: '8px',
              flexGrow: 1
            }}
            size='small'
          />
        </Row>
        <Row>
          <div className='label'><Typography variant='body1' style={{ whiteSpace: 'nowrap' }}>Office</Typography></div>
          <SearchableSelectInput
            name='office'
            onChangeSearchText={(searchText) => searchForField('office', searchText)}
            value={formInputs.office}
            onChange={onChange}
            optionsLoading={loadingForField.office}
            options={optionsForField?.office || []}
            style={{ flexGrow: 1 }}
          />
        </Row>
        {
          (progress >= 1) &&
          districtRow()
        }
        {
          progress >= 2 && (precedentsPossible) &&
          <Row>
            <div className='label'><Typography variant='body1' style={{ whiteSpace: 'nowrap' }}>Has Previous</Typography></div>
            <TextField select name='hasPrecedents' onChange={onChange} style={{ flexGrow: 1 }} value={formInputs?.hasPrecedents}>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Row>
        }
        {
          (progress >= 2 && formInputs.hasPrecedents) &&
          <Row>
            <div className='label' style={{ display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
              <Typography variant='body1' style={{ whiteSpace: 'nowrap' }}>Copy from</Typography>
              <Tooltip title='If this race has a primary election(s) in our system, select them to merge and copy the data'><InfoOutlined style={{ fontSize: '20px', marginTop: '2px' }}/></Tooltip>
            </div>
            <SearchableSelectInput
              multi={true}
              name='precedents'
              searchOptionComponent={PrecedentSearchItem}
              onChangeSearchText={(searchText) => console.log('doing nothing with searchText', searchText)}
              value={formInputs.precedents}
              onChange={onChange}
              optionsLoading={precedentLoading}
              options={precedentOptions || []}
              style={{ flexGrow: 1 }}
            />
          </Row>
        }
        {
          (progress >= 3 && formInputs.precedents?.length > 0) &&
          <Row>
            <div className='label' style={{  alignSelf: 'flex-start', paddingTop: '30px' }}><Typography variant='body1' style={{ whiteSpace: 'nowrap', }}>Candidates<br/>to copy</Typography></div>
            <SelectableList
              items={winnerOptionsAll}
              selectedIds={formInputs.winners}
              idField='_id'
              onChange={(winners) => onChange({ target: { name: 'winners', value: winners }})}
              listItemComponent={candidate => {
                return (
                  <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <CirclePhoto size='small' photoPath={candidate.photo} />
                    <Typography variant='body1'>{candidate.name}</Typography>
                  </div>
                )
              }}
            />
          </Row>
        }
        {
          (progress >= 2 && formInputs.hasPrecedents === false) &&
          <Row>
            <div className='label'><Typography variant='body1' style={{ whiteSpace: 'nowrap' }}>Coverage plan</Typography></div>
            <TextField select name='coverage' onChange={onChange} style={{ flexGrow: 1 }} value={formInputs?.coverage}>
              {coveragePlanOptions.map((opt) => <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>)}
            </TextField>
          </Row>
        }
        {
          (progress >= 3 && isPartisan && formInputs.hasPrecedents === false) &&
          <Row>
            <div className='label'><Typography variant='body1' style={{ whiteSpace: 'nowrap', }}>Party</Typography></div>
            <PartySelect
              value={formInputs.party}
              style={{ flexGrow: 1 }}
              onChange={(party) => onChange({ target: { name: 'party', value: party } })}
            />
          </Row>
        }
        {
          ((progress >= 2 && !isPartisan) || (progress >= 3 && isPartisan))
          && optionsForField.district?.length > 1
          &&
            <Row>
              <div className='label'><Typography variant='body1' style={{ }}>Additional districts</Typography></div>
              <SearchableSelectInput
                multi={true}
                name='additionalDistricts'
                onChangeSearchText={(searchText) => searchForField('additionalDistricts', searchText)}
                value={formInputs.additionalDistricts}
                onChange={onChange}
                optionsLoading={loadingForField.additionalDistricts}
                options={optionsForField?.additionalDistricts || []}
                style={{ flexGrow: 1 }}
              />
          </Row>
        }
        {
          progress >= 3 && formInputs.hasPrecedents === false &&
          <Row>
            <div className='label'><Typography variant='body1' style={{ }}>Retention election</Typography></div>
            <Select name='retention' onChange={onChange} style={{ flexGrow: 1 }} value={formInputs?.retention}>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </Row>
        }
        {
          (duplicateRace && progress >= 4) &&
          <HintTile style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>
                <b>Heads up! </b>{`There is already a ${formInputs?.office?.text} race created for ${formInputs?.district?.text} for this election. Do you mean to create another?`}
              </span>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '12px' }}>
                <Checkbox
                  checked={formInputs?.duplicate}
                  onClick={() => onChange({ target: { name: 'duplicate', value: !(formInputs.duplicate === true) }})}
                />
                <span style={{ marginLeft: '8px' }}><b>Yes, proceed anyways</b></span>
              </div>
            </div>
          </HintTile>
        }
      </Top>
      <CTAButton
        value={submitting ? `Creating...` : 'Create'}
        disabled={!submittable || submitting}
        onClick={submit}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  min-height: 200px;
`
const Top = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 28px;
    margin: 0 0 26px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 28px;

  div.label {
    font-size: 18px;
    margin: 0 14px 0 0;
    opacity: 0.7;
    width: 100px;
  }
`;

const PrecedentSearchItem = (race) => {
  return (
    <SearchItemStyle>
      <div>{race.longName}, {race.election?.name || race.election.split('-').map(elSeg => elSeg.slice(0,1).toUpperCase() + elSeg.slice(1)).join(' ')}</div>
      <div className='subtext'>{(race.candidateSummary.names || []).join(', ')}</div>
    </SearchItemStyle>
  )
}


export { CreateRaceModal };
