import React from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const TabControl = ({
  children,
  onChange,
  activeTab,
  style,
  tabStyle = {},
  align='left'
}) => {
  const childrenActual = Array.isArray(children) ? children.filter(Boolean) : children;
  const numChildren = Array.isArray(childrenActual) ? childrenActual?.length : 1;

  return (
    <Wrapper style={style} align={align}>
    {
      !Array.isArray(childrenActual) &&
      <TabElement
        align={align}
        numTabs={numChildren}
        className={'active'}
        firstitem={true}
        style={tabStyle}
        onClick={() => onChange(0)}
      >
        {React.cloneElement(childrenActual, { active: true })}
      </TabElement>
    }
    {
      Array.isArray(childrenActual) &&
      childrenActual.map((child, index) => {
        const active = activeTab === index;
        return (
          <TabElement
            align={align}
            numTabs={numChildren}
            className={active ? 'active' : ''}
            firstitem={index === 0 ? 1 : 0}
            key={index}
            style={tabStyle}
            onClick={() => onChange(index)}
          >
            {React.cloneElement(child, { active })}
          </TabElement>
        );
      })
    }
    </Wrapper>
  )
}

const Tab = ({ children, active, style }) => {
  const theme = useTheme();
  const styles = {
    color: '#000000',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    fontSize: '14px',
    textAlign: 'center',
    ...(style || {})
  }
  const activeTabSpanStyles = { ...styles, color: theme.palette.textSecondary };

  return (
    <Typography variant='body1' style={active ? activeTabSpanStyles : styles}>
      {children}
    </Typography>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: ${props => props.align === 'center' ? 'center' : 'flex-start'};

  width: 100%;
`

const TabElement = styled.div`
  padding: 8px 16px;

  ${props => props.align === 'center' ? `width: calc((100% - 16px * 2) / ${props.numTabs});` : ``};

  ${props => props.firstitem || props.align === 'center' ? `` : `margin-right: 16px;`}

  cursor: pointer;
  opacity: 0.4;

  border-bottom: solid 2px #FFFFFF00;

  &.active {
    opacity: 1.0;
    border-bottom: solid 2px ${props => props.theme.palette.textSecondary};
  }
`

TabControl.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  style: PropTypes.object,
  tabStyle: PropTypes.object,
  align: PropTypes.string,
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  style: PropTypes.object
};

export { Tab, TabControl };
