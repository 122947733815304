import { District } from '../../app/feathers/districts/District';
import React, { useEffect, useState } from 'react';
import { Button, List, ListItem, Modal, TextField } from '@mui/material';
import { ModalInner } from '../../components';
import styled from 'styled-components';
import { Add, ArrowLeft, ArrowRightAlt, CancelOutlined, Check, HighlightOff } from '@mui/icons-material';
import { parse } from 'csv-parse/browser/esm/sync';
import { Options } from 'csv-parse';
import { districtLetters } from '../../app/feathers/districts/districtLetters';
import { DistrictLetter } from '../../app/feathers/districts/DistrictLetter';
import Typography from '@mui/material/Typography';
import { useFeathers } from '../../app/util';
import { Application } from '@feathersjs/feathers';
import { ServiceTypes } from '../../app/feathers/ServiceTypes';
import ListBasedIcon from './list-based.png';
import RangeBasedIcon from './range-based.png';
import { prettyDistrictNameFromPattern } from '../../app/util/pretty-district-name-from-pattern';
import { NamingPattern } from '../../app/feathers/naming-patterns/NamingPattern';
import { TileSelectOption } from '../../components/lower-order';
import { asyncPoolAll } from '../../app/util';
import { PopulationType } from "../../app/feathers/districts/PopulationType";

interface Tier2DistrictTypeAddModalProps {
  parent: District;
  existingDistricts: District[];
  type: string;
  onClose: () => void;
  onUpdateDistricts: (districts: District[]) => void;
}

const humanize = (value: string) =>
  value
    ?.replace(/-/g, ' ')
    ?.replace(/(^| )(\w)/g, (_, __, p2) => ` ${p2.toUpperCase()}`);

const supportedColumns: {
  name: string;
  label: string;
  required: boolean;
}[] = [
  {
    name: 'identifier',
    label: 'Name, Number, or Letter',
    required: true,
  },
  {
    name: 'population',
    label: 'Population',
    required: false,
  },
  {
    name: 'notes',
    label: 'Notes',
    required: false,
  },
];

export const Tier2DistrictTypeAddModal = (
  {
    parent,
    existingDistricts,
    onClose,
    type,
    onUpdateDistricts,
  }: Tier2DistrictTypeAddModalProps,
) => {
  const [creationMode, setCreationMode] = useState<null | 'paste-list' | 'by-range'>(null);
  /*
    Step 0: Select creation mode
    Step 1: Paste data (in the case of a csv) or configure input data for bulk creation
    Step 2: Review and confirm
    Step 3: Success or error message
  */
  const [currentStep, setCurrentStep] = useState<0 | 1 | 2 | 3>(0);
  const [creates, setCreates] = useState<Partial<District>[]>([]);
  const [updates, setUpdates] = useState<(Partial<District> & { _id: string })[]>([]);
  const [noChanges, setNoChanges] = useState<Partial<District>[]>([]);
  const [creating, setCreating] = useState<boolean>(false);

  // For list-based creation
  const [data, setData] = useState('');
  const [detectedColumns, setDetectedColumns] = useState({} as Record<string, string | boolean>);
  const [maxNumber, setMaxNumber] = useState(0);
  const [error, setError] = useState<Error | null>(null);

  // for range based creation
  const [rangeStart, setRangeStart] = useState<number | ''>(1);
  const [rangeEnd, setRangeEnd] = useState<number | ''>('');

  const [ orderedNamingPatterns, setOrderedNamingPatterns ] = useState<(NamingPattern | null)[]>([]);
  
  const feathers = useFeathers<Application<ServiceTypes>>();

  useEffect(() => {
    // every time current step resets to 0, reset the data
    if (currentStep === 0) {
      setData('');
      setDetectedColumns({});
      setCreates([]);
      setUpdates([]);
      setNoChanges([]);
      setRangeStart(1);
      setRangeEnd('');

      loadNamingPatterns()
    }
  }, [currentStep]);

  const loadNamingPatterns = async () => {
    const namingPatternService = feathers.getService('naming-patterns');
    const stateNamingPatternId = parent?.namingPatternId;
    const namingPatterns = await Promise.all([
      namingPatternService.find({ query: { key: 'default-national'} }),
      namingPatternService.find({ query: { _id: stateNamingPatternId }}),
    ]);

    const patterns = namingPatterns.map(np => np.data).flat();
    const stateNamingPattern = patterns.find((pattern) => pattern._id === stateNamingPatternId && pattern.key !== 'default-national') || null;
    const defaultNamingPattern = patterns.find((pattern) => pattern.key === 'default-national') || null;
    const orderedPatterns = [stateNamingPattern, defaultNamingPattern]

    setOrderedNamingPatterns(orderedPatterns);
  }

  const handleRangeChange = (start: number | '', end: number | '') => {
    setRangeStart(start);
    setRangeEnd(end);

    const rangeStart = start === '' ? null : start;
    const rangeEnd = end === '' ? null : end;
    if (rangeStart === null || rangeEnd === null) {
      setCreates([]);
      setUpdates([]);
      setNoChanges([]);
      return;
    }

    // see if we have any missing data or invalid ranges
    if (typeof start !== 'number' || typeof end !== 'number' || !Number.isInteger(rangeStart) || !Number.isInteger(rangeEnd) || rangeStart > rangeEnd || rangeStart < 1 || rangeEnd < 1) {
      setCreates([]);
      setUpdates([]);
      setNoChanges([]);
      return;
    }

    const numberedArray = Array.from({ length: rangeEnd - rangeStart + 1 }).map((_, i) => i + rangeStart);
    const districts: Partial<District>[] = numberedArray.map(
      (number) => {
        const district: Partial<District> = {};
        district.number = +number;
        return district;
      }
    );
    setMaxNumber(districts[districts.length - 1].number || 0);

    const creates: Partial<District>[] = [];
    const updates: (Partial<District> & { _id: string; })[] = [];
    const noChanges: Partial<District>[] = [];
    districts.forEach(district => {
      const existingDistrict = existingDistricts.find(
        existing => existing['number'] === district['number'],
      );
      if (!existingDistrict) {
        creates.push(district);
      } else {
        const update = Object.fromEntries(
          Object.entries(district)
            .filter(([key, value]) =>
              existingDistrict[key as keyof District] !== value),
        );
        const hasChanges = Object.keys(update).length;
        if (hasChanges) {
          updates.push({ _id: existingDistrict._id, ...update });
        } else {
          noChanges.push(district);
        }
      }
    });

    setCreates(creates);
    setUpdates(updates);
    setNoChanges(noChanges);
  }
  const handleDataChange = (data: string) => {
    setData(data);
    const options: Options = {
      skipEmptyLines: true,
      trim: true,
    };
    if (data.split('\n')[0]?.includes('\t')) {
      options.delimiter = '\t';
    }
    try {
      const parsedData = parse(data, options) as string[][];
      const upperCaseHeaders = parsedData[0].map(header => header.toUpperCase());
      const firstRow = parsedData[1];
      const populationIndex = upperCaseHeaders.indexOf('POPULATION');
      const detectedColumns: Record<string, string | boolean> = {};
      if (firstRow[populationIndex]) {
        detectedColumns['population'] = true;
      }
      const notesIndex = upperCaseHeaders.indexOf('NOTES');
      if (notesIndex > -1) {
        detectedColumns['notes'] = true;
      }
      const nameIndex = upperCaseHeaders.indexOf('NAME');
      const numberIndex = upperCaseHeaders.indexOf('NUMBER');
      const letterIndex = upperCaseHeaders.indexOf('LETTER');
      let identifier: 'name' | 'number' | 'letter';
      if (firstRow[nameIndex]) {
        identifier = 'name';
      } else if (firstRow[numberIndex]) {
        identifier = 'number';
      } else if (firstRow[letterIndex]) {
        identifier = 'letter';
      } else {
        setDetectedColumns({});
        setCreates([]);
        setUpdates([]);
        setNoChanges([]);
        return;
      }
      detectedColumns['identifier'] = identifier;
      setDetectedColumns(detectedColumns);
      const districts = parsedData.slice(1).reduce(
        (districts, row) => {
          const district: Partial<District> = {};
          const letter = row[letterIndex]?.toUpperCase() as DistrictLetter;
          if (identifier === 'name') {
            district.name = row[nameIndex];
            if (type === 'county') {
              district.name = district.name.replaceAll(' County', '').trim();
            }
          } else if (identifier === 'number') {
            district.number = +row[numberIndex];
          } else if (identifier === 'letter' && districtLetters.includes(letter)) {
            district.letter = letter;
          } else {
            return districts;
          }
          if (district[identifier] === '') {
            return districts;
          }
          if (populationIndex > -1) {
            const population = Number.parseInt(
              row[populationIndex].replace(/\.\d+/g, '').replace(/\D/g, ''),
            );
            if (!isNaN(population)) {
              district.population = population;
              district.populationType = 'definitive';
            }
          }
          const notes = row[notesIndex];
          if (notes) {
            district.notes = notes
              .split('\n')
              .map(note => note.trim())
              .filter(Boolean);
          }
          districts.push(district);
          return districts;
        },
        [] as Partial<District>[],
      );
      setMaxNumber(districts[districts.length - 1].number || 0);
      const creates: Partial<District>[] = [];
      const updates: (Partial<District> & { _id: string; })[] = [];
      const noChanges: Partial<District>[] = [];
      districts.forEach(district => {
        const existingDistrict = existingDistricts.find(
          existing => `${existing[identifier]}`.trim() === `${district[identifier]}`,
        );
        if (!existingDistrict) {
          creates.push(district);
        } else {
          const update = Object.fromEntries(
            Object.entries(district)
              .filter(([key, value]) =>
                existingDistrict[key as keyof District] !== value),
          );
          const hasChanges = Object.keys(update).length;
          if (hasChanges) {
            updates.push({
              [identifier]: district[identifier],
              _id: existingDistrict._id,
              ...update,
            });
          } else {
            noChanges.push(district);
          }
        }
      });
      setCreates(creates);
      setUpdates(updates);
      setNoChanges(noChanges);
    } catch (error) {
      setMaxNumber(0);
      setCreates([]);
      setUpdates([]);
      setDetectedColumns({});
    }
  };

  const getIdentifier = () => ((
    creationMode === 'by-range' ? 'number' : detectedColumns['identifier']
  ) as 'name' | 'number' | 'letter');

  const hasValidData = () => {
    return creates.length || updates.length || noChanges.length;
  };

  const add10 = () => {
    const hasHeader = !!data.match(/^number/i);
    let offset = 1;
    const newLines = [];
    if (hasHeader) {
      offset += +(data.match(/^(\d+)/gm)?.pop() || '0');
    } else {
      newLines.push('Number,');
    }
    newLines.push(...Array.from({ length: 10 }).map((_, i) => `${i + offset},`));
    handleDataChange(`${hasHeader ? data + '\n' : ''}${newLines.join('\n')}`);
  };

  const addABCD = () => {
    handleDataChange(['Letter', 'A', 'B', 'C', 'D', ''].join(',\n'));
  };

  const addNSEW = () => {
    handleDataChange(['Letter', 'N', 'S', 'E', 'W', ''].join(',\n'));
  };

  const upsertDistricts = async () => {
    if (creating) return;
    setCreating(true);
    const districtService = feathers.getService('districts');

    try {
      let districtsUpdates = [];
      if (creates.length) {
        const createdDistricts = await asyncPoolAll(10, creates, (district: Partial<District>) => districtService.create({
          ...district,
          parentId: parent._id,
          type,
        }));
        districtsUpdates.push(...createdDistricts);
      }
      if (updates.length) {
        const updatedDistricts = await asyncPoolAll(10, updates, ({ _id, ...update } : { _id: string }) => districtService.patch(_id, update));
        districtsUpdates.push(...updatedDistricts);
      }

      onUpdateDistricts(districtsUpdates);
    } catch (error: any) {
      console.log(error);
      setError(error)
    } finally {
      setCreating(false);
      setCurrentStep(3);
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => onClose()}
    >
      <ModalInner
        style={{
          maxWidth: '900px',
          maxHeight: 'calc(100vh - 64px)',
        }}
        onClose={() => onClose()}
        title={`Add ${humanize(type || '')} Districts`}
      >
        {
          currentStep === 0 && <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Typography variant='body1'>
              How do you want to create the districts?
            </Typography>
            <div style={{
              display: 'flex',
              gap: 8,
              alignSelf: 'center'
            }}>
              <TileSelectOption
                title="Paste a list"
                subText="Paste a list of names and values to create specific districts"
                onChange={() => setCreationMode(cm => cm === 'paste-list' ? null : 'paste-list')}
                selected={creationMode === 'paste-list'}
                value='paste-list'
                style={{ maxWidth: 200 }}
              >
                <img src={ListBasedIcon}/>
              </TileSelectOption>
              <TileSelectOption
                title="Specify a range"
                subText="Create numbered districts within a certain range"
                onChange={() => setCreationMode(cm => cm === 'by-range' ? null : 'by-range')}
                selected={creationMode === 'by-range'}
                value='by-range'
                style={{ maxWidth: 200 }}
              >
                <img src={RangeBasedIcon}/>
              </TileSelectOption>
            </div>
            <Button
              variant="contained"
              color="primary"
              disabled={!creationMode}
              onClick={() => setCurrentStep(1)}
              endIcon={<ArrowRightAlt/>}
              style={{ alignSelf: 'end' }}
            >
              Next
            </Button>
          </div>
        }
        {currentStep === 1 && creationMode === 'paste-list' && <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          <Typography variant="h4">
            Quick Add
          </Typography>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}>
            <Button
              size="small"
              startIcon={<Add/>}
              onClick={() => add10()}
            >
              {maxNumber + 1} - {maxNumber + 10}
            </Button>
            <Button
              size="small"
              startIcon={<Add/>}
              onClick={() => addABCD()}
            >
              ABCD
            </Button>
            <Button
              size="small"
              startIcon={<Add/>}
              onClick={() => addNSEW()}
            >
              NSEW
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              gap: 8,
            }}
          >
            <TextField
              style={{
                flexBasis: '50%',
                flexGrow: 0,
                flexShrink: 0,
              }}
              onChange={({ target }) => {
                handleDataChange(target.value);
              }}
              label="Paste Data"
              multiline
              rows={4}
              value={data}
            />
            <List
              style={{
                opacity: hasValidData() ? 1 : .5,
                flexBasis: '50%',
                flexGrow: 0,
                flexShrink: 0,
              }}
              disablePadding={true}
            >
              {supportedColumns.map(({ name, label, required }) =>
                <ListItem key={name}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      gap: 4,
                    }}
                  >
                    <span style={{ fontWeight: required ? 'bold' : 'normal' }}>
                      {typeof detectedColumns[name] === 'string'
                        ? humanize(detectedColumns[name] as string)
                        : label}:
                    </span>
                    {detectedColumns[name]
                      ? <Check fontSize="small"/>
                      : <HighlightOff
                        color={required ? 'error' : 'disabled'}
                        fontSize="small"
                      />}
                  </div>
                </ListItem>)}
            </List>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => setCurrentStep(0)}
              startIcon={<ArrowLeft/>}
            >
              Back
            </Button>
            <Button
              style={{
                alignSelf: 'end',
              }}
              variant="contained"
              color="primary"
              disabled={!hasValidData()}
              onClick={() => setCurrentStep(2)}
              endIcon={<ArrowRightAlt/>}
            >
              Next
            </Button>
          </div>
        </div>}
        {currentStep === 1 && creationMode === 'by-range' && <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}>
          <Typography variant="h4">
            Specify a range
          </Typography>
          <div
            style={{
              display: 'flex',
              gap: 8,
            }}
          >
            <TextField
              label="Start"
              value={rangeStart}
              onChange={({ target }) => handleRangeChange(+target.value, rangeEnd)}
            />
            <TextField
              label="End"
              value={rangeEnd}
              onChange={({ target }) => handleRangeChange(rangeStart, +target.value)}
            />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => setCurrentStep(0)}
              startIcon={<ArrowLeft/>}
            >
              Back
            </Button>
            <Button
              disabled={!hasValidData()}
              variant="contained"
              color="primary"
              onClick={() => setCurrentStep(2)}
              endIcon={<ArrowRightAlt/>}
            >
              Next
            </Button>
          </div>
        </div>}
        {currentStep === 2 && <>
          <Typography variant='body1' mb={3}>After this step, there will be a total
            of <b>{creates.length + existingDistricts.length} {humanize(type)} districts</b> for this
            state.</Typography>
          <div
            style={{
              display: 'flex',
              gap: 24
            }}
          >
            <DistrictPreviewSection>
              <Typography variant="h3">
                New <small>({creates.length})</small>
              </Typography>
              <OperationPreviewList>
                {creates.map((create) => {
                  const createFull = { ...create, parent: parent.name, type };
                  const nameWritten = prettyDistrictNameFromPattern(createFull, orderedNamingPatterns)?.longName;
                  if(nameWritten.length === 0) {
                    return <li key={create[getIdentifier()]}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <CancelOutlined color='error' style={{ fontSize: '18px'}} />
                        <Typography variant='body1'>Invalid naming pattern</Typography>
                      </div>
                    </li>
                  } else {
                    return <li key={create[getIdentifier()]}>
                      {nameWritten}
                      {Object.keys(create).length > 1 && <ul>
                        {Object.entries(create)
                          .filter(([key]) => key !== getIdentifier())
                          .map(([key, value]) =>
                            <li key={key}>
                              {humanize(key)}: {typeof value === 'number'
                              ? value.toLocaleString()
                              : value}
                            </li>)}
                      </ul>}
                    </li>;
                  }
                })}
              </OperationPreviewList>
            </DistrictPreviewSection>
            <DistrictPreviewSection>
              <Typography variant="h3">
                Updated <small>({updates.length})</small>
              </Typography>
              <OperationPreviewList>
                {updates.map((update) => {
                  const identifier = update[getIdentifier()];
                  return (
                    <li key={identifier}>
                      {identifier}
                      <ul>
                        {Object.entries(update)
                          .filter(([key]) => key !== getIdentifier() && key !== '_id')
                          .map(([key, value]) =>
                            <li key={key}>
                              {humanize(key)}: {typeof value === 'number'
                              ? value.toLocaleString()
                              : value}
                            </li>)}
                      </ul>
                    </li>
                  );
                })}
              </OperationPreviewList>
            </DistrictPreviewSection>
            <DistrictPreviewSection>
              <Typography variant="h3">
                Unchanged <small>({noChanges.length})</small>
              </Typography>
              <OperationPreviewList>
                {noChanges.map((notChanged) => {
                  const identifier = notChanged[getIdentifier()];
                  return <li key={identifier}>
                    {identifier}
                  </li>;
                })}
              </OperationPreviewList>
            </DistrictPreviewSection>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => setCurrentStep(1)}
              startIcon={<ArrowLeft/>}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={(creates.length + updates.length) === 0 || creating}
              onClick={() => upsertDistricts()}
              endIcon={<Check/>}
            >
              {creating ? 'Creating...' : 'Create'}
            </Button>
          </div>
        </>}
        {currentStep === 3 && <div>
          <Typography variant="h3">
            {error
              ? 'An error occurred'
              : `Successfully created ${creates.length} and updated ${updates.length} districts!`}
          </Typography>
          {error && <p>{error?.message}</p>}
          <div style={{
            display: 'flex',
            justifyContent: 'end',
          }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClose()}
            >
              Close
            </Button>
          </div>
        </div>}
      </ModalInner>
    </Modal>
  );
};

const OperationPreviewList = ({ children }: { children: any }) =>
  children?.length === 0
    ? <div>
      <Typography variant="body2" color="textSecondary">
        No updates
      </Typography>
    </div>
    : <div style={{
      border: 'solid 1px #eee',
      borderRadius: 4,
      padding: '8px',
      maxHeight: '200px',
      overflowY: 'scroll',
    }}>
      <ul>
        {children}
      </ul>
    </div>;

const DistrictPreviewSection = styled.div`
  flex-basis: 33.33333%;
  overflow-x: auto;

  h3 {
    display: flex;
    align-items: baseline;
    gap: 16px;
    margin-bottom: 16px;
  }

  ul {
    margin-top: 0;

    > li {
      white-space: nowrap;
      font-size: 18px;
      margin-bottom: 8px;

      > ul > li {
        white-space: nowrap;
        font-size: 12px;
      }
    }

    flex-grow: 1;
    overflow-y: auto;
    padding-left: 16px;
  }
`;