const getArrayDepth = (array) => Array.isArray(array) ?
    1 + Math.max(0, ...array.map(item => getArrayDepth(item))) :
    0;

export const determineShapeFromCoordinates = (coordinates) => {
  if (coordinates === null) return null
  const depth = getArrayDepth(coordinates)
  let type;
  switch(depth) {
    case 1:
      type = "Point";
      break;
    case 2:
      type = "LineString";
      break;
    case 3:
      type = "Polygon";
      break;
    case 4:
      type = "MultiPolygon";
      break;
    default:
      throw new Error('Unsupported shape')
  }
  return type;
}

export const formatGeoJSON = (coordinates, otherProps = {}) => {
  if ( coordinates === null) return null
  return {
    type: determineShapeFromCoordinates(coordinates),
    coordinates,
    ...otherProps
  }
}

export const getMinMaxObj = (shape) => {
  const shapeType = determineShapeFromCoordinates(shape)
  let coordinates, minMaxes;

  // console.log('shapeType', shapeType) 

  switch(shapeType) {
    case 'LineString':
      coordinates = shape;
      break;
    case 'Polygon':
      coordinates = shape[0];
      break;
    case 'MultiPolygon':
      minMaxes = shape.map((polygon) => {
        return getMinMaxObj(polygon)
      })
      break;
    default:
      throw new Error('only checks for linestring, polygon, and multipollygon')
  }

  if (minMaxes) {
    const minMaxObj = minMaxes.reduce((acc, minMax) => {
      if (minMax.minX < acc.minX) acc.minX = minMax.minX
      if (minMax.minY < acc.minY) acc.minY = minMax.minY
      if (minMax.maxX > acc.maxX) acc.maxX = minMax.maxX
      if (minMax.maxY > acc.maxY) acc.maxY = minMax.maxY
      return acc;
    }, { maxY: -Infinity, minY: Infinity, maxX: -Infinity, minX: Infinity})
    return minMaxObj;
  }

  const minMaxObj = coordinates.reduce((acc, coord) => {
    if (coord[1] > acc.maxY) acc.maxY = coord[1]
    if (coord[1] < acc.minY) acc.minY = coord[1]
    if (coord[0] > acc.maxX) acc.maxX = coord[0]
    if (coord[0] < acc.minX) acc.minX = coord[0]
    return acc;
  }, { maxY: -Infinity, minY: Infinity, maxX: -Infinity, minX: Infinity})

  return minMaxObj;
}

export const switchLatLongs = (latLongArray) => {
  if(getArrayDepth(latLongArray) < 3) {
    return latLongArray.map((latLong) => {
      return [latLong[1], latLong[0]]
    })
  } else {
    return latLongArray.map(arr => switchLatLongs(arr));
  }

}
