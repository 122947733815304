import { ModalInner } from '../lower-order';
import Typography from '@mui/material/Typography';
import { Button, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { useFeathers } from '../../app/util';

export const EditElectionStatusModal = (
  {
    election,
    onClose,
  },
) => {
  const feathers = useFeathers();
  const [status, setStatus] = useState(election?.status ?? undefined);

  const options = [
    ['Select...', undefined],
    ['Live', 'live'],
    ['Unpublished', 'prep'],
    ['Certified', 'results-certified'],
    ['Finished', 'results-tentative'],
  ];

  const updateElectionStatus = async () => {
    let updatedElection = undefined;
    if(election) {
      updatedElection = await feathers.getService('elections')
        .patch(election.key, { status });
    }
    onClose(updatedElection);
  };

  return (
    <ModalInner onClose={onClose}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          minHeight: '25vh',
        }}
      >
        <Typography variant="h3">Edit Election Status</Typography>
        <Select
          value={status}
          onChange={({ target }) => setStatus(target.value)}
        >
          {options.map(([label, value]) => (
            <MenuItem key={value} value={value} disabled={!value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <div style={{
          // alignSelf: 'stretch',
          display: 'flex',
          justifyContent: 'space-between',
          // width: '100%',
        }}>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => updateElectionStatus()}
          >
            Save
          </Button>
        </div>
      </div>
    </ModalInner>
  );
};