import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip'
import { LoadingSpinner } from '../LoadingSpinner';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';

/*
*  This component is a select component mixed with a text field.
*  it allows the user to type in free text, while populating search results in a dropdown menu.
*  The user can then select an option from the drop-down menu
*/

const SearchableSelectInput = ({
  multi = false,                                     // true if multiple options are available
  name = '',
  onChangeSearchText = () => {},
  modifySearchText = (prevValue, newValue) => newValue,
  options = [],                                      // the options someone has to choose from. If using the default searchOptionComponent, each option should have a field called `text`
  onChange = () => {},                               // Called when an item is selected from options
  onChangeMulti = () => {},
  value = null,                                      // the selected option
  valueAsEditableText = false,                       // if true, allows the user to edit their final selection
  optionsLoading = false,                            // Set to true while searching, if async search
  searchOptionComponent = SearchItem,
  style = {},
  size,
  seedSearchText,
  seedMultiSearchText = [],
  placeholder,
  fieldName = 'text',
  allowReset = true,                                 // if true, allows someone to clear a selected result
  disabled = false,                                  // Prevents searching, and hides the "x" if a value has already been selected
  allowNewOption = false,                            // If true, allows the creation of a new option based on the search text
  formatNewOptionFromSearchText = (text) =>          // a function that formats the search text into a string, to display the creation option
    `New record "${text}"`,
  onAddNewOption = (text) => {},                     // Called whenever the new option is selected. Passes parameter of the text,
  searchMaxHeight,
  clearAfterSelect = false
}) => {
  const textBoxRef = useRef();
  const [ searchText, setSearchText ] = useState(seedSearchText || '');
  const [ searching, setSearching ] = useState(false);

  const [ multiSearchText, setMultiSearchText ] = useState(seedMultiSearchText || []);

  const [ textBoxWidth, setTextBoxWidth ] = useState(0);
  const [ textBoxBottom, setTextBoxBottom ] = useState(0);
  const [ highlightedOption, setHighlightedOption ] = useState(-1);

  useEffect(() => {
    if(searching) onChangeSearchText(searchText)
  }, [ searchText, searching ])

  useEffect(() => {
    setSearchText(seedSearchText)
  }, [ seedSearchText ])

  useEffect(() => {
    if(textBoxRef.current) {
      setTextBoxBottom(textBoxRef.current.getBoundingClientRect().bottom)
      setTextBoxWidth(textBoxRef.current.getBoundingClientRect().width)
    }
  }, [ textBoxRef, multiSearchText, searching ])

  const ItemComponent = searchOptionComponent;
  const textBoxStyle = {
    padding: '6px 12px 6px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    border: 'solid 1px #EEEEEE',
    margin: 0,
    width: 'calc(100% - 12px - 12px)',
    ...(searching
      ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
      : { borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }
    ),
    ...style,
  }

  const textBoxAlike = {
    ...textBoxStyle,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }

  const [highlightedOptionStaged, setHighlightedOptionStaged] = useState(0);
  useEffect(() => {
    const optionsLengthEffective = (options?.length || 0) + (allowNewOption ? 1 : 0);
    if(highlightedOptionStaged !== 0) {
      setHighlightedOptionStaged(0)
      const newHighlightedOption = highlightedOption + highlightedOptionStaged;
      if(newHighlightedOption <= 0)
        setHighlightedOption(0)
      else if(newHighlightedOption >= optionsLengthEffective)
        setHighlightedOption(optionsLengthEffective - 1)
      else 
        setHighlightedOption(newHighlightedOption)
    } else {
      // if options has changed, keep highlighted option in bounds
      if(highlightedOption >= optionsLengthEffective) {
        setHighlightedOption(optionsLengthEffective - 1)
      }
    }
  }, [ options, highlightedOptionStaged, highlightedOption, allowNewOption ])

  const keylistener = useCallback((event) => {
    if(event.keyCode === 27) {
      setSearching(false);
    } else if(event.keyCode === 40) {
      setHighlightedOptionStaged(1)
    } else if(event.keyCode === 38) {
      setHighlightedOptionStaged(-1)
    } else if(event.keyCode === 13) {
      setEnterPressed(true)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keylistener, false);
    return () => {
      document.removeEventListener("keydown", keylistener, false);
    };
  }, []);

  const [ enterPressed, setEnterPressed ] = useState(false);
  useEffect(() => {
    if(enterPressed) {
      setEnterPressed(false);
      if(!searching) return;
      if(optionsLoading) return;
      if(highlightedOption >= 0) {
        if(highlightedOption === options.length && allowNewOption) {
          onAddNewOption(searchText)
          if(clearAfterSelect) setSearchText('')
          setSearching(false)
        } else if(options.length > 0) {
          const target = { target: { name, value: options[highlightedOption >= 0 ? highlightedOption : 0] } };
          onChange(target);
          if(clearAfterSelect) setSearchText('')
          else setSearching(false);
        }
      } else {
        if(options?.length > 0) {
          const target = { target: { name, value: options[0] } };
          onChange(target);
          if(clearAfterSelect) setSearchText('')
          else setSearching(false);
        } else if(allowNewOption) {
          onAddNewOption(searchText)
          setSearching(false)
        }
      }
      
    }
  }, [ enterPressed, searching, options, searching, allowNewOption, highlightedOption, optionsLoading ])

  return (
    (<Wrapper style={style}>
      {
        searching &&
        <SearchBackground  onClick={() => setSearching(false)} />
      }
      { multi &&
        <div>
          {
            multiSearchText.map((opt, i) => {
              return (
              <StyledChip
                key={`multiSearchText-${i}`}
                onDelete={
                  disabled
                    ? undefined
                    : e => {
                          const newMultiSearchText = multiSearchText.filter(t => t.text != opt.text)
                          setMultiSearchText(newMultiSearchText)
                          const target = { target: { name, value: newMultiSearchText } };
                          onChange(target)
                          if(clearAfterSelect) setSearchText('')
                        }
                }
                style={{ marginBottom: '4px', marginRight: '2px' }}
                label={opt.text}
              />
            )})
          }
        </div>
      }
      {
        (value && !valueAsEditableText && !multi)
        ?
          <div style={textBoxAlike}>
            <ItemComponent {...value}/>
            {
              allowReset &&
              <IconButton
                style={{ minWidth: '36px', height: '36px' }}
                onClick={(e) => {
                  const target = { target: { name, value: null } };
                  onChange(target)
                  if(clearAfterSelect) setSearchText('')
                }}
                size="large">
                <CloseIcon style={{ color: '#777777', marginRight: '-8px' }} />
              </IconButton>
            }
          </div>
        :
          <TextField
            ref={textBoxRef}
            placeholder={placeholder}
            name={`${name}-search`}
            variant='outlined'
            size={size || 'small'}
            style={{
              border: 'solid 1px #EEEEEE',
              width: '100%',
              borderRadius: '8px'
            }}
            value={searchText}
            onFocus={() => setSearching(true)}
            onChange={e => {
              const newValue = e?.target?.value;
              setSearchText(currValue => modifySearchText(currValue, newValue));
            }}
            disabled={disabled}
            autocomplete='off'
            inputProps={{
              autocomplete: "off"
            }}
            InputProps={
              searching && (optionsLoading || options)
              ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size={size === 'small' ? 'small' : 'medium'}
                      onClick={(e) => {
                        setSearching(false)}
                      }
                    >
                      <CloseIcon style={{ color: '#777777', height: '20px', width: '20px', marginRight: '-8px' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }
              : {}
            }
          />
      }
      {
        searching && (optionsLoading || options) &&
        <SearchItemWrapper
          style={{ marginTop: '-4px' }}
          width={textBoxWidth}
          maxHeight={searchMaxHeight}
        >

          {
            optionsLoading
            ?
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px'}}>
                <LoadingSpinner width={30}/>
              </div>
            :
              (
                options.length > 0
                ?
                  options.map((opt, idx) => {
                    const mapOpt = fieldName !== 'text'
                      ? { ...opt, text: opt ? opt[fieldName] : '' }
                      : opt;
                    return (
                      <Hoverable
                        key={idx}
                        alreadySelected={multiSearchText.find(t => t.text === opt.text)}
                        keySelected={highlightedOption === idx}
                        onMouseMove={() => setHighlightedOption(-1)}
                        onClick={(e) => {
                          if (multi) {
                            if (multiSearchText.find(t => t.text === opt.text)) return
                            const newMultiSearchText = [...multiSearchText, opt]
                            setMultiSearchText(newMultiSearchText)
                            const target = { target: { name, value: newMultiSearchText } };
                            onChange(target)
                            if(clearAfterSelect) setSearchText('')
                            setSearching(false)
                          } else {
                            if(valueAsEditableText && opt && opt[fieldName]) {
                              setSearchText(opt[fieldName])
                            }
                            const target = { target: { name, value: opt } };
                            onChange(target)

                            if(clearAfterSelect) setSearchText('')
                            setSearching(false)
                          }
                        }}
                      >
                      <ItemComponent {...mapOpt} />
                    </Hoverable>
                    );
                  })
                :
                  <Hoverable>
                    <SearchItemStyle>No results</SearchItemStyle>
                  </Hoverable>
              )
          }
          {
            allowNewOption && searchText &&
            <Hoverable onClick={(e) => {
              onAddNewOption(searchText)
              setSearching(false)
              if(clearAfterSelect) setSearchText('')
            }}
              keySelected={highlightedOption === options.length} onMouseMove={() => setHighlightedOption(-1)}
            >
              <AddOption>
                <div className='text'>
                  {formatNewOptionFromSearchText(searchText)}
                </div>
              </AddOption>
            </Hoverable>
          }
        </SearchItemWrapper>
      }
    </Wrapper>)
  );
}

const Wrapper = styled.div`
  position: relative;
  display: block;
`;
const SearchItemWrapper = styled.div`
  width: ${props => `${props.width}px` || '100%'};
  max-height: ${props => props.maxHeight || '200px'};
  overflow-y: scroll;
  top: ${props => props.top - 4}px;
  background-color: #FFFFFF;
  border: solid 1px #EEEEEE;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SearchBackground = styled.div`
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 50;
  top: 0;
  left: 0;
  opacity: 100%;
`

const Hoverable = styled.div`
  padding: 8px 12px 8px;
  width: calc(100% - 12px * 2);
  display:  ${props => props.alreadySelected  ? 'none' : 'block'};
  cursor: pointer;
  background-color: #FFFFFF;
  z-index: 60;

  ${props => props.keySelected && `background-color: #F6F6F6;`}

  &:hover {
    background-color: #F6F6F6;
  }
`
const SearchItem = ({
  text,
  subText
}) => {
  return (
    <SearchItemStyle>
      <div>{text}</div>
      {
        subText &&
        <div className='subtext'>{subText}</div>
      }
    </SearchItemStyle>
  )
}

const SearchItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 17px;
  color: ${props => props.theme.palette.textPrimary};
  div {
    font-size: 17px;
    color: ${props => props.theme.palette.textPrimary};
    text-align: left;
  }

  div.subtext {
    margin-top: 0px;
    opacity: 0.5;
    font-size: 14px;
  }
`;

const StyledChip = withStyles({
  root: {
    backgroundColor: 'rgb(247, 249, 254)',
    border: 'solid .5px #22034F',
    "& .MuiChip-deleteIcon": {
      color: "#22034F",
    }
  },
  label: {
    color: '#22034F'
  }
})(Chip);

const AddOption = styled.div`

  div.text {
    ${props => props.theme.font.bold}
    font-size: 17px;
    color: ${props => props.theme.colors.darkPurple};
    opacity: 0.6;
  }
`

export { SearchableSelectInput, SearchItemStyle };
