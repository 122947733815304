import React, { useEffect, useState } from 'react';
import { useFeathers } from '../../app/util';
import { SearchableSelectInput } from '../SearchableSelectInput';
import { Typography } from '@mui/material';

const NamingPatternSegmentSearch = ({
  value,
  onChange,
  style = {},
  lengthType,
  identifierTypes,
}) => {
  const feathers = useFeathers();

  const [ optionsForField, setOptionsForField ] = useState([]);
  const [ loadingForField, setLoadingForField ] = useState([]);  // an array of timestamps
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ queuedResultsForField, setQueuedResultsForField ] = useState(null);

  const searchForField = async (searchText) => {
    if(!feathers) return;
    setError(null);

    const timestamp = new Date().getTime();
    setLoadingForField(l => [...l, timestamp]);
    setLoading(true)

    const segments = await feathers.getService('naming-pattern-segments').find({ query: {
      ...(searchText ? { segment: { $search: searchText } } : {}),
      lengthType,
      ...(identifierTypes?.length < 3 ? { identifierType: identifierTypes} : {})
    }})
    let options = segments.data.map(({ segment, _id, ...rest }) => ({ text: segment, _id, segment, ...rest }));

    setQueuedResultsForField({
      timestamp,
      options
    })
  }

  useEffect(() => {
    if(queuedResultsForField) {
      setQueuedResultsForField(null);

      const mostRecentTimestamp = loadingForField[loadingForField.length - 1];
      const isMostRecentResult = mostRecentTimestamp === queuedResultsForField.timestamp;
      if(!isMostRecentResult) return;

      // we do this so that we only acknowledge the most recent timestamp
      setOptionsForField(queuedResultsForField.options);
      setLoading(false);
    }
  }, [queuedResultsForField, loadingForField ])

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <SearchableSelectInput
        name='naming-pattern-segments'
        onChangeSearchText={(searchText) => searchForField(searchText)}
        value={value}
        onChange={onChange}
        optionsLoading={loading}
        options={optionsForField || []}
        style={style}
        multi={false}
        allowNewOption={true}
        formatNewOptionFromSearchText={text => `Create "${text}"`}
        onAddNewOption={segment => {
          // format as a segment
          // make sure that every curly bracket is balanced
          const debracketed = segment.replaceAll('{{', '').replaceAll('}}', '');
          const unbalanced = debracketed.includes('}') || debracketed.includes('{');
          if(unbalanced) {
            setError('Missing curly brackets');
            return;
          }

          // figure out the identifier type 
          const identifierType = ['number', 'name', 'letter'].find(type => segment.includes(type.toUpperCase()));
          if(!identifierType) {
            // we can't figure out the identifier type
            setError('Must include {{NAME}}, {{NUMBER}}, or {{LETTER}} in the segment');
            return;
          }

          if(!identifierTypes.includes(identifierType)) {
            setError(`Identifier '${identifierType}' already used`);
            return;
          }

          onChange({
            target: {
              value: {
                segment,
                lengthType,
                identifierType
              }
            }
          })
          setError(null);
        }}
        clearAfterSelect={true}
      />
      {
        error &&
        <div>
          <Typography color='error' variant='body2'>{error}</Typography>
        </div>
      }
    </div>
  )
}

export { NamingPatternSegmentSearch };
