import React, { useEffect } from 'react';
import styled from 'styled-components';
import { DraftPreview } from '../DraftPreview';
import { useFeathers } from '../../app/util';
import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const CreateProfileFeedback = ({
	drafts,
	onCreate,
	candidateId,
	style,
	className
}) => {
	const feathers = useFeathers();
	const [ beforeDraft, setBeforeDraft ] = React.useState(null);
	const [ afterDraft, setAfterDraft ] = React.useState(null);
	const [ loading, setLoading ] = React.useState(false);
 
	const createFeedback = async () => {
		if(loading) return;

		setLoading(true);
		try {
			const feedback = await feathers.getService('profile-feedbacks').create({
				candidate: candidateId,
				versionNumberBefore: beforeDraft.version,
				versionNumberAfter: afterDraft.version
			});
			onCreate(feedback);
		} catch (err) {
			console.error(`Error in creating feedback: `, err);
			setLoading(false);
		}
	}

	const beforeDraftOptions = React.useMemo(() => {
		const maxDraftVersion = Math.max(...drafts.map(draft => draft.version));
		return drafts.filter(draft => draft.version < maxDraftVersion);
	}, [drafts]);

	const afterDraftOptions = React.useMemo(() => {
		return beforeDraft ? drafts.filter(draft => draft.version > beforeDraft.version) : drafts;
	}, [drafts, beforeDraft]);

	useEffect(() => {
		setAfterDraft(null)
	}, [ afterDraftOptions ])

	return (
		<Wrapper style={style} className={className} disabled={loading}>
			<Typography variant='body1' color='textPrimary' style={{ gridColumn: '1 / -1' }}>To automatically generate feedback for a researcher, select their most recent draft (the base version) and then select your most recent draft (the new version).</Typography>
			<Typography variant='h4' color='textPrimary' style={{ }}>Base version</Typography>
			<Typography variant='h4' color='textPrimary' style={{ }}>New version</Typography>
			<Column>
				{beforeDraftOptions.map(draft => (
					<DraftPreview
						key={draft._id}
						draft={draft}
						showProgressBar={false}
						onClick={() => setBeforeDraft(draft)}
						isSelected={draft._id === beforeDraft?._id}
					/>
				))}
			</Column>
			<Column disabled={Boolean(beforeDraft) ? false : true}>
				{afterDraftOptions.map(draft => (
					<DraftPreview
						key={draft._id}
						draft={draft}
						showProgressBar={false}
						onClick={() => setAfterDraft(draft)}
						isSelected={draft._id === afterDraft?._id}
					/>
				))}
			</Column>
			<div/>
			<Button
				variant='contained'
				onClick={createFeedback}
				color='primary'
				disabled={!beforeDraft || !afterDraft}
			>
				Next
			</Button>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	max-width: 800px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 24px 64px;
	max-height: 65vh;
	height: 65vh;

	${props => props.disabled && `
		opacity: 0.5;
		pointer-events: none;
	`}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	border: 1px solid #E0E0E0;
	height: calc(100% - 12px * 2) ;
	overflow-y: scroll;
	padding: 12px;
	border-radius: 8px;

	${props => props.disabled && `
		opacity: 0.5;
		pointer-events: none;
	`}
`;

CreateProfileFeedback.propTypes = {
	drafts: PropTypes.array,
	onCreate: PropTypes.func,
	onClose: PropTypes.func,
	candidateId: PropTypes.string,
	style: PropTypes.object,
	className: PropTypes.string,
};

export { CreateProfileFeedback };