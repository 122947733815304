import React, { useState, useEffect, useMemo } from 'react';
import {
  useFeathers,
  mergeReferencesWithDraft
} from '../../app/util';
import Typography from '@mui/material/Typography';

import {
  ReferenceEditor,
  ReviewProgress,
  TaskPage,
  EditCandidateProfile,
  mapDraftToServerInputs,
  newDraftAfterError,
  TimeTracker,
} from '../../components';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const PublishCandidate = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const [task, setTask] = useState(null);
  const [candidate, setCandidate] = useState(null);
  const [race, setRace] = useState(null);
  const [saving, setSaving] = useState(false); 
  const [taskPageSaving, setTaskPageSaving] = useState(false);
  const [feedbackSaving, setFeedbackSaving] = useState(false);
  const [completionData, setCompletionData] = useState(null);
  const [stagedUpdatesToReferences, setStagedUpdatesToReferences] = useState(null);
  const [showingFeedbackDialog, setShowingFeedbackDialog] = useState(false);


  /* Candidate being actively edited */
  const [candidateEdit, setCandidateEdit] = useState({});

  useEffect(() => {
    if (stagedUpdatesToReferences && candidateEdit) {
      setStagedUpdatesToReferences(null);
      setCandidateEdit(
        mergeReferencesWithDraft(
          stagedUpdatesToReferences,
          candidateEdit
        )
      );
    }
  }, [candidateEdit, stagedUpdatesToReferences, task]);

  const createFeedback = async (loadNewTask) => {
    if (feedbackSaving) return;
    setFeedbackSaving(true);

    try {
      // first, load the new candidate after publishing the updates 
      const afterDraft = await feathers.getService('candidates').get(candidate._id);

      const feedback = await feathers.getService('profile-feedbacks').create({
				candidate: candidate?._id,
				versionNumberBefore: draftVersionNumber,
				versionNumberAfter: afterDraft.stagingDraft.version
			});
      console.log(`Feedback created`)
      const linkToFeedback = `/elections/${candidate?.election?.key || candidate.election}/races/${candidate?.race?._id || candidate?.race}/candidates/${candidate?._id}/feedback/${feedback._id}?confirm=true`
      console.log(linkToFeedback)

      window.open(linkToFeedback, "_blank");
    } catch (err) {
      console.error(`Error in creating feedback: `, err);
    } finally {
      setFeedbackSaving(false)
      loadNewTask()
      setShowingFeedbackDialog(false)
    }
  }

  const onCheckoutTask = async (task, loadNextTask) => {
    setCandidate(null);
    setCandidateEdit(null);
    setRace(null);
    setFeedbackSaving(false);
    setTask(task);
    let candidate;
    try {
      candidate = await feathers.getService('candidates').get(task.details.candidate);
    } catch(err) {
      if(err.name === 'NotFound') {
        // bad candidate, we need to cancel this task
        console.log('Candidate not found')
        await feathers.getService('research-tasks').patch(task._id, {
          $cancelTask: true
        });
        setTask(null);
        loadNextTask()
        return
      } else {
        throw err;
      }
    }
    setCandidate(candidate);
    const { updatedAt: holdingDataUpdatedAt, ...holdingData } = task?.holdingData || {};
    // seed inputs with most recently updated data from holdingData or candidate record
    const { bioPersonal, bioProfessional, bioPolitical, missingData, ...rest } = (
      ((holdingData && holdingDataUpdatedAt > candidate?.updatedAt)
        ? holdingData
        : candidate?.stagingDraft) 
      || {}
    )

    const mappedCandidate = {
      ...rest,
      missingData,
      ...(missingData?.bioPersonal
        ? { bioPersonal: '' }
        : { bioPersonal }),
      ...(missingData?.bioProfessional
        ? { bioProfessional: '' }
        : { bioProfessional }),
      ...(missingData?.bioPolitical
        ? { bioPolitical: '' }
        : { bioPolitical }),
    };

    setCandidateEdit(mappedCandidate);
    // get race separately since the aggregated race object attached to candidate is missing some fields

    const race = await feathers
      .getService('races')
      .get(candidate.race?._id);
    setRace(race);
  };

  useEffect(() => {
    if (candidate && candidateEdit)
      setCompletionData(mapDraftToServerInputs(candidateEdit));
  }, [candidate, candidateEdit, task]);

  const onCompleteError = (e) => {
    setCandidate((c) => newDraftAfterError(c, e));
  };

  const [submittedByUser, draftVersionNumber] = useMemo(() => {
    if(!task || !candidate) return [null, null];
    let userToRef = task.details?.submittedBy;

    // find first non-user who worked on this
    const draftsPossible = [
      candidate.stagingDraft,
      ...(candidate.previousDrafts || [])
    ].filter(Boolean)

    const draft = draftsPossible.find(d => userToRef ? d?.user?._id === userToRef : (d?.user?._id && d?.user?._id !== user._id));
    return [ draft?.user, draft?.version ];
  }, [ task, user, candidate ])

  const submittedByUserName = useMemo(() => {
    return (submittedByUser?.firstName || submittedByUser?.lastName)
      ? [submittedByUser?.firstName, submittedByUser?.lastName].filter(Boolean).join(' ')
      : (submittedByUser?.name || submittedByUser?.email || '--')
  }, [ submittedByUser ])

  return (
    <TaskPage
      task={task}
      disableTimeTracker
      taskType="publish-candidate"
      onCheckoutTask={onCheckoutTask}
      completionData={completionData}
      holdingData={candidateEdit}
      onCompleteError={onCompleteError}
      completeButtonLabel='Publish'
      sidePaneConfig={[
        {
          title: 'Submitted by',
          content: (
            <>
              <Typography variant="body2" style={{ marginTop: '6px' }}>You're reviewing a profile submitted by</Typography>
              <Typography variant="h3" style={{ marginTop: '8px' }}>
                {submittedByUserName}
              </Typography>
            </>
          ),
          initiallyExpanded: true,
        },
        {
          title: 'Priority',
          content: (
            <TimeTracker
              priorityLevel={candidate?.priorityLevel}
              seedTask={task}
              benchmarkMode='review'
              coveragePlan={candidate?.coveragePlan}
            />
          ),
          initiallyExpanded: true,
        },
        Boolean(candidate?.coveragePlan) && candidate.coveragePlan === 'auto-coverage' && {
          title: 'Progress',
          content: (
            <ReviewProgress
              coveragePlan={candidate?.coveragePlan}
              candidate={candidateEdit}
              key={`Progress`}
            />
          ),
          initiallyExpanded: true,
        },
        Boolean(candidateEdit?.references) && {
          title: 'Sources',
          content: (
            <ReferenceEditor
              references={candidateEdit?.references}
              onChangeReferences={setStagedUpdatesToReferences}
              onChangeActiveDraft={(update) => setCandidateEdit({...candidateEdit, ...update})}
              key="Information sources"
              disabled={saving} // being set in child via handleParentSaving
              activeDraft={candidateEdit}
              newsEnabled
            />
          ),
          initiallyExpanded: true,
        },
      ].filter(Boolean)}
      onCompleteTaskBeforeLoadNext={(draftVersionNumber && candidate.coveragePlan !== 'basic-coverage') ? () => setShowingFeedbackDialog(true) : null}
      onCompleteTaskDialog={
        (loadNextTask) => (
          <Dialog
            open={showingFeedbackDialog}
            onClose={() => {
              loadNextTask()
              setShowingFeedbackDialog(false)
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-body'
          >
            <DialogTitle id='alert-dialog-title'>
              Give feedback?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-body'>
                Do you want to give feedback to {submittedByUserName} on their profile?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={() => {
                  loadNextTask()
                  setShowingFeedbackDialog(false)
                }}
                color='primary'
              >
                Skip
              </Button>
              <Button 
                onClick={() => createFeedback(loadNextTask)} 
                color='primary' 
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    >
      <WrapperInner>
        {
          candidateEdit && (
            <EditCandidateProfile
              coveragePlan={candidate?.coveragePlan}
              value={candidateEdit}
              onChange={setCandidateEdit}
              onChangeIssue={(newIssue, issueKey) => {
                setCandidateEdit(existingDraft => {
                  const existingDraftIssues = existingDraft.issues || [];
                  const newIssues = existingDraftIssues.map((issue, i) => {
                    if(issue.key === issueKey) {
                      return newIssue
                    }
                    return issue
                  })

                  return {
                    ...existingDraft,
                    issues: newIssues
                  }
                })
              }}
              candidateId={candidate?._id}
              photoOptions={candidate?.photoOptions}
              race={race}
              disabled={saving || taskPageSaving} // being set in child
              electionKey={
                candidate?.election?.key || candidate?.election
              }
            />
          )
        }
        
      </WrapperInner>
    </TaskPage>
  );
};

const WrapperInner = styled.div`
  padding: 24px 24px 0;
`;
export default PublishCandidate;
