import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useFeathers } from '../../app/util';
import { Button, Typography } from '@mui/material';
import { LoadingSpinner } from '../LoadingSpinner';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SwitchControl } from '../lower-order';
import { CopyText } from '../CopyText';
import {TextInput} from "../TextInput";
import CandidateLink from "../CandidateLink";
import InputList from '../InputList';
import { PhotoUploadInput } from '../PhotoUploadInput';
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { Skeleton } from '@mui/material';

/* A component for allowing a researcher to complete the basic info for a candidate */
const BasicCoverageTile = ({
  style,
  className,
  electionKey
}) => {
  const feathers = useFeathers();
  const [ totalIncomplete, setTotalIncomplete ] = useState(undefined);
  const [ totalCount, setTotalCount ] = useState(undefined);
  const [ skipVal, setSkipVal ] = useState(undefined);
  const [ candidate, setCandidate] = useState(undefined);
  const [ candidateId, setCandidateId ] = useState(undefined);
  const [ candidateCountPreviewText, setCandidateCountPreviewText ] = useState(undefined);

  // sequence is: website, scraping, socials-and-photo
  // go from website to scraping in general, if website is provided
  // if website is not provided OR there was an error in the web scraping, then go from website to socials-and-photo
  const [ workflowState, setWorkflowState ] = useState(undefined);

  const [ website, setWebsite ] = useState('');
  const [ websiteMissing, setWebsiteMissing ] = useState(false);
  const [ shouldSkipScraping, setShouldSkipScraping ] = useState(false);

  const [ socials, setSocials ] = useState([{ url: '' }]);
  const [ socialsMissing, setSocialsMissing ] = useState(false);
  const [ photoFace, setPhotoFace ] = useState(undefined);

  const [ saving, setSaving ] = useState(false);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    if(workflowState === 'website') {
      setSocials([{url: ''}])
      setSocialsMissing(false)
      setPhotoFace(undefined)
      setError(null)
    }
  }, [ workflowState ])

  useEffect(() => {
    setSocials([{url: ''}])
    setSocialsMissing(false)
    setPhotoFace(undefined)
    setWebsite('')
    setWebsiteMissing(false)
    setWorkflowState('website')
    setShouldSkipScraping(false)
    setError(null)
    setSaving(false)
  }, [ candidateId ])

  useEffect(() => {
    if(typeof(totalIncomplete) !== 'undefined' && typeof(totalCount) !== 'undefined') {
      setCandidateCountPreviewText(`${totalCount - totalIncomplete} out of ${totalCount} candidates completed.`)
    }
  }, [ totalIncomplete, totalCount ])

  const loadCount = async () => {
    const candidatesTotal = await identifyCandidates(feathers, electionKey, false, true);
    const candidatesIncomplete = await identifyCandidates(feathers, electionKey, true, true);
    setTotalCount(candidatesTotal)
    setTotalIncomplete(candidatesIncomplete)
    const skipVal = 0;
    setSkipVal(skipVal);
    loadNextCandidate(skipVal)
  }

  const loadNextCandidate = async (skipVal) => {
    setCandidate(null);
    const skipValAdj = skipVal >= totalIncomplete
      ? 0
      : skipVal;
    setSkipVal(skipValAdj)
    // todo: need logic for randomly seeding skip value and circling it when we reach the end count
    const nextCandidateRes = (await identifyCandidates(feathers, electionKey, true, false, skipValAdj));
    setTotalIncomplete(nextCandidateRes.total);

    if(nextCandidateRes.total > 0) {
      const nextCandidate = nextCandidateRes.data[0];
      setCandidate(nextCandidate)
      setCandidateId(nextCandidate._id)
      const existingWebsite = ((nextCandidate.references.categories || []).find(c => c.type === 'website')?.sources || [])[0]
      if(existingWebsite)
        setWebsite(existingWebsite.url)

      if(existingWebsite && (nextCandidate?.jobs || []).filter(j => j.type === 'webscrape').length > 0)
        setShouldSkipScraping(true)
    }
  }

  const skipCandidate = () => {
    setSkipVal(skipVal + 1);
    loadNextCandidate(skipVal + 1)
  }

  const saveCandidateForScraping = async () => {
    if(saving || websiteMissing) return;
    setSaving(true);

    try {
      const references = {
        categories: [
          {
            type: 'website',
            missing: false,
            sources: [ website ]
          }
        ]
      }

      const updateToCandidate = await feathers.getService('candidates').patch(candidate._id, {
        references
      })

      const publishedCandidate = await feathers.getService('candidates').patch(candidate._id, {
        $publish: true
      })

      loadNextCandidate()
    } catch(err) {
      setError(err);
      setSaving(false)
    }
  }

  const saveCandidateData = async () => {
    if(saving) return;
    setSaving(true);

    try {
      const references = {
        categories: [
          {
            type: 'website',
            missing: websiteMissing,
            sources: websiteMissing ? [] : [ website ]
          },
          {
            type: 'social',
            missing: socialsMissing,
            sources: socialsMissing ? [] : socials
          }
        ]
      }

      const updateToCandidate = await feathers.getService('candidates').patch(candidate._id, {
        references,
        ...(photoFace ? { photoPathFace: photoFace } : {})
      })

      console.log('updated', updateToCandidate)
      const publishedCandidate = await feathers.getService('candidates').patch(candidate._id, {
        $publish: true
      })
      console.log('pulished', publishedCandidate)

      loadNextCandidate()
    } catch(err) {
      setError(err);
      setSaving(false)
    }
  }

  const errorMessage = useMemo(() => {
    if(!error) return;
    if (typeof error === 'string') return error;
    if (typeof error === 'object') {
      if (error.type === 'FeathersError') {
        let messageToShow;
        if(error?.errors && Object.values(error.errors).length > 0) {
          messageToShow = Object.values(error.errors).join(' ')
        } else {
          messageToShow = error?.message;
        }
      switch(error.code) {
        case 400:
          if(messageToShow) return messageToShow;
          return 'There was a problem with the data you submitted. Please check the form and try again.';
        case 401:
        case 403:
          if(messageToShow) return messageToShow;
          return 'You are not authorized to perform this action.';
        default:
          return messageToShow;
        }
      } else return error.message;
    }

    return 'There was an error when saving. Please try again!';
  }, [error]);


  useEffect(() => {
    if(feathers) {
      loadCount()
    }
  }, [ feathers, electionKey ])

  if(totalIncomplete === 0) {
    return <Typography variant='body1'>All candidates complete.</Typography>
  }

  const previewText = () => (
    candidateCountPreviewText
      ? <Typography variant='body1' color='textPrimary'>{candidateCountPreviewText}</Typography>
      : <Skeleton variant="text" width={200}/>
  )
  if(!candidate) {
    return (
      <div style={style} className={className}>
        {
          previewText()
        }
        <Wrapper>
          <div style={{ padding: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <LoadingSpinner />
          </div>
        </Wrapper>
      </div>

    )
  }

  return (
    <div style={style} className={className}>
      {
        previewText()
      }
      <Wrapper>
        <Snackbar
          open={error != null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={ 6000 }
          style={{position: 'absolute'}}
          onClose={() => setError(undefined)}
        >
          <Alert
            severity="error"
            onClose={() => setError(undefined)}
            elevation={6}
            variant="filled"
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Inner>
          <Side>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
              <Typography variant='h3'>{candidate.name}</Typography>
              <Link to={`/elections/${electionKey}/races/${candidate.race._id}/candidates/${candidate._id}`} target='_blank'>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}>
                  <Typography variant='body2'>Full page</Typography>
                  <OpenInNewIcon style={{ width: '15px', height: '15px' }}/>
                </div>
              </Link>
            </div>
            <Typography variant='body1' style={{ marginTop: '4px'}}>{candidate.race.office.name} · {candidate.race?.originalDistrict?.longName || candidate.race.district?.longName}</Typography>
            <div style={{ marginTop: '24px' }}>
              <Typography variant='body1'><b>Search text</b></Typography>
              <CopyText
                multiline
                text={`${candidate.name} ${candidate.race.office.name} ${candidate.race?.originalDistrict?.longName || candidate.race.district?.longName}`}
              />
            </div>
          </Side>
          <Side>
            {
              workflowState === 'website' &&
              <>
                <Typography variant={'h4'} style={{ fontSize: '16px', marginBottom: '8px' }}>
                  Candidate website
                </Typography>
                <SwitchControl
                  missing={websiteMissing}
                  displayName={'Website'}
                  setMissingTo={(newVal) => setWebsiteMissing(newVal)}
                >
                  <TextInput
                    disabled={saving}
                    onChange={e => setWebsite(e.target.value)}
                    value={website}
                    placeholder='www.candidate.com'
                    mode='condensed'
                    style={{
                      fontSize: '16px',
                      flexGrow: 1
                    }}
                  />
                </SwitchControl>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px', justifyContent: 'flex-end', marginTop: '16px'}}>
                  <Button
                    disabled={saving || ((websiteMissing || website?.length > 5) ? false : true )}
                    color='primary'
                    variant='contained'
                    style={{ alignSelf: 'flex-start', marginTop: '16px'}}
                    onClick={
                      (websiteMissing || shouldSkipScraping)
                      ? () => setWorkflowState('socials-and-photo')
                      : () => saveCandidateForScraping()
                    }
                  >
                    { (websiteMissing || shouldSkipScraping) ? 'Next' : 'Save' }
                  </Button>
                </div>
              </>
            }
            {
              workflowState === 'socials-and-photo' &&
              <>
                <Typography variant={'h4'} style={{ fontSize: '16px', marginBottom: '8px' }}>
                  Candidate social media
                </Typography>
                <SwitchControl
                  missing={socialsMissing}
                  displayName={'Social Media'}
                  setMissingTo={(newVal) => setSocialsMissing(newVal)}
                >
                  <InputList
                    style={{ maxWidth: '300px' }}
                    enableReorder={false}
                    disabled={saving}
                    name='socials'
                    addText={'Add link'}
                    onChange={(val) => {
                      const { name, value } = val.target;
                      const newArray = value.slice();
                      setSocials(newArray.slice())
                    }}
                    value={socials}
                    renderItem={CandidateLink}
                    emptyItem={{ url: '' }}
                  />
                </SwitchControl>
                <Typography variant={'h4'} style={{ fontSize: '16px', marginBottom: '12px', marginTop: '24px' }}>
                  Candidate photo
                </Typography>
                <PhotoUploadInput
                  style={{}}
                  value={photoFace}
                  onChange={(val) => setPhotoFace(val)}
                  candidateName={candidate.name}
                />
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px', justifyContent: 'flex-end', marginTop: '16px'}}>
                  <Button
                    disabled={saving}
                    color='primary'
                    onClick={() => setWorkflowState('website')}
                  >
                    Back
                  </Button>
                  <Button
                    disabled={saving || ((socialsMissing || socials.some(s => s.url.length > 4)) ? false : true )}
                    color='primary'
                    variant='contained'
                    style={{ alignSelf: 'flex-start'}}
                    onClick={saveCandidateData}
                  >
                    {saving ? 'Saving...' : 'Save'}
                  </Button>
                </div>

              </>
            }
          </Side>
        </Inner>
      </Wrapper>
      <Button
        style={{ marginTop: '12px' }}
        onClick={skipCandidate}
      >Skip candidate</Button>
    </div>
  )
}

const Wrapper = styled.div`
  background-color: #FFFFFF;
  border: solid 1px #EEEEEE;
  border-radius: 8px;
  margin-top: 24px;
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-gap: 32px;
`

const Side = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 28px;
`

/* A component for identifying the number of candidates yet to be completed */
const BasicCoverageCount = ({
  electionKey,
  onLoadData      // accepts a structured object, with two properties: numCandidates, numCandidatesCompleted
}) => {
  const feathers = useFeathers();

  const loadCounts = async () => {
    const candidatesAll = await identifyCandidates(feathers, electionKey, false, true);
    const candidatesIncomplete = await identifyCandidates(feathers, electionKey, true, true);
    if(onLoadData)
      onLoadData({
        numCandidates: candidatesAll,
        numCandidatesCompleted: candidatesAll - candidatesIncomplete
      })
  }

  useEffect(() => {
    if(feathers) {
      loadCounts()
    }
  }, [ feathers, electionKey ])
  return <div/>
}

const identifyCandidates = async (feathers, electionKey, incompleteOnly = true, countOnly = false, $skip = 0) => {
  const result = await feathers.getService('candidates').find({
    query: {
      election: electionKey,
      coveragePlan: 'basic-coverage',
      ...(incompleteOnly ? {
        '$needsBasicCoverageAttention': true
      } : {}),

      $limit: countOnly ? 0 : 1,
      $skip,
      $sort: {
        ballotOrder: 1
      }
    }
  });

  if(countOnly) return result.total;
  else return result;
}

export {
  BasicCoverageTile,
  BasicCoverageCount
}