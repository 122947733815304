import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {useDropzone} from 'react-dropzone';
import { useFeathers } from '../../app/util';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
/* import MUI checks */
import { CheckCircle as GreenCheck } from '@mui/icons-material';
import { CircularProgress as LoadingSpinner } from '@mui/material';
import superagent from 'superagent';
import { Application } from '@feathersjs/feathers';
import { ServiceTypes } from '../../app/feathers/ServiceTypes';

/*
*  This component accepts a .zip file, uploads it to AWS, calls the lambda to process the data
*  and then returns the processed data to the parent component.
*/

const ShapefileUpload = ({
  onChangeShapefiles,
  style = {}
}) => {
  const hiddenFileInput = useRef(null);
  const [ isUploading, setIsUploading ] = useState(false);
  const [ shapefileLocation, setShapefileLocation ] = useState(null);

  const [ isProcessing, setIsProcessing ] = useState(false);
  const [ processingStatusMessage, setProcessingStatusMessage ] = useState(null);
  const [ shapefileData, setShapefileData ] = useState(false);

  const [ error, setError ] = useState(null);
  const feathers = useFeathers();

  const handleClick = () => {
    if(hiddenFileInput?.current)
      hiddenFileInput.current.click();
  };

  const onDrop = (inputs) => fileSelected(null, inputs[0])

  const fileSelected = async (event, fileGiven) => {
    setIsUploading(true);
    setError(null);

    console.log('getting doing...')

    try {
      let file = fileGiven || event?.target?.files?.[0];
      // for (const pair of f.entries()) {
      //   file = pair[1];
      // }
      if(!file) return;
      if(!feathers) return;

      const { accessToken } = await feathers.get('authentication');
      const url = process.env.NODE_ENV === 'development'
          ? 'http://localhost:3030/api/v1/uploads'
          : 'https://www.branch.vote/api/v1/uploads';

      // TODO - this method is currently broken, returning redundant data....
      const uploadResult = (
        await superagent.post(url)
          .set('Authorization', accessToken)
          .query({ type: 'shapefiles'})
          .attach('file', file)
      ).body

      setIsProcessing(true);
      setIsUploading(false)
      setShapefileLocation(uploadResult);

      console.log({
        key: uploadResult.key,
        bucket: uploadResult.bucket
      })

      const processingResult = await feathers.getService('shapefiles').create({
        key: uploadResult.key,
        bucket: uploadResult.bucket
      });


      console.log('processingResult', processingResult)
      let allRows = processingResult.data;

      // Check if we have to call paginated results
      if(processingResult.total > processingResult.data.length) {
        console.log('processingResult.total', processingResult.total)
        console.log('processingResult.limit', processingResult.limit)

        setProcessingStatusMessage(`${processingResult.limit} of ${processingResult.total} loaded.`)

        // Paginate through the function the necessary number of times
        for(let i = 1; i < Math.ceil(processingResult.total / processingResult.limit); i++) {
          const nextResults = await feathers.getService('shapefiles').create({
            key: uploadResult.key,
            bucket: uploadResult.bucket
          }, {
            query: {
              $limit: processingResult.limit,
              $skip: processingResult.limit * i
            }
          });
          allRows = allRows.concat(nextResults.data);


          setProcessingStatusMessage(`${allRows.length} of ${processingResult.total} loaded.`)
        }
      }

      const mappedResult = {
        total: processingResult.total,
        data: allRows.map((d, idx) => {
          return {
            dataIndex: idx,
            ...d
          }
        })
      }

      setShapefileData(mappedResult);
      onChangeShapefiles(mappedResult.data);
      setIsProcessing(false)
    } catch (err) {
      console.error(err)
      setError(err)
      setShapefileLocation(null);
      setShapefileData(null);
      setIsUploading(false);
    } finally {
      setIsUploading(false);
      setIsProcessing(false)
    }
  };

  const accept = '.zip';

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept,
    noClick: true,
    maxFiles: 1
  })


  if(shapefileLocation || shapefileData || isUploading) {
    return (
      <Wrapper style={{ display: 'flex', gap: '12px', ...style }}>
        {
          isUploading &&
          <Row>
            <LoadingSpinner />
            <Typography variant='body1'>Uploading shapefiles...</Typography>
          </Row>
        }
        {
          !isUploading && <>
            {
              shapefileLocation &&
              <Row>
                <GreenCheck style={{ fontSize: '16px' }} />
                <Typography variant='body2'>Uploaded shapefile to {shapefileLocation.key}</Typography>
              </Row>
            }
            {
              shapefileData 
              ?
                <Row>
                  <GreenCheck style={{ fontSize: '16px' }} />
                  <Typography variant='body2'>Processed shapefile. {shapefileData.total} districts found.</Typography>
                </Row>
              :
                <Row>
                  <LoadingSpinner />
                  <Typography variant='body1'>{processingStatusMessage || 'Processing shapefile...'}</Typography>
                </Row>
            }
          </>
        }
      </Wrapper>
    )
  }


  return (
    <Wrapper className='upload-button-wrapper'
      {...getRootProps()}
      isDragActive={isDragActive ? 1 : 0}
      style={style}
    >
      {
        error &&
        <Row>
          <Typography variant='body1' color='error'>{error.message}</Typography>
        </Row>
      }
      <Row>
        <Typography variant='h6'>Upload the .zip shapefile</Typography>
        <Button variant='outlined' color='primary' style={{ marginLeft: '36px' }} onClick={handleClick}>
          { isUploading ? 'LOADING...' : 'UPLOAD'}
        </Button>
      </Row>
      <input type="file"
        {...getInputProps()}
        ref={hiddenFileInput}
        onChange={fileSelected}
        style={{ display:'none' }}
        accept={accept}
      />  
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: calc(100% - 14px * 2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${props => props.isDragActive
  ?`
    border: dashed 2px #CCCCCC;
    background-color: #EEEEEE;
    padding: 16px;
  `
  :`
    border: solid: 1px #EEEEEE;
  `}
`

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`

export { ShapefileUpload };
