import React, { useEffect, useMemo, useState, useRef } from "react";
import { LayerGroup, MapContainer, TileLayer, useMap, useMapEvents, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Polyline } from 'react-leaflet/Polyline';
import { Polygon } from 'react-leaflet/Polygon';
import { CircleMarker } from 'react-leaflet/CircleMarker';

import { getMinMaxObj, switchLatLongs } from '../../app/util/geo-projection-helpers';
import useKeypress from "react-use-keypress";
import { formatGeoJSON } from "../../app/util/geo-projection-helpers";

/*
  input design:

  state:
    features - array of geojson in long/lat with additional properties like:
      strokeWidth
      strokeColor
      fillColor
      dashArray
      pointColor (optional, places a point at each vertex)
      pointRadius (optional, places a point at each vertex)
    onClick = ([long, lat]) => ...
    onUndo
    viewBox - autodetected from provided features

  display:
    map layer
      features used in map (colors/etc)
    polygon borders in lat long
    polygons in lat long (new borders)
    points
    lines connecting the points

  interaction:
    drop points (on click)
    drag points
    remove points
    zoom / pan

*/

export const InteractiveMap = ({
  // must provide EITHER features or districts (with geodistrict subfield)
  features: featuresGiven,
  districts,

  latLongMode = false,

  onClick = () => {},
  onUndo = () => {},
  style = {},

  censusBlockUnprojected = {},   // todo: use features to determine bounding box

}) => {
  const [ activeShape, setActiveShape ] = useState(null) // should be an array of [ 'type', idx, idx2 ]

  const centerLatGiven = ((censusBlockUnprojected?.maxLat || 0) + (censusBlockUnprojected?.minLat || 0)) / 2;
  const centerLongGiven = ((censusBlockUnprojected?.maxLong || 0) + (censusBlockUnprojected?.minLong || 0)) / 2;

  const features = useMemo(() => {
    if(featuresGiven) {
      return featuresGiven;
    } else if(districts) {
      return districts.map(district => {
        if(!district?.geoDistrict) return null;
        const tooltip = `${district?.longName}`;
        return formatGeoJSON(district.geoDistrict.boundary, { fillColor: '#4F50CC', strokeColor: '#4F50CC', tooltip })
      }).filter(Boolean) 
    } else {
      return null;
    }
  }, [ featuresGiven, districts ])

  const [centerLat, centerLong] = useMemo(() => {
    if(centerLatGiven && centerLongGiven) {
      return [centerLatGiven, centerLongGiven]
    } else {
      // determine as the min/max of the features
      const minMaxes = (features || []).map(f => {
        const coordinates = f?.coordinates || f;
        if(!coordinates || coordinates?.length === 0) return null;
        return getMinMaxObj(coordinates)
      }).filter(Boolean)

      const minLat = Math.min(...minMaxes.map(m => m.minY));
      const maxLat = Math.max(...minMaxes.map(m => m.maxY));
      const minLong = Math.min(...minMaxes.map(m => m.minX));
      const maxLong = Math.max(...minMaxes.map(m => m.maxX));

      if(!minLat || !maxLat || !minLong || !maxLong) return [0, 0];

      return [(minLat + maxLat) / 2, (minLong + maxLong) / 2];
    }
  }, [ centerLatGiven, centerLongGiven, features ])

  const bounds = useMemo(() => {
    const minMaxes = (features || []).map(f => {
      const coordinates = f?.coordinates || f;
      if(!coordinates || coordinates?.length === 0) return null;
      return getMinMaxObj(coordinates)
    }).filter(Boolean)

    const minLat = Math.min(...minMaxes.map(m => m.minY));
    const maxLat = Math.max(...minMaxes.map(m => m.maxY));
    const minLong = Math.min(...minMaxes.map(m => m.minX));
    const maxLong = Math.max(...minMaxes.map(m => m.maxX));

    if(!minLat || !maxLat || !minLong || !maxLong) return [[0, 0], [0, 0]];

    return [[minLat, minLong], [maxLat, maxLong]];
  }, [ features ])

  useEffect(() => {
    const callback = (event) => {
      // event.metaKey - pressed Command key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.metaKey || event.ctrlKey) && event.code === 'KeyZ') {
        if(onUndo) onUndo()
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, []);


  const MapLayer = () => {
    const map = useMapEvents({
      click: (e) => {
        e.originalEvent.preventDefault()
        if (e?.latlng) {
          const { lat, lng } = e.latlng;
          if(activeShape) {
            // if the active shape is movable, then move to new position
            // todo: implement this...
            // console.log(features)
            // const
            // let featuresSliced = [...features];
            // let coordinates = featuresSliced[activeShape[1]].coordinates;
            // coordinates[activeShape[2]] = [lng, lat];
            // featuresSliced[activeShape[1]] = {
            //   ...featuresSliced[activeShape[1]],
            //   coordinates
            // };

            // console.log(featuresSliced)

            // if(onChange) onChange(featuresSliced);

            // setActiveShape(null)
          } else {
            if(onClick) onClick([lng, lat]);
          }
        }
      },
    });

    return null;
  }

  const center = [centerLat, centerLong]

  const featuresInLatLong = useMemo(() => {
    return latLongMode ? (features || [] ) : (features || []).map(f => ({
      ...f,
      coordinates: f ? switchLatLongs(f.coordinates) : []
    }))
  }, [ features ])

  return (
    <>
     { !!centerLat && <MapContainer
        center={center}
        bounds={bounds}
        style={{ height: '100%', width: '100%', ...style }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
          <MapLayer />
          {
            (featuresInLatLong || []).map((feature, idx) => {
              const { strokeWidth: weight, strokeColor: color, dashArray, fillColor, tooltip } = feature;
              const options = {
                weight: weight ?? 2,
                color: color ?? 'red',
                ...(dashArray ? { dashArray } : {}),
                ...(fillColor ? { fillColor, fillOpacity: 0.5 } : {})
              };

              const { pointColor, pointRadius } = feature;
              const vertexOptions = (pointColor || pointRadius) ? {
                ...(pointColor ? { color: pointColor } : {}),
                radius: pointRadius || 2
              } : null;

              const boundary = feature?.coordinates;

              if(feature?.type === 'MultiPolygon') {
                return <>
                  {
                    feature.coordinates.map((boundary, idxInner) => {
                      if(fillColor) {
                        if(tooltip) {
                          return (
                            <Polygon pathOptions={options} positions={boundary} key={`${idx}-${idxInner}`}>
                              <Tooltip>{tooltip}</Tooltip>
                            </Polygon>
                          )
                        }
                        return <Polygon pathOptions={options} positions={boundary} key={`${idx}-${idxInner}`}/>
                      } else {
                        return <Polyline pathOptions={options} positions={boundary} key={`${idx}-${idxInner}`}/>
                      }
                    })
                  }
                </>
              } else if(feature?.type === 'Polygon') {
                if(fillColor) {
                  if(tooltip) {
                    return (
                      <Polygon pathOptions={options} positions={boundary} key={idx}>
                        <Tooltip>{tooltip}</Tooltip>
                      </Polygon>
                    )
                  }
                  return <Polygon pathOptions={options} positions={boundary} key={idx}/>
                } else {
                  return <Polyline pathOptions={options} positions={boundary} key={idx} />
                }
              } else if(feature?.type === 'LineString') {
                if(vertexOptions) {
                  const { vertexMovable, vertexDeletable } = feature;
                  return <>
                    <Polyline pathOptions={options} positions={boundary} key={idx}/>
                    {
                      (boundary || []).map((point, idxInner) => (
                        <CircleMarker
                          center={point}
                          key={`point-${idx}-${idxInner}`}
                          radius={(activeShape?.[0] === 'vertex' && activeShape[1] === idx && activeShape[2] === idxInner) ? vertexOptions?.radius * 2 : vertexOptions?.radius}
                          draggable={true}
                          options={vertexOptions}
                          eventHandlers={{
                            click: () => {
                              if(false && (vertexDeletable || vertexMovable)) {
                                setActiveShape(['vertex', idx, idxInner])
                              }
                            },
                          }}
                        />
                      ))
                    }
                  </>
                } else {
                  return <Polyline pathOptions={options} positions={boundary} key={`polyline-no-vertx-${idx}`} />
                }
              } else {
                console.log(`Unsupported Geometry`, feature?.type)
                return null;
              }
            })
          }

      </MapContainer>}
    </>
  );
};

/*


{
          droppedLatLongPts.map((point, idx) => (

        }

        <Polyline pathOptions={borderPathOptions} positions={censusBlockBoundaryLatLong}  />
        {
          recentlyCalculatedBoundary &&
          <Polyline pathOptions={splitPolygonBorderPathOptions} positions={splitPolygonBorderLatLong} />
        }
*/