import { NamingPatternSegment } from "../feathers/naming-patterns/NamingPatternSegment";

export const segmentPretty = (value: string) => {
  // extract the identifier type and segment body
  const [ identifier, segment ] = value.split(':');
  const identifierType = ['name', 'letter', 'number'].find(type => identifier.includes(type.toUpperCase()));
  return {
    identifierType,
    segment: segment.slice(0, -1).trim()
  };
}

export const segmentUgly = (segment: NamingPatternSegment) => {
  return `[IF {{${segment.identifierType.toUpperCase()}}}:${segment.segment}]`;
}