import React, { useEffect, useMemo, useState } from 'react';
import {useFeathers} from '../../app/util';
import { useSelector } from 'react-redux';
import {
  useParams,
} from 'react-router-dom';

import {
	LoadingSpinner,
  DataDetailToolbar,
} from '../../components';
import styled from 'styled-components';
import { Button, TextField, Typography, IconButton } from '@mui/material';
import { CalendarToday, Cancel } from '@mui/icons-material';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Alert } from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ElectionDates = () => {
  const feathers = useFeathers();
  const electionKey = useParams().key;
  const user = useSelector(state => state.user);
  const electionDataPreloaded = useSelector(state => state?.elections?.byKey[electionKey]);
  const [ election, setElection ] = useState(electionDataPreloaded);
  const [ electionImplementation, setElectionImplementation ] = useState(null)
  const [ loading, setLoading ] = useState(!Boolean(electionDataPreloaded));
  const [ editData, setEditData ] = useState({});
  const [ error, setError ] = useState(null);
  const history = useHistory()

  const onChangeDate = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value })
  }

  const submittable = (
    Object.keys(editData).length > 0 && 
    (
      Object.keys(editData).includes('date')
      ? Boolean(editData.date)
      : Boolean(election.date)
    )
  );

  const valueFor = (date, keyKey) => {
    if(date[keyKey]) {
      const valueOfKey = date[keyKey];
      const valueToReturn = Object.keys(editData).includes(date[keyKey]) 
        ? editData[valueOfKey] 
        : (date?.ei ? electionImplementation?.[valueOfKey]  : election?.[valueOfKey]);
      const nameToStore = keyKey.slice(0, -3)
      return { [nameToStore]: (nameToStore.includes('date') && valueToReturn) ? moment(valueToReturn) : valueToReturn }
    } else {
      return {}
    }
  }

  const dates = useMemo(() => ([
    { 
      title: (`Last day to register to vote`), 
      date1Key: 'voterRegistrationEnd',
      subtextKey: 'voterRegistrationDisclaimer',
      faqLinkKey: 'voter-registration'
    },
    { 
      title: (`Early voting`), 
      date1Key: 'earlyVotingStart',
      date2Key: 'earlyVotingEnd',
      subtextKey: 'earlyVotingDisclaimer',
      faqLinkKey: 'early-voting'
    },
    { 
      title: (`Last day to request absentee ballot`), 
      date1Key: 'absenteeEnd',
      subtextKey: 'absenteeDisclaimer',
      faqLinkKey:  'absentee-voting'
    },
    { 
      title: ('Last day to vote'), 
      date1Key: 'date',
      faqLinkKey: 'election-day-voting'
    }
  ].map(d => {
    return {
      ...d,
      ...(valueFor(d, 'date1Key')),
      ...(valueFor(d, 'subtextKey')),
      ...(valueFor(d, 'date2Key')),
    }
  })), [ editData, election, electionImplementation ])

  const projectDates = useMemo(() => ([
    { 
      title: (`Qualifying date`), 
      date1Key: 'qualifyingDate',
      ei: false
    },
    { 
      title: (`Scouting for races starts on`), 
      date1Key: 'raceScoutingStartDate',
      ei: true
    },
    { 
      title: (`Scouting for referendums starts on`), 
      date1Key: 'measureScoutingStartDate',
      ei: true
    },
    { 
      title: (`Target go-live date`), 
      date1Key: 'goLiveDate',
      ei: true
    },
  ].map(d => {
    return {
      ...d,
      ...(valueFor(d, 'date1Key')),
      ...(valueFor(d, 'subtextKey')),
      ...(valueFor(d, 'date2Key')),
    }
  })), [ editData, election, electionImplementation ])


  const refreshElectionData = async () => {
    try {
      const newData = await feathers.getService('elections').get(
        electionKey
      );
      setElection(newData)
      const eiData = await feathers.getService('election-implementations').get(electionKey);
      setElectionImplementation(eiData)

      setLoading(false)
    } catch(err) {
      console.error(err)
    }
  }

  const saveChanges = async () => {
    const allDates = [].concat(dates, projectDates);
    try {
      const keysEdited = Object.keys(editData);
      const changesToElection = allDates.reduce((acc, d) => {
        if(!d.ei) {
          if(d.date1Key && keysEdited.includes(d.date1Key)) {
            acc[d.date1Key] = editData[d.date1Key] ? moment.utc(editData[d.date1Key]).toISOString() : null;
          }
          if(d.date2Key && keysEdited.includes(d.date2Key)) {
            acc[d.date2Key] = editData[d.date2Key] ? moment.utc(editData[d.date2Key]).toISOString() : null;
          }
          if(d.subtextKey && keysEdited.includes(d.subtextKey)) {
            acc[d.subtextKey] = editData[d.subtextKey]
          }
        }
        return acc
      }, {})

      const changesToEi = allDates.reduce((acc, d) => {
        if(d.ei) {
          if(d.date1Key && keysEdited.includes(d.date1Key)) {
            acc[d.date1Key] = editData[d.date1Key] ? moment.utc(editData[d.date1Key]).toISOString() : null;
          }
          if(d.date2Key && keysEdited.includes(d.date2Key)) {
            acc[d.date2Key] = editData[d.date2Key] ? moment.utc(editData[d.date2Key]).toISOString() : null;
          }
          if(d.subtextKey && keysEdited.includes(d.subtextKey)) {
            acc[d.subtextKey] = editData[d.subtextKey]
          }
        }
        return acc
      }, {})

      await Promise.all([
        feathers.getService('elections').patch(electionKey, changesToElection),
        feathers.getService('election-implementations').patch(electionKey, changesToEi)
      ])

      setEditData({})

      history.push(`/elections/${electionKey}`)
    } catch(err) {
      console.error(err)
      setError(err)
    }
  }

  useEffect(() => {
    if(feathers) {
      refreshElectionData()
    }
  }, [ feathers ])

  if(loading) {
    return (
      <Wrapper>
        <LoadingTile>
          <LoadingSpinner scale={0.8}/>
        </LoadingTile>
      </Wrapper>
    )
  }


  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}`,
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name,
            to: {
							pathname: `/elections/${electionKey}`,
            }
          },
          { text: 'Edit dates' }
        ]}
        actionButtonsComponent={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant='contained' color='primary' disabled={!submittable} onClick={saveChanges}>
              Save
            </Button>
          </div>
        }
      />
      {
        error &&
        <Alert severity='error' style={{ marginTop: '24px'}}>
          {error.message}
        </Alert>
      }
      <DateSection title='Election dates' dates={dates} onChange={onChangeDate}/>
      <DateSection title='Project dates' dates={projectDates} onChange={onChangeDate}/>
    </Wrapper>
  )
}

const DateSection = ({ title, dates, onChange }) => {
  return (
    <Section>
      <Typography variant='h3'>{title}</Typography>
      <div>
        {dates.map((d, i) => (
          <DateRow date={d} key={i} onChange={onChange}/>
        ))}
      </div>
    </Section>
  )
}

const DateRow = ({ date, onChange }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', paddingLeft: '24px', marginBottom: '24px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'row', gap: '8px'}}>
        <CalendarToday style={{ marginLeft: '-24px', fontSize: '16px' }}/>
        <Typography variant='body1' style={{ fontWeight: 'bold' }}>{date.title}</Typography>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '250px 250px', gridTemplateRows: 'auto auto', gridGap: '8px 18px', alignItems: 'center' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '60px 1fr 20px', gridGap: '8px', alignItems: 'center',  gridRow: '1 / 2', gridColumn: '1 / 2' }}>
          <Typography variant='body1'>Start</Typography>
          <StyledDatePicker
            inputFormat="MM/DD/YYYY"
            value={date.date1 || null}
            onChange={value => onChange({ target: { name: date.date1Key, value }})}
            renderInput={(params) => <TextField variant='small' {...params} />}
            style={{
              highlight: { color: '#FFFFFF' }
            }}
          />
          <IconButton size='small' onClick={() => onChange({ target: { name: date.date1Key, value: null }})}>
            <Cancel style={{ fontSize: '16px '}}/>
          </IconButton>
        </div>
        {
          date.date2Key &&
          <div style={{ display: 'grid', gridTemplateColumns: '60px 1fr 20px', gridGap: '8px', alignItems: 'center',  gridRow: '1 / 2', gridColumn: '2 / 3' }}>
            <Typography variant='body1' style={{ marginLeft: '24px' }}>End</Typography>
            <StyledDatePicker
              inputFormat="MM/DD/YYYY"
              value={date.date2 || null}
              onChange={value => onChange({ target: { name: date.date2Key, value }})}
              renderInput={(params) => <TextField variant='small' {...params} />}
              style={{
                highlight: { color: '#FFFFFF' }
              }}
            />
            <IconButton size='small' onClick={() => onChange({ target: { name: date.date2Key, value: null }})}>
              <Cancel style={{ fontSize: '16px '}}/>
            </IconButton>
          </div>
        }
        {
          date.subtextKey &&
          <div style={{ display: 'grid', gridTemplateColumns: '60px 1fr', gridGap: '8px',  alignItems: 'center',  gridRow: '2 / 3', gridColumn: '1 / 3' }}>
            <Typography variant='body1'>Subtext</Typography>
            <TextField
              variant="outlined"
              size='small'
              fullWidth
              value={date.subtext}
              onChange={onChange}
              name={date.subtextKey}
            />
          </div>
        }
      </div>
    </div>
  )
}

const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-input {
    padding: 2px 8px;
  }
`;

const Section = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
`;


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const LoadingTile = styled.div`
  width: 100%;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default ElectionDates;
