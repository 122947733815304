module.exports.sortDistricts = function(districts, ascending = true, sortByField = 'identifier') {
  return districts.sort((a, b) => {
    if(sortByField === 'identifier') {
      if (Number.isInteger(a.number) && Number.isInteger(b.number)) {
        return a.number - b.number * (ascending ? 1 : -1);
      }
      if ('letter' in a && 'letter' in b) {
        return a.letter.localeCompare(b.letter) * (ascending ? 1 : -1);
      }
      if ('name' in a && 'name' in b) {
        return a.name.localeCompare(b.name) * (ascending ? 1 : -1);
      }

      return a.longName.localeCompare(b.longName) * (ascending ? 1 : -1);
    } else if(sortByField === 'population') {
      return a.population - b.population * (ascending ? 1 : -1);
    } else {
      return `${a[sortByField]}`.localeCompare(`${b[sortByField]}`) * (ascending ? 1 : -1);
    }
  });
};